import React, { useState } from "react";

import {
  withStyles,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Button
} from "@material-ui/core";
import { calendar, clock } from "./iconPaths";
import * as FaIcons from 'react-icons/fa';
import { connect } from 'react-redux'
import { addUserDetails } from '../../redux/kyc-form/kyc.actions'


const styles = theme => {
  return {
    root: {
      padding: theme.spacing.unit * 3,
      height: "100%",
      maxWidth: 450,
      margin: " 0 auto"
    },
    labelRoot: {
      fontSize: 15,
      [theme.breakpoints.down("xs")]: {
        fontSize: 13.5,
      }
    },

    error: {
      color: "red"
    },
    icon: {
      height: 28,
      width: 28,
      fill: theme.palette.grey[500]
    },
    button: {
      width: '25vw',
      fontSize: 12,
      [theme.breakpoints.down("xs")]: {
        fontSize: 10,
        width: '70vw'
      }
    },
  };
};
function AadharDetails({ classes, error, aadharNo, setaadharNo, selectedaadharFile, setSelectedaadharFile }) {


  console.log(error);
  console.log(aadharNo.length);
  const [isaadharFilePicked, setIsaadharFilePicked] = useState(false);

  const numbers = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;

  const handleaadharNoChange = e => {
    setaadharNo(e.target.value);
  };
  const changeaadharHandler = (event) => {
    setSelectedaadharFile(event.target.files[0]);
  };

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      className={classes.root}
    >
      <Grid container direction="row" alignItems="center" alignContent="center">

        <Grid item xs={1}>
        </Grid>
        <Grid item xs={10}>
          <TextField
            name="name"
            value={aadharNo}
            fullWidth
            margin="normal"
            label="Enter your Aadhar Number"
            variant="outlined"
            onChange={handleaadharNoChange}
            InputLabelProps={{
              classes: {
                root: classes.labelRoot
              }
            }}
          />
        </Grid>
        <Grid item xs={12} className={classes.error}>{aadharNo ? "" : error.aadharNo} </Grid>
        <Grid item xs={12} className={classes.error}>{aadharNo.match(numbers) ? "" : error.aadharNolength} </Grid>


        <Grid item xs={12}>
          <input
            color="primary"
            accept="image/*,application/pdf"
            type="file"
            onChange={changeaadharHandler}
            id="icon-button-file1"
            style={{ display: 'none', }}
          />
          <label htmlFor="icon-button-file1">
            <Button
              variant="contained"
              component="span"
              className={classes.button}
              size="large"
              color="primary"
            >
              <FaIcons.FaUpload />
                    &nbsp; &nbsp;
                Upload Your Aadhar Card
          </Button>
          </label>
          <p>
            {selectedaadharFile ? selectedaadharFile.name : "No file Chosen"}
          </p>

        </Grid>
        <Grid item xs={12} className={classes.error}>{selectedaadharFile ? "" : error.selectaadharfile} </Grid>
        <Grid item xs={12} className={classes.error}>{error.aadharVerify} </Grid>

      </Grid>
      {/* <Button
                color="primary"
                className={classes.navigation}
                variant="contained"
               >

                    <FaIcons.FaUpload  />
                    &nbsp; &nbsp;
                Upload Your PAN Card
                
              </Button>    */}

    </Grid>
  );
}

const mapStateToProps = state => ({
  // data:state.cardItems
})
const mapDispatchToProps = dispatch => ({
  addToUserDetails: data => dispatch(addUserDetails(data))

})

//export default withStyles(styles)(AadharDetails);
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AadharDetails));

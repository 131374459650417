import { useState, useEffect } from 'react';
import { Card, Row, Col, Button } from "react-bootstrap";
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import TextField from '@material-ui/core/TextField';
import firebase, { firestore } from '../../firebase/firebase.utils';
import { connect } from 'react-redux';


const NotificationBar = ({ currentUser, notif }) => {
    console.log(notif);
    console.log(currentUser);

    const [value, setValue] = useState(0);
    //const max = 20000;

    const [max, setmax] = useState(0);
    //    const [value, setValue] = React.useState<number | string | Array<number | string>>(30);
    const [reqAmount, setreqAmount] = useState(0)

    useEffect(() => {

        firestore.collection("users").doc(notif.data.payload.senderId).collection("SMSGroups").doc(notif.data.payload.smsGroup).onSnapshot((snapshot) => {
            console.log(snapshot.data());
            setreqAmount(snapshot.data().reqAmount)

        })

        if (currentUser !== undefined) {
            setmax(currentUser.walletAmount - (currentUser.walletHoldAmount || 0))
        }



        return () => {
        }
    }, [])

    const handleChange = (event) => {
        if (event.target.value > max) {
            setValue(max);
        }
        else {
            setValue(event.target.value);
        }
    }


    const handleSubmit = async () => {
        await firestore.collection("users").doc(notif.data.payload.receiverId).set({
            walletHoldAmount: firebase.firestore.FieldValue.increment(value),
        }, { merge: true })

        await firestore.collection("users").doc(notif.data.payload.senderId).collection("SMSGroups").doc(notif.data.payload.smsGroup).collection("requested").doc(notif.data.payload.receiverId).set({
            approvedAmount: value,
            status: "approved"
        }, { merge: true })

        await firestore.collection("users").doc(notif.data.payload.senderId).collection("SMSGroups").doc(notif.data.payload.smsGroup).set({
            approvedAmount: firebase.firestore.FieldValue.increment(value),
        }, { merge: true })

        await firestore.collection("users").doc(notif.data.payload.receiverId).collection("notifications").doc(notif.id).set({
            status: "disable"
        }, { merge: true })
    }


    const handleReject = async () => {
        // alert(notif.id);

        await firestore.collection("users").doc(notif.data.payload.receiverId).collection("notifications").doc(notif.id).set({
            status: "disable"
        }, { merge: true })
    }

    return (

        <>

            <Card className="mb-2" style={{ width: '100%' }}>

                <Row className="w-100">
                    <Col lg="6" md="12" xs="12">
                        <h5 style={{ color: "rgb(63, 81, 181)" }}>{notif.data.payload.title} <br></br> Rs 42,500 in {notif.data.payload.smsGroup}</h5>
                        <p>REQUESTED AMOUNT : Rs {reqAmount}</p>
                    </Col>
                    <Col className="mb-4" lg="3" md="6" xs="12">

                        <TextField value={value} className="mb-5" onChange={(e) => handleChange(e)} type="number" id="outlined-basic" label="Enter Amount" variant="outlined" />

                        <InputRange
                            maxValue={max}
                            minValue={0}
                            value={value}
                            onChange={amount => setValue(amount)} />

                    </Col>

                    <Col lg="3" md="12" xs="12">
                        <Button className="mr-4" variant="success" onClick={() => handleSubmit()} >Approve </Button>
                        <Button variant="danger" onClick={() => handleReject()}> Reject </Button>
                    </Col>



                </Row>

            </Card>


        </>

    )
}

//export default NotificationBar;


const mapStateToProps = ({ user, referred }) => ({
    currentUser: user.currentUser,
    referredId: referred.referredId,
});



export default connect(mapStateToProps, null)(NotificationBar);
import { USER_DETAILS } from './kyc.types';
const initialState = {
    userData: []
}
export default function userDetails(state = [], action) {
    switch (action.type) {
        case USER_DETAILS:
            return [
                ...state,
                {userData: action.data}
            ]
        default:
            return state
    }


}

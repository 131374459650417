import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { ReactComponent as Logo } from "../../assets/crown.svg";

import { auth, firestore } from "../../firebase/firebase.utils";
import { SidebarData } from "./SidebarData";
import { IconContext } from "react-icons";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import "./Navbar.css";
import { Row, Col, Image } from "react-bootstrap";
import { Grid, ListItem } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import Tooltip from "@material-ui/core/Tooltip";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { withStyles } from "@material-ui/core/styles";
import Icon from '@material-ui/core/Icon';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Fade from '@material-ui/core/Fade';
import Divider from '@material-ui/core/Divider';
import NotificationsIcon from '@material-ui/icons/Notifications';
import ListItemText from '@material-ui/core/ListItemText';


const styles = {
  tooltip: {
    width: "200px",
    height: "35px",
    borderRadius: "10px",
    backgroundColor: "white",
    color: "black"
  }

};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex"
  },
}));


const useStyles1 = makeStyles((theme) => ({
  paper: {
    border: '1px solid',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    borderRadius: "10px",
    textAlign: "center"
  },
}));


const CustomTooltip = withStyles(styles)(Tooltip);

function Header({ currentUser }) {
  const [sidebar, setSidebar] = useState(false);
  let history = useHistory();

  const showSidebar = () => setSidebar(!sidebar);
  console.log(currentUser);

  const classes = useStyles();

  const classes1 = useStyles1();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const [notifications, setnotifications] = useState([]);
  const [notificationscount, setnotificationscount] = useState(0);

  useEffect(() => {
    setnotifications([]);
    firestore.collection("users").doc(currentUser.id).collection("pushnotifications").onSnapshot((snapshot) => {
      snapshot.docs.forEach(element => {
        //        console.log(element)
        // var data = [];
        // data.push(element.data());
        // data.push(element.id)
        var data = element.data();

        data.id = element.id

        setnotifications(notifications => [...notifications, data]);
      });
    })

    firestore.collection("users").doc(currentUser.id).collection("pushnotifications").where("read", "==", false).onSnapshot((snapshot) => {
      //      console.log(snapshot.docs.length);
      setnotificationscount(snapshot.docs.length)
    })


    return () => {

    }
  }, []);

  console.log(notifications)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);



  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handlesubmit = (element) => {
    firestore.collection("users").doc(currentUser.id).collection("pushnotifications").doc(element.id).set({
      read: true
    }, { merge: true });
    setnotifications([]);
    if (element.redirect) {
      history.push(`/SMSGroups`);
    }
  }

  const open1 = Boolean(anchorEl);
  const id = open1 ? 'transitions-popper' : undefined;

  return (
    <div className="header">
      <IconContext.Provider value={{ color: "#fff" }}>
        <div className="navbar">
          <Link to="#" className="menu-bars mr-2">
            <FaIcons.FaBars style={{ color: "black" }} onClick={showSidebar} />
          </Link>
        </div>
        <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
          <ul className="nav-menu-items" onClick={showSidebar}>
            <li className="navbar-toggle">
              <Row>
                <Col lg={8} md={8} xs={8}>
                  <Link className="profile-bars" to="/profile">
                    <Image
                      style={{ width: "50px", borderRadius: "50px" }}
                      src={
                        currentUser.photoURL === null
                          ? "http://www.umt.edu/_plugins/faculty-database/imx/default.jpg"
                          : currentUser.photoURL
                      }
                      alt="Avatar"
                    />

                    <span className="myprofile-text"> My Profile </span>
                  </Link>

                </Col>
                {/* 
              */}

                <Col lg={4} md={4} xs={4}>
                  <Link
                    to="#"
                    className="profile-bars col-offset-xs-2 col-xs-2"
                  >
                    <AiIcons.AiOutlineClose />
                  </Link>
                </Col>
              </Row>

              <Button variant="primary" style={{ fontSize: "10px", color: "#fff" }}>
                <p style={{ textAlign: "left", fontSize: "13px" }}>                  {`Agent Id: ${currentUser.agentID || ""}`}
                  <br></br>

                  {`Membership Id: ${currentUser.membershipID || ""}`} </p>
              </Button>

            </li>
            {SidebarData.map((item, index) => {
              return (
                <li key={index} className={item.cName}>
                  <Link to={item.path}>
                    {item.icon}
                    <span>{item.title}</span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>
      </IconContext.Provider>

      <div className="options ">
        <div className="wide-nav">

          <CustomTooltip

            title={`Wallet Amount: Rs ${currentUser.walletAmount || 0}\n` + "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\n\xa0\xa0\xa0\xa0\xa0\xa0\xa0\n\xa0\xa0\xa0\xa0\xa0\xa0\xa0\n" +
              `Wallet Hold Amount: Rs ${currentUser.walletHoldAmount || 0}`}
            placement="top"
          >
            <Link className="option" to="/">
              <AccountBalanceWalletIcon style={{ color: "rgb(63, 81, 181)" }} /> Rs{" "}
              {currentUser.walletAmount || 0}
            </Link>
          </CustomTooltip>


          <div className="mt-2" onClick={handleClick} style={{ color: "rgb(63, 81, 181)" }} >
            <NotificationsIcon />
            {notificationscount}
          </div>
          <Popper style={{ zIndex: "2", display: notifications.length > 0 ? "block" : "none" }} id={id} open={open1} anchorEl={anchorEl} transition>
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <div className={classes1.paper}>
                  {
                    notifications.map((element, count) => {
                      return (
                        <>
                          <ListItemText
                            key={count}
                            primary={element.title}
                            secondary={element.body}
                            style={{ backgroundColor: element.read ? "white" : "#F3F1F1", borderRadius: "10px", width: "25vw", textAlign: "left", padding: "5px" }}
                            onClick={() => handlesubmit(element)}
                          />

                          <Divider />
                        </>
                      )

                    })
                  }

                  <ListItemText
                    style={{ display: notifications.length > 0 ? "block" : "none" }}
                    onClick={() => { }}
                    primary="View All"
                  />
                </div>
              </Fade>
            )}
          </Popper>


          <Link className="option" to="/">
            HOME
          </Link>
          <Link className="option" to="/about">
            ABOUT US
          </Link>
          <Link className="option" to="/chats">
            CHATS
          </Link>
          {currentUser ? (
            <div
              className="option"
              onClick={() => {
                auth.signOut().catch((e) => {
                  console.error("Sign Out Error", e);
                });
                history.push("/");
              }}
            >
              SIGN OUT
            </div>
          ) : (
            <Link className="option" to="/signin">
              SIGN IN
            </Link>
          )}
        </div>
        <div className="short-nav" >
          <div className={classes.root}>
            <div>
              <Button
                ref={anchorRef}
                aria-controls={open ? "menu-list-grow" : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
              >
                <MoreVertIcon />
              </Button>
              <Popper
                style={{ zIndex: "5" }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "left bottom" ? "center top" : "left bottom"
                    }}
                  >
                    <Paper className="mr-5" >
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList
                          autoFocusItem={open}
                          id="menu-list-grow"
                          onKeyDown={handleListKeyDown}
                        >
                          <MenuItem onClick={handleClose}>   <CustomTooltip

                            title={`Wallet Amount: Rs ${currentUser.walletAmount || 0}` + "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\n\xa0\xa0\xa0\xa0\xa0\xa0\xa0\n\xa0\xa0\xa0\xa0\xa0\xa0\xa0\n\xa0\xa0\xa0\xa0\xa0\xa0\xa0\n" +
                              `Wallet Hold Amount: Rs ${currentUser.walletHoldAmount || 0}`}
                            placement="left"
                          >
                            <Link className="option" to="/">
                              <AccountBalanceWalletIcon style={{ color: "rgb(63, 81, 181)" }} /> Rs{" "}
                              {currentUser.walletAmount || 0}
                            </Link>
                          </CustomTooltip>
                          </MenuItem>
                          <MenuItem onClick={handleClose}>   <Link className="option" to="/">
                            HOME
                          </Link></MenuItem>
                          <MenuItem onClick={handleClose}>   <Link className="option" to="/about">
                            ABOUT US
                          </Link></MenuItem>

                          <MenuItem onClick={handleClose}> <Link className="option" to="/chats">
                            CHATS
                          </Link></MenuItem>

                          <MenuItem onClick={handleClose}>
                            {currentUser ? (
                              <div
                                className="option"
                                onClick={() => {
                                  auth.signOut().catch((e) => {
                                    console.error("Sign Out Error", e);
                                  });
                                  history.push("/");
                                }}
                              >
                                SIGN OUT
                              </div>
                            ) : (
                              <Link className="option" to="/signin">
                                SIGN IN
                              </Link>
                            )}

                          </MenuItem>

                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>
          </div>


        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});

export default connect(mapStateToProps)(Header);

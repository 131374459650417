import React, { useState } from "react";
import PropTypes from "prop-types";
import { withStyles, FormControlLabel, Grid, Switch, TextField, GridList, GridListTileBar } from "@material-ui/core";
const style = theme => ({
  root: {
    maxWidth: 400,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'start',
    overflow: 'hidden',
  },

  labelRoot: {
    fontSize: 15,
    [theme.breakpoints.down("xs")]: {
      fontSize: 13.5,
    }
  },


  error: {
    color: "red"
  },
  row: { borderBottom: `1px solid ${theme.palette.grey[100]}` },
  icon: {
    fill: theme.palette.grey[700],
    width: 28,
    height: 28
  }
});
const BankDetails = ({ classes, error, nameOnAccount, setnameOnAccount, accountNo, setaccountNo, accountType, setaccountType, bankName, setbankName, ifscCode, setifscCode }) => {


  const handlenameOnAccount = e => setnameOnAccount(e.target.value);
  const handleaccountNo = e => setaccountNo(e.target.value);
  const handleaccountType = e => setaccountType(e.target.value);
  const handlebankName = e => setbankName(e.target.value);
  const handleifscCode = e => setifscCode(e.target.value);



  return (

    <Grid container spacing={3} >

      <Grid container direction="row" alignItems="center" alignContent="center" className="col-sm-6">
        <Grid item xs={12}>
          <TextField
            name="nameOnAccount"
            value={nameOnAccount}
            fullWidth
            margin="normal"
            label="Enter Name on Account"
            variant="outlined"
            onChange={handlenameOnAccount}
            InputLabelProps={{
              classes: {
                root: classes.labelRoot
              }
            }}
          />

        </Grid>
        <Grid item xs={12} className={classes.error}>{nameOnAccount ? "" : error.nameOnAccount} </Grid>

      </Grid>

      <Grid container direction="row" alignItems="center" alignContent="center" className="col-sm-6">

        <Grid item xs={12}>
          <TextField
            name="accountNo"
            value={accountNo}
            fullWidth
            margin="normal"
            label="Enter Account No"
            variant="outlined"
            onChange={handleaccountNo}
            InputLabelProps={{
              classes: {
                root: classes.labelRoot
              }
            }}
          />

        </Grid>
        <Grid item xs={12} className={classes.error}>{accountNo ? "" : error.accountNo} </Grid>

      </Grid>



      <Grid container direction="row" alignItems="center" alignContent="center" className="col-sm-6">

        <Grid item xs={12}>
          <TextField
            name="accountType"
            value={accountType}
            fullWidth
            margin="normal"
            label="Enter Account Type"
            variant="outlined"
            onChange={handleaccountType}
            InputLabelProps={{
              classes: {
                root: classes.labelRoot
              }
            }}
          />


        </Grid>
        <Grid item xs={12} className={classes.error}>{accountType ? "" : error.accountType} </Grid>

      </Grid>






      <Grid container direction="row" alignItems="center" alignContent="center" className="col-sm-6">

        <Grid item xs={12}>
          <TextField
            name="bankName"
            value={bankName}
            fullWidth
            margin="normal"
            label="Enter Bank Name"
            variant="outlined"
            onChange={handlebankName}
            InputLabelProps={{
              classes: {
                root: classes.labelRoot
              }
            }}
          />

        </Grid>
        <Grid item xs={12} className={classes.error}>{bankName ? "" : error.bankName} </Grid>

      </Grid>







      <Grid container direction="row" alignItems="center" alignContent="center" className="col-sm-6">

        <Grid item xs={12}>
          <TextField
            name="ifscCode"
            value={ifscCode}
            fullWidth
            margin="normal"
            label="Enter IFSC Code"
            variant="outlined"
            onChange={handleifscCode}
            InputLabelProps={{
              classes: {
                root: classes.labelRoot
              }
            }}
          />
        </Grid>
        <Grid item xs={12} className={classes.error}>{ifscCode ? "" : error.ifscCode} </Grid>


      </Grid>










      <Grid item xs={12} className={classes.error}>{error.bankVerify} </Grid>
    </Grid>

  );
};


export default withStyles(style)(BankDetails);

import React from "react";
import "./App.css";

import HomePage from "./pages/homepage/homepage.component";
import { Switch, Route, Redirect } from "react-router-dom";
import ShopPage from "./pages/shop/shop.component";
import Header from "./components/header/header.component";
import SignInAndSignUpPage from "./pages/sign-in-and-sign-up/sign-in-and-sign-up.component";
import { auth, createUserProfileDocument, onMessageListener } from "./firebase/firebase.utils";
import firebase from "./firebase/firebase.utils";

import { connect } from "react-redux";
import { setCurrentUser } from "./redux/user/user.actions";

import Myprofile from "./pages/profile/profile";
import MyTree from './pages/tree/my-tree.components'
import mySMSGroup from "./pages/smsGroups/smsgroups";
import MySMSGroupPage from "./pages/smsGroups/smsGroupPage";
import KYCHomePage from "./pages/kyc/kycHomePage";
import KYCPage from "./pages/kyc/kycPage";
import Payments from "./components/payments/payments.component";
import Earning from "./components/earnings/earning.component";
//import 'bootstrap/dist/css/bootstrap.min.css';
//import Chats from "./pages/chats/chats";
import Chat from "./pages/chats/chat";
import Sidebar from "./pages/chats/Sidebar";
import { Row, Col } from 'react-bootstrap';
import Mynotifications from "./pages/notifications/notifications";
import Ranks from "./pages/ranks/ranks";
import RankHomepage from "./pages/ranks/rankhomepage";
import Rules from "./pages/rules/rules";
import About from "./pages/about/about";
import OrgChartTree from "./pages/tree/tree";
import Documents from './pages/documents/documents'
import MyTreeHomepage from './pages/tree/mytreehomepage'
import MyAgentTree from './pages/tree/mytree';
import { Toast } from 'react-bootstrap';
import FormComponent from "./components/unregistered-homepage/form";
const ChatRoom = () => {
  return (
    <div className="app__body">
      {/* <Row>
           <Col lg="3">
           <Sidebar />          
           </Col>
          <Col lg="9">
          <Chat />
          </Col>
        </Row> */}
      <Sidebar />
      <Chat />

    </div>
  )
}
class App extends React.Component {
  authSubscription = null;

  constructor(props) {
    super(props);
    this.state = {
      show: false,
      notificationTitle: "",
      notificationBody: "",
    };
  }


  componentDidMount() {
    const { currentUser, setCurrentUser } = this.props;
    console.log(this.props);
    this.authSubscription = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const userRef = await createUserProfileDocument(user);

        userRef.onSnapshot((snapshot) => {
          setCurrentUser({
            id: snapshot.id,
            ...snapshot.data(),
          });
        });
      } else {
        setCurrentUser(user);
      }
    });

    const msg = firebase.messaging();
    msg.requestPermission().then(() => {
      return msg.getToken({ vapidKey: 'BOeF-2nZN6Ya0kG8B2w987nzqZ0ujkESq-FA6rm60y4GpfxOgvznRImM9avLU2oglX6nnstR4ieOSpipTu3E4ZE' })
    }).then((data) => {
      console.log(data);
    })



    onMessageListener().then(payload => {
      // setShow(true);
      // setNotification({title: payload.notification.title, body: payload.notification.body})
      this.setState({
        show: true,
        notificationTitle: payload.notification.title,
        notificationBody: payload.notification.body,

      })
      console.log(payload);
    }).catch(err => console.log('failed: ', err));

  }

  componentWillUnmount() {
    this.authSubscription();
  }

  render() {
    return (
      <div>

        {this.props.currentUser ? <Header /> : null}

        <Toast
          style={{
            position: 'absolute',
            top: 50,
            right: 10,
            zIndex: 1
          }}
          onClose={() => this.setState({ show: false })} show={this.state.show} delay={3000}
        >
          <Toast.Header>
            <strong className="mr-auto">{this.state.notificationTitle}</strong>
            <small>just now</small>
          </Toast.Header>
          <Toast.Body>{this.state.notificationBody}</Toast.Body>
        </Toast>


        <Switch>
          <Route exact path="/" render={(props) => <HomePage />} />
          <Route

            path="/signin/:paramater?"
            render={(props) =>
              //redirect to homepage when signin is completed

              this.props.currentUser ? (
                <Redirect to="/" />
              ) : (
                <SignInAndSignUpPage {...props} />
              )
            }
          // component={SignInAndSignUpPage}
          />
          <Route exact path="/referral/:paramater?" render={(props) => <HomePage {...props} />} />
          <Route path="/profile" component={Myprofile} />
          <Route path="/tree" component={MyTree} />
          <Route path="/SMSGroups" component={mySMSGroup} />
          <Route path="/SMSGroupsPage/:smsId?" component={MySMSGroupPage} />
          <Route path="/kyc" component={KYCHomePage} />
          <Route path="/verifyKYC" render={(props) => <KYCPage {...props} />} />
          {/* <Route path="/payments" component = {Payments}/> */}
          <Route path="/myearnings" component={Earning} />
          <Route path="/chats" component={ChatRoom} />
          <Route path="/rooms/:roomId" component={ChatRoom} />
          <Route path="/notifications" component={Mynotifications} />
          {/* <Route path="/ranks" component={Ranks} /> */}
          <Route path="/rules" component={Rules} />
          <Route path="/about" component={About} />
          <Route path="/documents" component={Documents} />
          <Route path="/mytree" component={MyTreeHomepage} />
          <Route path="/myagenttree" render={(props) => <MyAgentTree {...props} />} />
          <Route path="/ranks" render={(props) => <Ranks {...props} />} />

          <Route path="/rankpage" component={RankHomepage} />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = ({ user, referred }) => ({
  currentUser: user.currentUser,
  referredId: referred.referredId,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);

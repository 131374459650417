//import { act } from "react-dom/test-utils";
import { SET_CURRENT_USER } from "./user.types";

const INITIAL_STATE = {
    currentUser: undefined,
 
}

const userReducer = (state = INITIAL_STATE, action) => {
    
    switch (action.type) {
        case SET_CURRENT_USER:
            return {
                ...state,
                currentUser: action.payload
            }
   
        default:
            return state;
    }
}

export default userReducer;
import { useState, useEffect } from "react";
import {Card,Button,Col,Row} from "react-bootstrap";
import { firestore } from "../../firebase/firebase.utils";
import {connect} from 'react-redux';



import NotificationBar from "./notificationBar";
const Mynotifications = ({currentUser}) => {

    console.log(currentUser);

    const [data, setdata] = useState([]);
 
    useEffect(() => {
        if(currentUser !== undefined){
            
            firestore.collection("users").doc(currentUser.id).collection("notifications").onSnapshot((snapshot) => {
                data.length = 0;
                setdata([])
                snapshot.docs.forEach(element => {
                    setdata(old => [...old,{"data":element.data(),"id":element.id}])
                });
            })
        }
        return () => {
            
        }
    }, [currentUser])

    console.log(data);
    return(
        <>
        <h1 style={{textAlign:"center",color:"rgb(63, 81, 181)"}}>My Notifications</h1>
        <Card>

    <Col>
    {
         data.filter(name => name.data.status === "active").length ? data.filter(name => name.data.status === "active").map((notif,index) => {

            console.log(data);
            return(
              <NotificationBar key={index} notif={notif}/>
            )
        })
        :
        <h3 style={{textAlign:"center",color:"rgb(63, 81, 181)"}}>Oops!, No New Notifications</h3>
    }
 
    </Col>
  
</Card>
        
        </>
    )
}

//export default Mynotifications;



const mapStateToProps = ({ user,referred }) => ({
    currentUser: user.currentUser,
    referredId: referred.referredId,
  });
  
  
  
  export default connect(mapStateToProps, null)(Mynotifications);
import React from "react";
import "./kycPage.css";
import Content from "../../components/kyc/kyc";
import { connect } from "react-redux";

var index;

const KYCPage = ({ currentUser, location }) => {
  // const  parameter1  = props.match.params.kycindex;
  // console.log(parameter1);

  index =  location?.state?.currentindex || index || 0;
  return (
    <div className="Kyc">
      <div className="wizard">
        <Content currentUser={currentUser} currentindex={index} />
      </div>
    </div>
  );
};

const mapStateToProps = ({ user, referred }) => ({
  currentUser: user.currentUser,
  referredId: referred.referredId,
});

export default connect(mapStateToProps, null)(KYCPage);

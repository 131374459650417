import React, { useState, useEffect } from 'react';
import './Sidebar.css';
import SidebarChat from './Sidebarchat';
import { Avatar, IconButton} from '@material-ui/core';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import ChatIcon from '@material-ui/icons/Chat';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { SearchOutlined } from '@material-ui/icons';
//import {useStateValue} from './StateProvider';
import { firestore as db} from '../../firebase/firebase.utils';

import { connect } from "react-redux";

function Sidebar({currentUser}) {
 
    console.log(currentUser);
    const [sidebarBool, setsidebarBool] = useState(true);
    const [input, setInput] = useState("");
    const [rooms,setRooms] = useState([]);
  //  const [{user},dispatch] = useStateValue();
  const handleChange = (e) => {
    setsidebarBool(false);
    setInput(e.target.value);
  };

    useEffect (() => {

        const unsubscribe = ()=> {
            console.log("hello");
        };
    // const unsubscribe = db.collection("chats").doc(currentUser.id).onSnapshot((snapshot) => 
          
    //         setRooms(snapshot.docs.map((doc) => ({

    //                    id: doc.id,
    //                    data: doc.data(),

    //                })
                    
    //                 ))
    //   );
if(currentUser !== undefined ){

    console.log(currentUser.id);
    
    let documentRef = db.collection("users").doc(currentUser.id).collection("contactList").onSnapshot((snapshot) => {
        setRooms(snapshot.docs.map((doc) => ({

            id: doc.id,
            data: doc.data(),

        })
         
         ))
    });

   
   
//console.log({collections: collectionIds});
    //   const unsubscribe = db.collection("chats").doc(currentUser.id).collection().get().then(documentSnapshot => {
    //     console.log(documentSnapshot);
    //   }, err => {
    //     console.log(`Encountered error: ${err}`);
    //   });

}
      return () => {
          unsubscribe();
      }

    },[currentUser]);

    return (
        <>{ !currentUser ? <p>loading</p> :
        <div className="sidebar">

        <div className="sidebar__header">

        
        <IconButton>
        <Avatar src={currentUser.photoURL} />
        </IconButton>

        <div className="sidebar__headerRight">

       <p>{currentUser.displayName}</p>


        </div>

        </div>

        <div className="sidebar__search">


        <div className="sidebar__SearchContainer">
        <SearchOutlined/>
        <input placeholder="Search or start new chat" type="text"   value={input} onChange={handleChange} />

        </div>


        </div>

        <div className="sidebar__chat">

        <SidebarChat addnewChat />
        {rooms.map(room => (
            <SidebarChat key = {room.id} id ={room.id} input={input}/>
        ))

        }


        </div>




            
        </div>
 } </>
    )
}
const mapStateToProps = ({ user,referred }) => ({
    currentUser: user.currentUser,
    referredId: referred.referredId,
  });

//export default Chat
export default connect(mapStateToProps, null)(Sidebar);

//export default Sidebar;
import React, { useState } from "react";
import {
  withStyles, TextField, Grid, RadioGroup,
  FormControlLabel,
  Radio
} from "@material-ui/core";
import InputAdornment from '@material-ui/core/InputAdornment';



const genderList = ["Male", "Female", "Other"];

const style = theme => {
  return {
    root: {
      padding: theme.spacing.unit * 3,
      height: "100%",
      maxWidth: 450,
      margin: "0 auto"
    },
    labelRoot: {
      fontSize: 15,
      [theme.breakpoints.down("xs")]: {
        fontSize: 13.5,
      }
    },
    error: {
      color: "red"
    },
    icon: {
      fill: theme.palette.grey[500]
    },
    icon1: {
      height: 28,
      width: 28,
      fill: theme.palette.grey[500]
    },
    navigation: {

      width: '95px',
      [theme.breakpoints.down("xs")]: {
        width: '40px',
      }
    },
  };
};

const PersonalDetails = ({ classes, error, name, setName, phoneNo, setphoneNo, selectedDate, handleDateChange, gender, setGender }) => {

  const handleGenderChange = e => setGender(e.target.value);
  const handleNameChange = e => setName(e.target.value);
  const handlephoneNoChange = e => setphoneNo(e.target.value);


  var phonenumbers = /^\d{10}$/;


  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      className={classes.root}
    >
      <Grid container direction="row" alignItems="center" alignContent="center">

        {/* <Grid item xs={12}>
          <TextField
            name="name"
            value={name}
            fullWidth
            margin="normal"
            label="Enter your Full name"
            variant="outlined"
            onChange={handleNameChange}
          />
        </Grid>
        <Grid item xs={12} className={classes.error}>{name ? "": error.name} </Grid> */}


        <Grid item xs={12}>
          <TextField
            name="phoneNo"
            value={phoneNo}
            fullWidth
            margin="normal"
            label="Enter Phone No as per Aadhar Card"
            variant="outlined"
            onChange={handlephoneNoChange}
            InputProps={{
              startAdornment: <InputAdornment position="start">+91</InputAdornment>,
            }}
            InputLabelProps={{
              classes: {
                root: classes.labelRoot
              }
            }}
          />
        </Grid>

        <Grid item xs={12} className={classes.error}>{phoneNo ? "" : error.phoneNo} </Grid>
        <Grid item xs={12} className={classes.error}>{phoneNo.match(phonenumbers) ? "" : error.phoneNolength} </Grid>


        {/* 
<KeyboardDatePicker
     margin="normal"
     id="date-picker-dialog"
     label="Date picker dialog"
     format="MM/dd/yyyy"
     value={selectedDate}
     onChange={handleDateChange}
     KeyboardButtonProps={{
       'aria-label': 'change date',
     }}
   /> */}




        <Grid item xs={12}           >
          <RadioGroup
            name="daypart"
            value={gender}
            onChange={handleGenderChange}
            row
          //          style={{width:'150px'}}
          >
            {genderList.map(part => (
              <FormControlLabel
                key={part}
                value={part}
                control={<Radio color="primary" />}
                label={part}
                labelPlacement="bottom"
                checked={gender === part}
                className={classes.navigation}

              />
            ))}
          </RadioGroup>
        </Grid>





      </Grid>{" "}

      {/*
        <Grid item xs={2}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 463.009 463.009"
            className={classes.icon}
            width="24px"
            height="24px"
          >
            {phoneIcon}
          </svg>
        </Grid>
        <Grid item xs={10}>
          <TextField
            name="phone"
            value={phone}
            fullWidth
            margin="normal"
            label="Enter your phone number "
            variant="outlined"
            onChange={handlePhoneChange}
          />
        </Grid>
     
*/}





    </Grid>
  );
};

export default withStyles(style)(PersonalDetails);

import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';

export const SidebarData = [
  {
    title: 'Home',
    path: '/',
    icon: <AiIcons.AiFillHome />,
    cName: 'nav-text'
  },
  {
    title: 'KYC',
    path: '/kyc',
    icon: <IoIcons.IoIosPaper />,
    cName: 'nav-text'
  },
  {
    title: 'My SMS Group',
    path: '/SMSGroups',
    icon: <FaIcons.FaCartPlus />,
    cName: 'nav-text'
  },
  // {
  //   title: 'Earnings',
  //   path: '/Myearnings',
  //   icon: <IoIcons.IoMdPeople />,
  //   cName: 'nav-text'
  // },
  {
    title: 'My Tree',
    path: '/mytree',
    icon: <FaIcons.FaEnvelopeOpenText />,
    cName: 'nav-text'
  },
  {
    title: 'Add Guarantor',
    path: '/notifications',
    icon: <IoIcons.IoMdHelpCircle />,
    cName: 'nav-text'
  },
  {
    title: 'Ranks',
    path: '/ranks',
    icon: <IoIcons.IoMdHelpCircle />,
    cName: 'nav-text'
  },
  {
    title: 'Documents',
    path: '/documents',
    icon: <IoIcons.IoMdHelpCircle />,
    cName: 'nav-text'
  }
];

import React from 'react';
import './share.css';
import Fab from '@material-ui/core/Fab';
import ShareIcon from '@material-ui/icons/Share';


import { FacebookIcon, TwitterIcon, WhatsappIcon, TelegramIcon, EmailIcon, RedditIcon, TumblrIcon } from "react-share";
import {
    FacebookShareButton,
    GooglePlusShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    TelegramShareButton,
    WhatsappShareButton,
    PinterestShareButton,
    VKShareButton,
    OKShareButton,
    RedditShareButton,
    TumblrShareButton,
    LivejournalShareButton,
    MailruShareButton,
    ViberShareButton,
    WorkplaceShareButton,
    EmailShareButton,
} from 'react-share';


class FloatingMenuItem extends React.Component {

    handleClick() {
        this.props.action();
    }

    render() {
        let buttonStyle = {
            backgroundImage: `url(${this.props.icon})`
        }

        let label;

        if (this.props.label) {
            label = <label>{this.props.label}</label>;
        }

        return <div
            onClick={this.handleClick.bind(this)}
            className="floating-menu-item">
            {label}
            <div className="floating-menu-icon"><i className="material-icons">{this.props.icon}</i></div>
        </div>;
    }
}

class FloatingMenu extends React.Component {
    constructor() {
        super();

        this.state = {
            toggled: false
        }
    }



    toggleMenu() {
        this.setState({ toggled: !this.state.toggled });
    }

    sayhi() {
        alert("hiii");
    }

    render() {
        console.log(this.props.agentID);

        let buttons = [];
        let className = "floating-menu";
        let icon = "add";
        let url = `https://kmcsociety.com/referral/${this.props.agentID}`;


        if (this.state.toggled) {
            className += " open";
            icon = "clear";
            buttons.push(<> <WhatsappShareButton className="mr-1" url={url}> <WhatsappIcon size={32} round /> Whatsapp
            </WhatsappShareButton> <br></br> <br></br></>);
            buttons.push(<><FacebookShareButton className="mr-1" url={url}> <FacebookIcon size={32} round /> Facebook
            </FacebookShareButton> <br></br> <br></br> </>);
            buttons.push(<><TwitterShareButton className="mr-3" url={url}> <TwitterIcon size={32} round /> Twitter
            </TwitterShareButton> <br></br> <br></br> </>);
            buttons.push(<>   <TelegramShareButton url={url}> <TelegramIcon size={32} round /> Telegram
            </TelegramShareButton><br></br> <br></br> </>);
            buttons.push(<> <EmailShareButton className="mr-3" url={url}> <EmailIcon size={32} round /> Email
            </EmailShareButton><br></br> <br></br> </>);

        }

        buttons.push(<Fab variant="extended" onClick={() => this.toggleMenu()}>
            <ShareIcon />
            Share
        </Fab>
        );

        return <div className="container">
            <div className={className}>
                {buttons}
            </div>
        </div>;
    }
}


export default FloatingMenu;
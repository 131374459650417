import { Col } from 'react-bootstrap';
import { useEffect } from 'react'
import registration from './registration.jpg';
import TAN from './TAN.jpg';
import PAN from './PAN.jpg';
import { firestore } from '../../firebase/firebase.utils';

const Documents = () => {


    useEffect(() => {
        firestore
            .collection("SMSGroupRequest")
            .orderBy("createdAt", "asc")
            .where("agenttype", "==", "staragent")
            .limit(4).onSnapshot((snapshot) => {
                console.log(snapshot.docs.length);
                snapshot.docs.forEach(element => {
                    console.log(element.data())
                });
            })
        return () => {

        }
    }, [])
    return (
        <Col>
            <h2 style={{ color: "blue", textAlign: "center", margin: "40px" }}>Reg No : AMC/MNR/DCO/2013/2910</h2>
            <img style={{ margin: "0 auto", display: "block" }} src={registration}></img>
            <hr style={{ marginBottom: "40px", marginTop: "40px" }}></hr>
            <h2 style={{ color: "blue", textAlign: "center", margin: "40px" }}>  TAN No :
                :23560470006054
            </h2>
            <img style={{ margin: "0 auto", display: "block" }} src={TAN}></img>
            <hr style={{ marginBottom: "40px", marginTop: "40px" }}></hr>
            <h2 style={{ color: "blue", textAlign: "center", margin: "40px" }}>PAN No :
                AACAK6408M
            </h2>
            <img style={{ margin: "0 auto", display: "block" }} src={PAN}></img>
        </Col>
    )
}


export default Documents;
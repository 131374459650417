import React, { useState, useEffect } from "react";
import "./smsGroupPage.css";
import axios from "axios";
import logo from "../../logo.svg";
import CheckIcon from "@material-ui/icons/Check";
import SendIcon from "@material-ui/icons/Send";
import TextField from "@material-ui/core/TextField";
import { CircularProgress } from "@material-ui/core";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
} from "react-router-dom";
import { connect } from "react-redux";
import { useSelector } from "react-redux";
import firebase, { getTree, firestore } from "../../firebase/firebase.utils";
import { Button, Card, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { IconButton, Avatar } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import LockIcon from "@material-ui/icons/Lock";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import AccountBalanceWalletOutlinedIcon from "@material-ui/icons/AccountBalanceWalletOutlined";
import { PanoramaWideAngle } from "@material-ui/icons";

import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
} from "@material-ui/core";
import { logRoles } from "@testing-library/dom";

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

function printAllVals(obj, user, currentid) {
  //console.log(obj);

  for (let k in obj) {
    if (typeof obj[k] === "object") {
      printAllVals(obj[k], user, currentid);
    } else {
      //    console.log(obj["photoURL"]);
      var temp = {
        displayName: obj["displayName"],
        id: obj["id"],
        photoURL: obj["photoURL"],
      };
      if (!user.includes(temp) && !(obj["id"] === currentid)) {
        user.push(temp);
      }
      // base case, stop recurring
      // console.log(obj[k]);
      //console.log(obj["id"]);
      //        console.log(obj["displayName"]);
      //console.log(obj["photoURL"]);
      // obj["id"] = user;
      // obj["displayName"] = displayName;
      // obj["photoURL"] = photoURL;
      // obj["children"] = [
      //   { name: obj["name"] + "0" },
      //   { name: obj["name"] + "1" },
      // ];
    }
  }
  return user;
}

const MySMSGroupPage = ({ currentUser }) => {
  let { smsId } = useParams();
  console.log(smsId)
  const [lastpaidweek, setlastpaidweek] = useState("");
  const [members, setmembers] = useState([]);
  const [randommembers, setrandommembers] = useState([]);
  const [smsData, setsmsData] = useState({});
  const [currentWeek, setcurrentWeek] = useState(1);
  const [startDate, setstartDate] = useState(new Date());
  const [amount, setamount] = useState(0);
  const [currentInstallment, setcurrentInstallment] = useState(0);
  const [currentWithdrawal, setcurrentWithdrawal] = useState(0);
  const [searchText, setsearchText] = useState("");
  const [requestedList, setrequestedList] = useState([]);

  const [withdrawalStatus, setwithdrawalStatus] = useState(false);
  const [withdrawalData, setwithdrawalData] = useState(0);
  let count = 1;
  const [shouldShowDialog, setShowDialog] = useState(false);
  const [treeuser, settreeuser] = useState([]);
  const [treeuserdata, settreeuserdata] = useState([]);
  const [name, setname] = useState([
    "name",
    "user1",
    "user2",
    "user3",
    "user4",
  ]);
  const [photo, setphoto] = useState(["photo"]);
  const treeusers = [];

  function handleClose() {
    setShowDialog(false);
    //forceUpdate();
  }

  const sendNotifications = async (id, displayName) => {
    await firestore
      .collection("users")
      .doc(id)
      .collection("notifications")
      .doc()
      .set({
        payload: {
          title: `${currentUser.displayName} is Requesting to be Guarantor of his Withdrawal`,
          body: "Are u want to be guarantor ?",
          smsGroup: smsId,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          senderId: currentUser.id,
          receiverId: id,
        },
        type: "withdrawal_guarantor",
        status: "active",
      });

    await firestore
      .collection("users")
      .doc(currentUser.id)
      .collection("SMSGroups")
      .doc(smsId)
      .collection("requested")
      .doc(id)
      .set({
        approvedAmount: 0,
        status: "pending",
      });
  };

  console.log(withdrawalData -
    (currentUser.walletAmount || 0) -
    (currentUser.walletHoldAmount || 0))
  const handlewithdraw = async () => {
    if (currentUser) {
      await firestore
        .collection("users")
        .doc(currentUser.id)
        .collection("SMSGroups")
        .doc(smsId)
        .get()
        .then((snapshot) => {

          setwithdrawalData(snapshot.data().withdrawAmount);



          firestore
            .collection("users")
            .doc(currentUser.id)
            .collection("SMSGroups")
            .doc(smsId)
            .set(
              {
                reqAmount:
                  snapshot.data().withdrawalAmount -
                  (currentUser.walletAmount || 0) -
                  (currentUser.walletHoldAmount || 0),
              },
              { merge: true }
            );
        })
        .catch((err) => { });
    }

    await getTree().on("value", (e) => {
      // console.log(e.val());
      var s =
        e.val() ||
        '{"name":"Root","id":"dhcGbpt2IAReHUW3uKniVFRXZvQ2","displayName":"KMC","children":[{"name":"0"},{"name":"1"}]}';

      //  console.log(orgChart)

      //setOrgChart(JSON.parse(s));
      console.log(JSON.parse(s));

      var realdata = printAllVals(JSON.parse(s), [], currentUser.id);
      //console.log(data);
      //settreeuser(data)
      //var uniquedata = data.filter((set => f => !set.has(f.id) && set.add(f.id) && set.add(f.displayName) && set.add(f.photoURL))(new Set));

      settreeuserdata(
        realdata.filter(
          ((set) => (f) =>
            !set.has(f.id) &&
            set.add(f.id) &&
            set.add(f.displayName) &&
            set.add(f.photoURL))(new Set())
        )
      );

      //get child and parent

      if (currentUser) {
        if (s.includes(currentUser.id)) {
          //          console.log(currentUser);

          var startindex = s.indexOf(currentUser.id);
          var parentstartindex1 = s.indexOf(currentUser.id) - 9;
          var lastindex = s.indexOf(currentUser.id);
          var parentstartindex = s.indexOf(currentUser.id);

          var parentstartname = s.indexOf(currentUser.id);
          var parentlastname = s.indexOf(currentUser.id);
          var count = 0;
          var setLast = true;
          var setFirst = true;
          var setparentFirst = true;
          var check = false;
          var i = startindex + 1;
          var j = startindex - 1;

          console.log(i);
          if (s !== "{}") {
            while (setFirst) {
              if (s.charAt(j) === "{") {
                startindex = j;
                setFirst = false;
              }
              j--;
            }

            while (setLast) {
              if (s.charAt(i) === "[") {
                console.log(s.charAt(i));
                count++;
                check = true;
              } else if (s.charAt(i) === "]") {
                count--;
              }

              if (check) {
                if (count === 0) {
                  lastindex = i + 1;
                  setLast = false;
                }
              }

              // if(i === 250){
              //   start = false;
              // }
              i++;
              //console.log(i);
            }
            //   var y = startindex;
            //   var parentstartindex1 = startindex;
            //   while(setparentFirst1){
            //     console.log("fggh");
            //     if(s.charAt(y) === '"' && s.charAt(y+1) === 'i' && s.charAt(y+2) === 'd' && s.charAt(y+3) === '"'){
            //       parentstartindex1 = y;
            //       setparentFirst1 = false;
            //   }
            //   y++;
            // }
            console.log(s.substring(startindex + 9, parentstartindex1));
            var x = s.substring(startindex + 9, parentstartindex1);
            var setparentFirst1 = true;
            var k = s.indexOf('"' + x + '"');
            while (setparentFirst) {
              if (
                s.charAt(k - 1) === '"' &&
                s.charAt(k) === "i" &&
                s.charAt(k + 1) === "d" &&
                s.charAt(k + 2) === '"'
              ) {
                parentstartindex = k;
                setparentFirst = false;
              }
              k++;
            }

            // var setparentName = true;
            // var parentfirstname = k;
            // var parentlastname = k;
            // while(setparentName){
            //   if(s.charAt(k) === ':'){
            //     parentfirstname = k+1;
            //     parentlastname = k+1;
            //     setparentName = false;
            //     var stData = true;
            //     k = k+2;
            //     while(stData){
            //       if(s.charAt(k) === '"'){
            //         parentlastname = k;
            //         stData = false
            //       }
            //       k++;
            //     }
            //   }
            //   k++;
            // }

            var z1 = parentstartindex + 50;
            parentstartname = parentstartindex + 50;
            parentlastname = parentstartindex + 50;
            var z2 = parentstartindex + 51;

            console.log(z1);
            var count = 0;
            var nameFound = true;
            while (nameFound) {
              if (
                s.charAt(z1) === '"' &&
                s.charAt(z1 + 1) === "," &&
                s.charAt(z1 + 2) === '"'
              ) {
                count++;
                parentlastname = z1;
                nameFound = false;
              }
              z1++;
              console.log(z1);
            }
          }
          console.log("sdhfjsnsg");
          console.log(parentstartname);
          console.log(parentlastname);

          console.log(startindex);
          console.log(parentstartindex1);

          console.log(lastindex);
          console.log(parentstartindex);
          console.log(s.substring(parentstartindex + 5, parentstartindex + 33));

          console.log(s.substring(parentstartname, parentlastname));

          //setparentId(s.substring(parentstartindex+5,parentstartindex+33));
          var temp = [
            {
              id: s.substring(parentstartindex + 5, parentstartindex + 33),
              displayName: s.substring(parentstartname, parentlastname),
            },
          ];

          var myData = s.substring(startindex, lastindex) + "}";
          console.log(myData);

          // setOrgChart1(JSON.parse(myData));

          var data = printAllVals(JSON.parse(myData), temp, currentUser.id);
          //console.log(data);
          //settreeuser(data)
          var unique = data.filter(
            ((set) => (f) =>
              !set.has(f.id) &&
              set.add(f.id) &&
              set.add(f.displayName) &&
              set.add(f.photoURL))(new Set())
          );

          settreeuser(
            data.filter(
              ((set) => (f) =>
                !set.has(f.id) &&
                set.add(f.id) &&
                set.add(f.displayName) &&
                set.add(f.photoURL))(new Set())
            )
          );
        }
      }

      // if(parentId){
      // console.log("fghvhjhhghghvhv");
      // firestore.collection("users").doc(parentId).get().then((e) =>{
      // if(e.data() !== undefined){
      //   console.log(e.data());
      // setparentName(e.data().displayName);
      // setparentPhoto(e.data().photoURL);
      // }});
      // }

      // var data =   printAllVals(JSON.parse(s),[],currentUser.id);
      // //console.log(data);
      // //settreeuser(data)
      // var unique = data.filter((set => f => !set.has(f.id) && set.add(f.id) && set.add(f.displayName) && set.add(f.photoURL))(new Set));
      // settreeuser(data.filter((set => f => !set.has(f.id) && set.add(f.id) && set.add(f.displayName) && set.add(f.photoURL))(new Set)));
      //console.log(unique.splice(1,));
      //console.log(typeof unique);
      //console.log(unique);
      // for (let index = 1; index < unique.length; index++) {
      //   treeusers.push(unique[index]);
      //  // console.log(unique[index]);

      // }
      //settreeuser([unique.splice(1,)]);
      //settreeuser(oldArray => [ unique.splice(1,)]);
      setShowDialog(!shouldShowDialog);
    });
  };

  const [paid, setpaid] = useState(false);

  const handleChange = (event) => {
    setsearchText(event.target.value);
  };

  const getNamePhoto = async (user) => {
    await firestore
      .collection("users")
      .doc(user)
      .get()
      .then((result) => {
        console.log(result.data().displayName);
        console.log(result.data().photoURL);
        setname((oldArray) => [...oldArray, result.data().displayName]);
      })
      .catch((err) => { });
  };

  const handlepay = async () => {
    //  e.preventDefault();

    // firestore.collection("sms-groups").doc(smsId).collection("payments").doc("payments").collection(`Week${currentWeek}`).doc(currentUser.id).set({
    //   payment:"paid",
    //   paidAt: firebase.firestore.FieldValue.serverTimestamp(),
    // })
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    // creating a new order


    //console.log(lastpaidweek + 5);
    const result = await axios.post("https://us-central1-react-test-5e32f.cloudfunctions.net/getOrderId", {

      type: `week${currentWeek}`,
      lastpaidweek: lastpaidweek

    },
      {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Access-Control-Allow-Origin': '*'
        },
      });

    if (!result) {
      alert("Server error. Are you online?");
      return;
    }

    // Getting the order details back
    const { amount, id: order_id, currency } = result.data;

    firestore
      .collection("payments")
      .doc(order_id)
      .set({
        uid: currentUser.id,
        paymentType: "SMS",
        paymentTime: firebase.firestore.FieldValue.serverTimestamp(),
        paymentWeek: `week${currentWeek}`,
        smsGroup: smsId,
      });

    const options = {
      key: "rzp_test_7xxFrCJgl4GYWB", // Enter the Key ID generated from the Dashboard
      amount: amount.toString(),
      currency: currency,
      name: "KMC",
      description: "Test Transaction",
      image: { logo },
      order_id: order_id,
      handler: async function (response) {
        const data = {
          orderCreationId: order_id,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
        };

        const result = await axios.post(
          "https://us-central1-react-test-5e32f.cloudfunctions.net/paymentSuccessHandler",
          data,
          {
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Access-Control-Allow-Origin': '*'
            },
          }
        );

        // alert(result.data.msg);
      },
      prefill: {
        name: "KMC",
        email: "kmcsociety.app@gmail.com",
        contact: "9652961216",
      },
      notes: {
        address: "KALPAVRUKSHA COOPERATIVE SOCIETY LTD",
      },
      theme: {
        color: "#61dafb",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  const users = [
    { displayName: "user1" },
    { displayName: "user2" },
    { displayName: "user3" },
    { displayName: "user4" },
    { displayName: "user5" },
    { displayName: "user6" },
    { displayName: "user7" },
    { displayName: "user8" },
    { displayName: "user9" },
    { displayName: "user10" },
    { displayName: "user11" },
    { displayName: "user12" },
    { displayName: "user13" },
    { displayName: "user14" },
    { displayName: "user15" },
    { displayName: "user16" },
    { displayName: "user17" },
    { displayName: "user18" },
  ];

  function shuffleArray(array) {
    let i = array.length - 1;
    for (; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  }

  useEffect(() => {
    // data = [];
    if (currentUser !== undefined) {
      firestore
        .collection("sms-groups")
        .doc(smsId)
        .collection("payments")
        .doc("payments")
        .collection(`week${currentWeek}`)
        .onSnapshot((snapshot) => {
          snapshot.docs.forEach((element) => {
            console.log(element.id);
            if (currentUser.id === element.id) {
              setpaid(true);
            }
          });

          //          console.log(snapshot.docs);
        });
      firestore
        .collection("users")
        .doc(currentUser.id)
        .collection("SMSGroups")
        .doc(smsId)
        .collection("requested")
        .onSnapshot((snapshot) => {
          setrequestedList([]);
          snapshot.docs.forEach((element) => {
            setrequestedList((old) => [...old, element.id]);
          });
        });

      firestore
        .collection("users")
        .doc(currentUser.id)
        .collection("SMSGroups")
        .doc(smsId)
        .get()
        .then((result) => {
          console.log(result.data());
          if (result.data().withdrawStatus === undefined) {
            setwithdrawalStatus(true);
          }
          if (result.data().withdrawStatus === true) {
            setwithdrawalStatus(false);
          }
          console.log(result.data().lastpaidweek);
          setlastpaidweek(result.data().lastpaidweek);
        })
        .catch((err) => { });
    }
    setname([]);



    firestore
      .collection("sms-groups")
      .doc(smsId)
      .get()
      .then((result) => {
        //console.log(result.data())
        setmembers(result.data().members);
        setrandommembers(shuffleArray(result.data().members));
        setsmsData(result.data());
        var currdate = new Date();
        var olddate = new Date(null);
        olddate.setTime(result.data().createdAt.seconds * 1000);
        var weeks = Math.floor((currdate - olddate) / 604800000);
        //console.log(weeks);
        setcurrentWeek(weeks + 1);
        setstartDate(olddate);
        setamount(result.data().amount);
        console.log(result.data().smspaymentdata);
        setcurrentInstallment(result.data().smspaymentdata[`week${weeks + 1}`]);
        setcurrentWithdrawal(
          result.data().smswithdrawaldata[
          `week${result.data().members.indexOf(currentUser.id) + 1}`
          ]
        );

        console.log(result.data().smswithdrawaldata[
          `week${result.data().members.indexOf(currentUser.id) + 1}`
        ]);
      })
      .catch((err) => { });

    //        console.log(smsData);
    //        console.log(members);

    return () => { };
  }, [currentUser, currentWeek]);
  //  console.log(name);
  // console.log(smsData.createdAt);
  //  console.log(members);

  useEffect(() => {

    return () => {

    }
  }, [members])

  // console.log(treeusers);
  return (
    <>
      {currentUser !== undefined ? (
        <>
          <Card
            style={{ width: "80vw", marginLeft: "10vw" }}
            className="align-item-center mb-5 smalltextSize"
          >

            <row className="smallWidth">
              <div className="wrapper col-lg-6 col-xs-12 mb-5">
                <ul className="mat_list card scrollable">
                  <div className="mat_list_title">
                    <div className="headerText"> {smsId.toUpperCase()}  Members </div>
                  </div>
                  {randommembers.map((user) => {
                    return (
                      <Row
                        key={user}
                        style={{
                          margin: "0 auto",
                          display: "flex",
                          fontSize: "1rem",
                          width: "100%",
                        }}
                      >
                        {count++}.{" "}
                        <Avatar
                          style={{ height: "35px", width: "35px" }}
                          alt="Remy Sharp"
                          src="https://www.yashodahospital.org/wp-content/uploads/2018/09/no_image.png"
                        />{" "}
                        <p
                          className="smalltextSize"

                          style={{
                            width: "70%",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {" "}
                          {user}{" "}
                        </p>{" "}
                      </Row>
                    );
                  })}
                </ul>
              </div>

              <div class="wrapper col-lg-6 col-xs-12 mb-5">
                <ul className="mat_list card scrollable smalltextSize">
                  <p>Created At : {startDate.toLocaleDateString("en-GB")}</p>
                  <p>Current Week : Week {currentWeek}</p>
                  <p>Total Members : {members.length}</p>
                  <p>Total SMS amount : Rs {amount} </p>
                  {/* <p>Total Amount Paid : Rs 4900</p> */}
                  <p>
                    Amount to Pay in Week {currentWeek} : {currentInstallment}
                  </p>
                  <Row
                    className="mt-5 buttonmargin"
                    style={{ justifyContent: "center", display: "flex" }}
                  >
                    {currentWeek - 1 >= members.indexOf(currentUser.id) &&
                      withdrawalStatus ? (
                      <Link>
                        <Button
                          onClick={() => handlewithdraw()}
                          className=""
                          variant="info"
                          size="md"
                          style={{ width: "180px" }}
                        >
                          {" "}
                          <IconButton
                            style={{ color: "white", fontSize: "1rem" }}
                          >
                            <AccountBalanceWalletOutlinedIcon
                              className="mr-3"
                              style={{ color: "white" }}
                            />
                              WithDraw
                            </IconButton>{" "}
                        </Button>
                      </Link>
                    ) : (
                      <></>
                    )}
                    {paid ? (
                      <Link>
                        <Button style={{ width: "180px" }} variant="success" size="md">
                          {" "}
                          <IconButton
                            className="mr-2"
                            style={{ color: "white", fontSize: "1rem" }}
                          >
                            <CheckCircleRoundedIcon
                              className="mr-3"
                              style={{ color: "white" }}
                            />{" "}
                              Paid
                            </IconButton>
                        </Button>
                      </Link>
                    ) : (
                      <Link>
                        <Button
                          onClick={() => handlepay()}
                          style={{ backgroundColor: "#3f51b5", width: "180px" }}
                          size="md"
                        >
                          {" "}
                          <IconButton
                            className="mr-2"
                            style={{ color: "white", fontSize: "1rem" }}
                          >

                            <LockOutlinedIcon
                              className="mr-3"
                              style={{ color: "white" }}
                            />{" "}
                              Pay
                            </IconButton>
                        </Button>
                      </Link>
                    )}
                  </Row>
                </ul>
              </div>

            </row>
          </Card>

          <Dialog
            fullWidth="xl"
            // maxWidth={maxWidth}
            open={shouldShowDialog}
            onClose={handleClose}
            aria-labelledby="max-width-dialog-title"
          >
            <DialogTitle id="max-width-dialog-title">
              Withdrawal Amount : Rs {currentWithdrawal}
            </DialogTitle>
            <DialogContent>
              Add Your guarantor
              <List>
                <TextField
                  id="outlined-basic"
                  label="Search Member by Id"
                  className="w-100"
                  value={searchText}
                  variant="outlined"
                  onChange={(e) => handleChange(e)}
                />
                {searchText.length > 0
                  ? treeuserdata
                    .filter((name) => name.id.includes(searchText))
                    .map((filteredName, index) => (
                      <div>
                        <ListItem key={index} button onClick={() => { }}>
                          <ListItemAvatar>
                            <Avatar
                              alt="Remy Sharp"
                              src={filteredName["photoURL"]}
                            />
                          </ListItemAvatar>
                          <ListItemText>
                            {filteredName["displayName"]}
                          </ListItemText>

                          <ListItemSecondaryAction>
                            <IconButton
                              edge="end"
                              aria-label="add"
                              onClick={() => {
                                sendNotifications(
                                  filteredName["id"],
                                  filteredName["displayName"]
                                );
                              }}
                            >
                              {!requestedList.includes(filteredName["id"]) ? (
                                <SendIcon style={{ color: "3f51b5" }} />
                              ) : (
                                <CheckIcon style={{ color: "3f51b5" }} />
                              )}
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      </div>
                    ))
                  : treeuser.map((e, index) => (
                    <div>
                      <ListItem key={index} button onClick={() => { }}>
                        <ListItemAvatar>
                          <Avatar alt="Remy Sharp" src={e["photoURL"]} />
                        </ListItemAvatar>
                        <ListItemText>{e["displayName"]}</ListItemText>

                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            aria-label="add"
                            onClick={() => {
                              sendNotifications(e["id"], e["displayName"]);
                            }}
                          >
                            {!requestedList.includes(e["id"]) ? (
                              <SendIcon style={{ color: "3f51b5" }} />
                            ) : (
                              <CheckIcon style={{ color: "3f51b5" }} />
                            )}
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    </div>
                  ))}
              </List>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </>
      ) : (
        <div
          style={{
            minHeight: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      )}
    </>
  );
};

//export default MySMSGroupPage;

const mapStateToProps = ({ user, referred }) => ({
  currentUser: user.currentUser,
  referredId: referred.referredId,
});

export default connect(mapStateToProps, null)(MySMSGroupPage);
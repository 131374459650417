import { SET_REFERRED_ID } from "./referred.types";


export const setReferredId = id => { 
    console.log("data receieved");
    console.log(id);
    return ({
    type: SET_REFERRED_ID,
    referredId: id
})
}
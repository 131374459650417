import React, { useState } from "react";
import PropTypes from "prop-types";
import { withStyles, FormControlLabel, Grid, Switch, TextField, GridList, GridListTileBar } from "@material-ui/core";
import { curl, straight, color, haircut, shampoo } from "./iconPaths";
const style = theme => ({
  root: {
    maxWidth: 400,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'start',
    overflow: 'hidden',
  },
  labelRoot: {
    fontSize: 15,
    [theme.breakpoints.down("xs")]: {
      fontSize: 13.5,
    }
  },


  error: {
    color: "red"
  },
  row: { borderBottom: `1px solid ${theme.palette.grey[100]}` },
  icon: {
    fill: theme.palette.grey[700],
    width: 28,
    height: 28
  }
});
const AddressDetails = ({ classes, error, addresslane1, setaddresslane1, addresslane2, setaddresslane2, district, setdistrict, state, setstate, city, setcity, pincode, setpincode }) => {


  const handleaddresslane1 = e => setaddresslane1(e.target.value);
  const handleaddresslane2 = e => setaddresslane2(e.target.value);
  const handlepincode = e => setpincode(e.target.value);
  const handlecity = e => setcity(e.target.value);
  const handledistrict = e => setdistrict(e.target.value);
  const handlestate = e => setstate(e.target.value);



  return (

    <Grid container spacing={3} >

      <Grid container direction="row" alignItems="center" alignContent="center" className="col-sm-6">
        <Grid item xs={12}>
          <TextField
            name="addresslane1"
            value={addresslane1}
            fullWidth
            margin="normal"
            label="Enter Address lane 1 / Street"
            variant="outlined"
            onChange={handleaddresslane1}
            InputLabelProps={{
              classes: {
                root: classes.labelRoot
              }
            }}
          />

        </Grid>
        <Grid item xs={12} className={classes.error}>{addresslane1 ? "" : error.addresslane1} </Grid>

      </Grid>

      <Grid container direction="row" alignItems="center" alignContent="center" className="col-sm-6">

        <Grid item xs={12}>
          <TextField
            name="addresslane2"
            value={addresslane2}
            fullWidth
            margin="normal"
            label="Enter Address lane 2 / Street"
            variant="outlined"
            onChange={handleaddresslane2}
            InputLabelProps={{
              classes: {
                root: classes.labelRoot
              }
            }}
          />

        </Grid>
        <Grid item xs={12} className={classes.error}>{addresslane2 ? "" : error.addresslane2} </Grid>

      </Grid>



      <Grid container direction="row" alignItems="center" alignContent="center" className="col-sm-6">

        <Grid item xs={12}>
          <TextField
            name="pincode"
            value={pincode}
            fullWidth
            margin="normal"
            label="Enter Pincode"
            variant="outlined"
            onChange={handlepincode}
            InputLabelProps={{
              classes: {
                root: classes.labelRoot
              }
            }}
          />


        </Grid>
        <Grid item xs={12} className={classes.error}>{pincode ? "" : error.pincode} </Grid>

      </Grid>






      <Grid container direction="row" alignItems="center" alignContent="center" className="col-sm-6">

        <Grid item xs={12}>
          <TextField
            name="city"
            value={city}
            fullWidth
            margin="normal"
            label="Enter City / Village"
            variant="outlined"
            onChange={handlecity}
            InputLabelProps={{
              classes: {
                root: classes.labelRoot
              }
            }}
          />

        </Grid>
        <Grid item xs={12} className={classes.error}>{city ? "" : error.city} </Grid>

      </Grid>







      <Grid container direction="row" alignItems="center" alignContent="center" className="col-sm-6">

        <Grid item xs={12}>
          <TextField
            name="district"
            value={district}
            fullWidth
            margin="normal"
            label="Enter District"
            variant="outlined"
            onChange={handledistrict}
            InputLabelProps={{
              classes: {
                root: classes.labelRoot
              }
            }}
          />
        </Grid>
        <Grid item xs={12} className={classes.error}>{district ? "" : error.district} </Grid>


      </Grid>











      <Grid container direction="row" alignItems="center" alignContent="center" className="col-sm-6">

        <Grid item xs={12}>
          <TextField
            name="state"
            value={state}
            fullWidth
            margin="normal"
            label="Enter State"
            variant="outlined"
            onChange={handlestate}
            InputLabelProps={{
              classes: {
                root: classes.labelRoot
              }
            }}
          />
        </Grid>
        <Grid item xs={12} className={classes.error}>{state ? "" : error.state} </Grid>

      </Grid>












      {/* <Grid item xs={6}>
    
 <Grid container direction="row" alignItems="center" alignContent="center" className={classes.root}>
<Grid item xs={12}>
  <TextField
    name="addresslane1"
    value={addresslane1}
    fullWidth
    margin="normal"
    label="Enter Address lane 1"
    variant="outlined"
    onChange={handleaddresslane1}
  />

</Grid>
<Grid item xs={12} className={classes.error}>{addresslane1 ? " ": error.addresslane1} </Grid>




<Grid item xs={12}>
  <TextField
    name="pincode"
    value={pincode}
    fullWidth
    margin="normal"
    label="Enter Pincode"
    variant="outlined"
    onChange={handlepincode}
  />

</Grid> 
<Grid item xs={12} className={classes.error}>{pincode ? "": error.pincode} </Grid>



<Grid item xs={12}>
  <TextField
    name="district"
    value={district}
    fullWidth
    margin="normal"
    label="Enter District"
    variant="outlined"
    onChange={handledistrict}
  />

</Grid> 
<Grid item xs={12} className={classes.error}>{district ? "": error.district} </Grid>




</Grid>


    </Grid>    
 
 
    <Grid item xs={6}>
    <Grid container direction="row" alignItems="center" alignContent="center" className={classes.root}>




<Grid item xs={12}>
  <TextField
    name="addresslane2"
    value={addresslane2}
    fullWidth
    margin="normal"
    label="Enter Address lane 2"
    variant="outlined"
    onChange={handleaddresslane2}
  />

</Grid> 
<Grid item xs={12} className={classes.error}>{addresslane2 ? " ": error.addresslane2} </Grid>




<Grid item xs={12}>
  <TextField
    name="city"
    value={city}
    fullWidth
    margin="normal"
    label="Enter City"
    variant="outlined"
    onChange={handlecity}
  />

</Grid> 
<Grid item xs={12} className={classes.error}>{city ? "": error.city} </Grid>




<Grid item xs={12}>
  <TextField
    name="state"
    value={state}
    fullWidth
    margin="normal"
    label="Enter State"
    variant="outlined"
    onChange={handlestate}
  />

</Grid> 
<Grid item xs={12} className={classes.error}>{state ? "": error.state} </Grid>




</Grid>

   </Grid>  */}
    </Grid>

  );
};

export default withStyles(style)(AddressDetails);

import { SET_CURRENT_USER } from "./user.types";

export const setCurrentUser = user => {
    console.log("REceived")
    
    return ({
        type: SET_CURRENT_USER,
        payload:user
    });
}



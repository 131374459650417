import { useState, useEffect } from "react";
import axios from "axios";
import logo from "../../logo.svg";
import "./homepage.styles.scss";
import Content from "../../components/kyc/kyc";
import { connect } from "react-redux";
import { UnregisteredHomepage } from "../../components/unregistered-homepage/unregistered-homepage.component";
import { Modal } from "react-bootstrap";
import { Button, InputGroup, FormControl, Card, Form, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import firebase, { firestore } from "../../firebase/firebase.utils";
import { CircularProgress } from "@material-ui/core";
import { useSelector } from "react-redux";
import Carousel from "./homepageitems";
import MembershipData from './membership';

const HomePage = ({ match, currentUser }) => {

  // console.log(match);
  // console.log(currentUser);
  const [referraldatafromURL, setreferraldatafromURL] = useState("");
  const state = useSelector((state) => state.referred.referredID);


  console.log(state);
  const [memberId, setmemberId] = useState("")
  const [membermsg, setmembermsg] = useState("")
  const [fillform, setfillform] = useState(false)
  const [show, setShow] = useState(false);
  const [successshow, setsuccessShow] = useState(false);
  const [referredIdName, setreferredIdName] = useState("");

  const [count, setCount] = useState(0);
  const [msg, setmsg] = useState("");
  const [referralIdValue, setreferralIdValue] = useState(state);
  const [membervalue, setmembervalue] = useState(false);
  const [disable, setdisable] = useState(false);
  const [paymentShow, setpaymentShow] = useState(false);
  const [transactionId, settransactionId] = useState("")

  const handleClose = () => {
    setCount(1);
    setShow(false);
  };



  const [fullname, setfullname] = useState("");
  const [fathersname, setfathersname] = useState("");
  const [age, setage] = useState(18);
  const [dob, setdob] = useState("");
  const [address, setaddress] = useState("");
  const [phoneno, setphoneno] = useState("")

  const submitPayment = () => {
    firestore.collection("membershipformRequests").doc(currentUser.id).set({
      fullname,
      fathersname,
      age,
      dob,
      address,
      phoneno,
      id: currentUser.id,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      transactionId
    }, { merge: true })
    alert("submitted");
    setpaymentShow(false);
  }

  const membershipsubmit = () => {

    setfillform(false);
    setpaymentShow(true);
    //handleMembershipPay(currentUser.id);
  }


  const handleformClose = () => {
    setfillform(false);
  }
  const handleAddmember = async () => {
    if (memberId.substring(0, 13) === 'KMC_MEM_00000' && memberId.substring(13,) <= 1485) {
      await firestore.collection("users").doc(currentUser.id).set({
        membershipID: memberId,
        membershipStatus: true
      }, { merge: true })
      alert("Your Member Id is : " + memberId);
    }
    else {
      setmembermsg("Invalid Member Id")
    }
  }


  const handlesuccessClose = () => {
    setsuccessShow(false);
  }
  const handleShow = () => setShow(true);
  const handlereferralId = (e) => setreferralIdValue(e.target.value);
  const slides = [
    {
      id: 1,
      title: "My Tree",
      subtitle: "",
      description: "",
      image: "C:/Users/vanim/Desktop/kmc-sms-mlm/src/assets/tree.jpeg",
      path: "mytree",
    },
    {
      id: 2,
      title: "My SMS GROUPS",
      subtitle: "",
      description: "",
      image:
        "https://www.slideteam.net/media/catalog/product/cache/960x720/t/e/team_of_business_people_with_problem_puzzles_stock_photo_Slide01.jpg",
      path: "SMSGroups",
    },
    {
      id: 3,
      title: "My PROFILE",
      subtitle: "",
      description: "",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQXyBwDf_g53s1mbWnPAQSRExEfpheE9JNZ0omFRc31pVN44507Vt2MzCFtLbabNOzwvb8&usqp=CAU",
      path: "profile",
    },
    {
      id: 4,
      title: "My CHATS",
      subtitle: "",
      description: "",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQXyBwDf_g53s1mbWnPAQSRExEfpheE9JNZ0omFRc31pVN44507Vt2MzCFtLbabNOzwvb8&usqp=CAU",
      path: "chats",
    },
    {
      id: 5,
      title: "My RANK",
      subtitle: "",
      description: "",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQXyBwDf_g53s1mbWnPAQSRExEfpheE9JNZ0omFRc31pVN44507Vt2MzCFtLbabNOzwvb8&usqp=CAU",
      path: "ranks",
    },
  ];

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  const handleMembershipPay = async (userId) => {
    setdisable(true);
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    // creating a new order
    const result = await axios.post(
      "https://us-central1-react-test-5e32f.cloudfunctions.net/getOrderId",
      {
        type: `initialPay`,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );

    if (!result) {
      alert("Server error. Are you online?");
      return;
    }

    // Getting the order details back
    const { amount, id: order_id, currency } = result.data;

    firestore.collection("payments").doc(order_id).set({
      uid: userId,
      paymentType: "INITIALPAY",
      paymentTime: firebase.firestore.FieldValue.serverTimestamp(),
    });

    const options = {
      key: "rzp_test_7xxFrCJgl4GYWB", // Enter the Key ID generated from the Dashboard
      amount: amount.toString(),
      currency: currency,
      name: "KMC",
      description: "Test Transaction",
      image: { logo },
      order_id: order_id,
      handler: async function (response) {
        const data = {
          orderCreationId: order_id,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
        };

        const result = await axios.post(
          "https://us-central1-react-test-5e32f.cloudfunctions.net/paymentSuccessHandler",
          data,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "Access-Control-Allow-Origin": "*",
            },
          }
        );

        //        alert(result.data.msg);
      },
      prefill: {
        name: "KMC",
        email: "kmcsociety.app@gmail.com",
        contact: "9652961216",
      },
      notes: {
        address: "KALPAVRUKSHA COOPERATIVE SOCIETY LTD",
      },
      theme: {
        color: "#61dafb",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  const handleAdd = async () => {
    console.log(referralIdValue);
    console.log(currentUser.id);
    const reff = await referralIdValue;
    console.log(reff);
    const batch = firestore.batch();

    if (referralIdValue) {
      const referralIdRef = firestore
        .collection("agents")
        .doc(referralIdValue.toString())
        .collection("referredId")
        .doc(currentUser.id);

      const userRef = firestore.collection("users").doc(currentUser.id);

      firestore.collection("agents").doc(referralIdValue).get().then(async (snapshot) => {
        if (snapshot.exists) {

          setreferredIdName(snapshot.data().displayName);


          batch.set(referralIdRef, {
            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            id: currentUser.id,
          });

          batch.update(userRef, {
            referredFrom: referralIdValue,
          });


          await batch.commit();

          handleClose();
          setsuccessShow(true);

        }
        else {
          setmsg("Invalid Referral Id")
        }
      })

    } else {
      console.log(referralIdValue);
    }

  };

  useEffect(() => {
    if (currentUser) {
      firestore
        .collection("users")
        .doc(currentUser.id)
        .onSnapshot((snapshot) => {
          console.log(snapshot.data());
          if (snapshot.data().membershipStatus === true) {
            setmembervalue(true);
          }
        });
    }
    return () => { };
  }, [currentUser, disable]);

  useEffect(() => {
    console.log(show);

    if (currentUser && count === 0) {
      return (
        firestore
          .collection("users")
          .doc(currentUser.id)
          .get()
          .then(function (doc) {
            if (doc.exists) {
              console.log(doc.data());
              if (doc.data().referredFrom === null) {
                console.log("hi");
                console.log(state);
                console.log(count);
                if (state !== null) {
                  setreferralIdValue(state);
                  handleAdd();
                } else if (state === null) {
                  setShow(true);
                }

                // if(state === null ){
                //   setShow(true);
                // }
                //setShow(true);
              }
              //setcheckKYC(doc.data().kycVerified);
            } else {
              // doc.data() will be undefined in this case
              console.log("No such document!");
            }
          })
          .catch(function (error) {
            console.log("Error getting document:", error);
          }),
        [state]
      );
    }
  });

  //  console.log(referralIdValue);
  return (
    <div className="homepage">
      {currentUser === undefined ? (
        <div
          style={{
            minHeight: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      ) : currentUser ? (
        <div>
          {/*       
<Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button> */}

          {/* <Button variant="primary" style={{ fontSize: "12px", }}>
            <p> {`Membership Id: ${currentUser.membershipID || ""}`} <br></br>
              {`Agents Id: ${currentUser.agentID || ""}`}</p>
          </Button> */}

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Referral Id</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text>Enter your Referral Id</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  value={referralIdValue}
                  onChange={handlereferralId}
                />
              </InputGroup>
              <p style={{ color: "red", textAlign: "center" }}> {msg} </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handleAdd}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>


          <Modal show={fillform} onHide={handleformClose} size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
              <Modal.Title> Membership Form </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <InputGroup className="mb-2">
                <InputGroup.Prepend>
                  <InputGroup.Text>Full Name</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl onChange={(e) => setfullname(e.target.value)} />
              </InputGroup>

              <InputGroup className="mb-2">
                <InputGroup.Prepend>
                  <InputGroup.Text>Father's /Husband Name</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl onChange={(e) => setfathersname(e.target.value)} />
              </InputGroup>



              <InputGroup className="mb-2">
                <InputGroup.Prepend>
                  <InputGroup.Text>Age</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl type="number" onChange={(e) => setage(e.target.value)} />
              </InputGroup>


              <InputGroup className="mb-2">
                <InputGroup.Prepend>
                  <InputGroup.Text>Dob</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl type="date" placeholder="DD/MM/YYYY" onChange={(e) => setdob(e.target.value)} />
              </InputGroup>

              <InputGroup className="mb-2">
                <InputGroup.Prepend>
                  <InputGroup.Text>Address</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl onChange={(e) => setaddress(e.target.value)} />
              </InputGroup>

              <InputGroup className="mb-2">
                <InputGroup.Prepend>
                  <InputGroup.Text>Phone No</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl onChange={(e) => setphoneno(e.target.value)} />
              </InputGroup>



            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() => membershipsubmit()}>Submit</Button>

              <Button variant="secondary" onClick={handleformClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>


          <Modal show={successshow} onHide={handlesuccessClose}>
            <Modal.Header closeButton>
              <Modal.Title>Referral Id</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p style={{ color: "green", textAlign: "center" }}> You have been referred by {referredIdName}. </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handlesuccessClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>


          <Modal
            show={paymentShow}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Pay Rs 120
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                Account Holder: KALPAVRUKSHA MUTUALLY AIDED MULTIPURPOSE
                <br></br>Account Number: 50200057225510

                <br></br> IFSC: HDFC0004330
                <br></br> Branch: SHADNAGAR FAROOQNAGAR
              </p>
              <p>*After Payment Add Transaction Id and Submit.</p>

              <Form.Group>
                <Row style={{ textAlign: "center" }}>
                  <Form.Control className="ml-5 w-50" value={transactionId} onChange={(e) => settransactionId(e.target.value)} type="text" placeholder="Enter Transaction Id" />
                  <Button className="ml-5" onClick={submitPayment}>Submit</Button>
                </Row>
              </Form.Group>

            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() => setpaymentShow(false)}>Close</Button>
            </Modal.Footer>
          </Modal>

          {/* <h1>HOMEPAGE</h1> */}
          <div style={{ display: `${currentUser.membershipID ? "none" : "block"}` }}>
            <Card style={{ minHeight: "80vh" }}>
              <Card.Title>
                {" "}
                <h1 className="headertext" style={{ textAlign: "center", color: "rgb(63, 81, 181)" }}>
                  Join KALPAVRUKSHA COOPERATIVE SOCIETY LTD.
                </h1>
              </Card.Title>
              <Card.Body
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  margin: "0 auto",
                }}
              >
                <Card.Text className="centeritem">1. Pay Membership Fee Rs.20/- </Card.Text>

                <Card.Text className="centeritem">2. Pay share amount Rs.100/-</Card.Text>
                {/* 
                <Card.Text>
                  3. Pay an entrance fee of Rs 200/- to join SMS Groups.
                </Card.Text> */}
                {/* <div style={{ margin: "0 auto" }}>
                  <Button onClick={() => setfillform(true)}>
                    Fill Form
                </Button>
                </div> */}

                <div style={{ margin: "0 auto" }}>
                  <Button
                    disabled={disable}
                    className="mb-2 mt-1"
                    variant="primary"
                    onClick={() => setfillform(true)}
                  >
                    Pay Rs 120
                  </Button>


                </div>

                <Card.Text style={{ textAlign: "center", margin: "0 auto", display: "block" }}> Or </Card.Text>
                <Card.Text className="centeritem"> Already a member !</Card.Text>
                <Card.Text className="centeritem">
                  <InputGroup className="mb-1 mt-1">
                    <InputGroup.Prepend>
                      <InputGroup.Text id="basic-addon1">Member Id :</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      value={memberId}
                      placeholder="Enter Member Id"
                      aria-label="memberId"
                      aria-describedby="memberId"
                      onChange={(e) => setmemberId(e.target.value)}
                    />
                  </InputGroup>

                </Card.Text>
                <p style={{ textAlign: "center", color: "red" }}>{membermsg}</p>

                <Card.Text className="centeritem">

                  <Button
                    className=""
                    variant="primary"
                    onClick={handleAddmember}
                  >
                    Submit
                  </Button>
                </Card.Text>

              </Card.Body>

            </Card>
          </div>

          <Carousel slides={slides} isPageBackground />
          {/* <div className="Kyc">
            <div className="wizard">
              <Content  currentUser={currentUser}/>
            </div>
          </div>  */}
        </div>
      ) : (
        <UnregisteredHomepage currentUser={currentUser} match={match} />
      )}
    </div>
  );
};
const mapStateToProps = ({ user, referred }) => ({
  currentUser: user.currentUser,
  referredId: referred.referredId,
});

export default connect(mapStateToProps, null)(HomePage);

import React, { useState, useEffect } from "react";
import './smsgroups.css';
import { connect } from "react-redux";
import { useSelector } from "react-redux";
import firebase, { firestore } from "../../firebase/firebase.utils";
import { Button, Card, Col, Row, Modal, Form, InputGroup, FormControl } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import Checkbox from '@material-ui/core/Checkbox';
const MySMSGroup = () => {

  const [smsGrouplength, setsmsGrouplength] = useState(0);
  const [smsGroup, setsmsGroup] = useState([]);
  const [msg, setmsg] = useState("");
  const [disable, setdisable] = useState(false);
  const [queue, setqueue] = useState(0);
  const [modalShow, setModalShow] = React.useState(false);
  const [checked, setchecked] = useState(false)
  const [paymentShow, setpaymentShow] = React.useState(false);
  const [transactionId, settransactionId] = useState("")

  const [fillform, setfillform] = useState(false);
  const [agentfillform, setagentfillform] = useState(false);


  const [kycverified, setkycverified] = useState(false);
  const user = useSelector((state) => {
    if (state.user.currentUser === undefined) {
      return console.log("noooooooooooooooo");
    } else {
      return state.user.currentUser;
    }
  });

  const [checkpayment, setcheckpayment] = useState(false);

  const [fullname, setfullname] = useState("");
  const [fathersname, setfathersname] = useState("");
  const [age, setage] = useState(18);
  const [dob, setdob] = useState("");
  const [address, setaddress] = useState("");
  const [phoneno, setphoneno] = useState("")

  const [serviceamount, setserviceamount] = useState(200);

  const [week1amount, setweek1amount] = useState(1000);
  const [week2amount, setweek2amount] = useState(1000);
  const [agenttype, setagenttype] = useState("agent");


  const agentsubmit = () => {
    // firestore.collection("agentformRequests").doc(user.id).set({
    //   fullname,
    //   fathersname,
    //   age,
    //   dob,
    //   address,
    //   dob,
    //   id: user.id
    // }, { merge: true })
    // alert("submitted");

    setagentfillform(false);
    // setModalShow(true);
    setModalShow(true);

  }


  const membershipsubmit = () => {
    // firestore.collection("smsformRequests").doc(user.id).set({
    //   fullname,
    //   fathersname,
    //   age,
    //   dob,
    //   address,
    //   dob,
    //   id: user.id
    // }, { merge: true })
    // alert("submitted");
    setfillform(false);
    setagentfillform(true);
  }


  useEffect(async () => {
    //  console.log("sfssf   :     " + user);

    if (user !== undefined) {
      firestore
        .collection("users")
        .doc(user.id)
        .get()
        .then((result) => {
          console.log(result.data());
          setkycverified(result.data().kycVerified);

          if (result.data().crownagentID) {
            setserviceamount(600);
            setweek1amount(3000);
            setweek2amount(3000);
            setagenttype("crownagent");
          }
          else if (result.data().staragentID) {
            setserviceamount(400);
            setweek1amount(2000);
            setweek2amount(2000);
            setagenttype("staragent")
          }
        })
        .catch((err) => { });


      firestore.collection("users").doc(user.id).collection("document").doc("membershipdocument").get().then((result) => {

        if (result.data()) {
          setfullname(result.data().fullname || "");
          setfathersname(result.data().fathersname || "");
          setage(result.data().age || 18);
          setdob(result.data().dob || "");
          setaddress(result.data().address || "");
          setphoneno(result.data().phoneno || "");


        }

      })

      firestore
        .collection("users")
        .doc(user.id)
        .collection("SMSGroups")
        .onSnapshot((snapshot) => {
          setsmsGroup([]);
          snapshot.docs.forEach((element) => {
            setsmsGroup((result) => [
              ...result,
              {
                groupname: element.data().groupName,
                joinedDate: element.data().joinedDate,
              },
            ]);
            //   console.log(element.data());
          });
        });

      console.log(user.id);
      const status = await firestore.collection("sms-groups")
        .where("members", "array-contains", user.id)
        .where("status", "==", "running")
        .get();

      console.log(status.docs.length);
      if (status.docs.length > 0) {
        setmsg("You have been Joined in SMS Group");
        setdisable(true);
      }

      // setsmsGrouplength(status.docs.length);


      const smsReq = firestore.collection("SMSAdminGroupRequest");
      smsReq
        .doc(user.id)
        .onSnapshot((snapshot) => {
          //       console.log(snapshot.size);
          if (snapshot.exists) {
            setmsg("Your Request have been submitted and will be approved by Admin");
            setdisable(true);
          }
        });



      const smsReq1 = firestore.collection("SMSGroupRequest");
      smsReq1
        .doc(user.id)
        .onSnapshot((snapshot) => {
          //       console.log(snapshot.size);
          if (snapshot.exists) {
            setmsg("Your Request have been approved and your sms group will start on next monday.");
            setdisable(true);
          }
        });

      firestore.collection("SMSGroupRequest").onSnapshot((snapshot) => {
        console.log(snapshot.size);
        setqueue(50 - snapshot.size);
      });
    }

    return () => { };
  }, [user, disable]);

  console.log(user);

  const handlePayment = () => {
    console.log("sfhsjfkn");
    setModalShow(false);
    setpaymentShow(true);
  }

  const submitPayment = () => {

    const userRef = firestore.collection("SMSAdminGroupRequest").doc(user.id);
    userRef.set({
      id: user.id,
      transactionId: transactionId,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      paidAmount: checked ? week1amount + week2amount + serviceamount : week1amount + serviceamount,
      agenttype,
      fullname,
      fathersname,
      age,
      dob,
      address,
      phoneno,

    }, { merge: true });

    firestore.collection("agentformRequests").doc(user.id).set({
      fullname,
      fathersname,
      age,
      dob,
      agenttype,
      address,
      phoneno,
      id: user.id,
      paidAmount: checked ? week1amount + week2amount + serviceamount : week1amount + serviceamount,
      transactionId: transactionId,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    }, { merge: true })


    settransactionId("");
    setpaymentShow(false);

  }

  const handlechecked = () => {
    setchecked(!checked);
  }


  const requestSMS = () => {
    const userRef = firestore.collection("SMSGroupRequest").doc(user.id);
    userRef.set({
      id: user.id,
      name: user.displayName,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
    setdisable(true);
  };
  const withdrawSMS = async () => {
    const userRef = firestore.collection("SMSGroupRequest").doc(user.id);
    await userRef.delete();
    setdisable(false);
    setmsg("");
  };

  //   const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  // const firstDate = new Date(2008, 1, 12);
  // const secondDate = new Date(2008, 1, 22);

  // const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
  // console.log((firstDate - secondDate) / oneDay);

  return (
    <>
      {" "}
      {user !== undefined ? (
        kycverified ? (
          <>
            <Card
              style={{
                width: "80vw", marginLeft: "10vw", display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              className="align-item-center"
            >

              {smsGroup.length ? (
                <>
                  <Card.Title className="headerText"> My Current SMS Groups </Card.Title>

                  <Card.Text
                    style={{
                      alignItems: "center",
                    }}
                  >


                    <Row>
                      {smsGroup.map((sms) => {
                        var url = "/SMSGroupsPage/" + sms.groupname;
                        const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
                        const firstDate = new Date(
                          sms.joinedDate.seconds * 1000
                        );
                        const secondDate = new Date();

                        const diffDays = Math.round(
                          Math.abs((firstDate - secondDate) / oneDay)
                        );
                        console.log((firstDate - secondDate) / oneDay);
                        const daydiff = Math.ceil(
                          (firstDate - secondDate) / oneDay
                        );
                        return (
                          <Card.Text>
                            {daydiff > 0 ? (
                              <Button
                                disabled
                                variant="warning"
                                size="lg"
                                className="m-3 "
                              >
                                {" "}
                                {sms.groupname} <br></br> Starting in{" "}
                                {daydiff} days{" "}
                              </Button>
                            ) : (
                              <Link to={url}>
                                <Button
                                  variant="secondary"
                                  size="lg"
                                  className="m-3 smalltextSize"
                                >
                                  {" "}
                                  {sms.groupname.toUpperCase()} <br></br> Started{" "}
                                  {Math.abs(daydiff)} days ago
                                </Button>
                              </Link>
                            )}
                          </Card.Text>
                        );
                      })}
                      {/* <Card.Text>
            <Button variant="secondary" size="lg" className="m-3" > SMS 2 <br></br> Current Week : Week 4</Button>
        </Card.Text>  
        <Card.Text>
            <Button variant="secondary" size="lg" className="m-3" > SMS 3 <br></br> Current Week : Week 1</Button>
        </Card.Text>     */}
                    </Row>
                    <Card.Text
                      className="smalltextSize"
                      style={{
                        alignItems: "center",
                      }}
                    >
                      {msg}
                    </Card.Text>


                  </Card.Text>
                </>
              ) : (
                <Card.Text
                  className="smalltextSize"
                  style={{
                    alignItems: "center",
                  }}
                >
                  You have not Joined any Group !
                </Card.Text>
              )}



              <Col style={{ display: disable ? "none" : "block" }}>



                <Row style={{ margin: "0 auto", display: "block" }}>

                  {/* {disable ? (
                  <Button className="smalltextSize" variant="danger" onClick={() => withdrawSMS()}>
                    Withdraw
                  </Button>
                ) : (
                  <>
                    <Button
                      variant="info"
                      onClick={() => requestSMS()}
                      disabled={disable}
                    >
                      JOIN SMS Group
                      </Button>
                  </>
                )} */}

                  <div>
                    <div style={{
                      height: "1px",
                      backgroundColor: "black",
                      width: "100%",
                      float: "left",
                      marginBottom: "40px"
                    }}>

                    </div>
                    <Button
                      style={{ margin: "0 auto", display: "block" }}
                      variant="info"
                      onClick={() => setfillform(true)}
                      // onClick={() => requestSMS()}
                      disabled={disable}
                    >
                      JOIN & PAY
                    </Button>

                  </div>

                </Row>

                <Card.Text
                  className="smalltextSize"
                  style={{
                    alignItems: "center",
                  }}
                >
                  {disable ? "" : `${queue} members more to join to start the SMS Plan.`}
                </Card.Text>
              </Col>
            </Card>



            <Modal show={agentfillform} onHide={() => setagentfillform(false)} size="md"
              aria-labelledby="contained-modal-title-vcenter"
              centered>
              <Modal.Header closeButton>
                <Modal.Title> Agent Form </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <InputGroup className="mb-2">
                  <InputGroup.Prepend>
                    <InputGroup.Text>Full Name</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl value={fullname} onChange={(e) => setfullname(e.target.value)} />
                </InputGroup>

                <InputGroup className="mb-2">
                  <InputGroup.Prepend>
                    <InputGroup.Text>Father's /Husband Name</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl value={fathersname} onChange={(e) => setfathersname(e.target.value)} />
                </InputGroup>



                <InputGroup className="mb-2">
                  <InputGroup.Prepend>
                    <InputGroup.Text>Age</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl value={age} type="number" onChange={(e) => setage(e.target.value)} />
                </InputGroup>


                <InputGroup className="mb-2">
                  <InputGroup.Prepend>
                    <InputGroup.Text>Dob</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl value={dob} type="date" placeholder="DD/MM/YYYY" onChange={(e) => setdob(e.target.value)} />
                </InputGroup>

                <InputGroup className="mb-2">
                  <InputGroup.Prepend>
                    <InputGroup.Text>Address</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl value={address} onChange={(e) => setaddress(e.target.value)} />
                </InputGroup>

                <InputGroup className="mb-2">
                  <InputGroup.Prepend>
                    <InputGroup.Text>Phone No</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl value={phoneno} onChange={(e) => setphoneno(e.target.value)} />
                </InputGroup>



              </Modal.Body>
              <Modal.Footer>
                <Button onClick={() => agentsubmit()}>Next</Button>

                <Button variant="secondary" onClick={() => setagentfillform(false)}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>




            <Modal show={fillform} onHide={() => setfillform(false)} size="md"
              aria-labelledby="contained-modal-title-vcenter"
              centered>
              <Modal.Header closeButton>
                <Modal.Title> SMS-e-Plan Form </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <InputGroup className="mb-2">
                  <InputGroup.Prepend>
                    <InputGroup.Text>Full Name</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl value={fullname} onChange={(e) => setfullname(e.target.value)} />
                </InputGroup>

                <InputGroup className="mb-2">
                  <InputGroup.Prepend>
                    <InputGroup.Text>Father's /Husband Name</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl value={fathersname} onChange={(e) => setfathersname(e.target.value)} />
                </InputGroup>



                <InputGroup className="mb-2">
                  <InputGroup.Prepend>
                    <InputGroup.Text>Age</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl value={age} type="number" onChange={(e) => setage(e.target.value)} />
                </InputGroup>


                <InputGroup className="mb-2">
                  <InputGroup.Prepend>
                    <InputGroup.Text>Dob</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl value={dob} type="date" placeholder="DD/MM/YYYY" onChange={(e) => setdob(e.target.value)} />
                </InputGroup>

                <InputGroup className="mb-2">
                  <InputGroup.Prepend>
                    <InputGroup.Text>Address</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl value={address} onChange={(e) => setaddress(e.target.value)} />
                </InputGroup>

                <InputGroup className="mb-2">
                  <InputGroup.Prepend>
                    <InputGroup.Text>Phone No</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl value={phoneno} onChange={(e) => setphoneno(e.target.value)} />
                </InputGroup>



              </Modal.Body>
              <Modal.Footer>
                <Button onClick={() => membershipsubmit()}>Next</Button>

                <Button variant="secondary" onClick={() => setfillform(false)}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal
              show={modalShow}
              size="md"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  Join SMS-e-Group
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group controlId="joincharge">
                  <Form.Check checked={true} type="checkbox" label={`Rs ${serviceamount} :  SMS-e-Group Joining Charge`} />
                </Form.Group>
                <Form.Group controlId="1stinstallment">
                  <Form.Check checked={true} type="checkbox" label={`Rs ${week1amount} : 1st Week Installment`} />
                </Form.Group>
                <Form.Group controlId="2ndinstallment">
                  <Form.Check onChange={handlechecked} checked={checked} type="checkbox" label={`Rs ${week2amount} : 2nd Week Installment `} />
                </Form.Group>
                {/* <p>*Agent Id will be alloted after payment of 2nd installment only</p> */}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="success" onClick={handlePayment}>Pay {checked ? `Rs ${serviceamount + week1amount + week2amount}` : `Rs ${serviceamount + week1amount}`}</Button>
                <Button onClick={() => setModalShow(false)}>Close</Button>
              </Modal.Footer>
            </Modal>



            <Modal
              show={paymentShow}
              size="md"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  Pay {checked ? `Rs ${serviceamount + week1amount + week2amount}` : `Rs ${serviceamount + week1amount}`}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>
                  Account Holder: KALPAVRUKSHA MUTUALLY AIDED MULTIPURPOSE
                  <br></br>Account Number: 50200057225510

                  <br></br> IFSC: HDFC0004330
                  <br></br> Branch: SHADNAGAR FAROOQNAGAR
                </p>
                <p>*After Payment Add Transaction Id and Submit.</p>

                <Form.Group>
                  <Row style={{ textAlign: "center" }}>
                    <Form.Control className="ml-5 w-50" value={transactionId} onChange={(e) => settransactionId(e.target.value)} type="text" placeholder="Enter Transaction Id" />
                    <Button className="ml-5" onClick={submitPayment}>Submit</Button>
                  </Row>
                </Form.Group>

              </Modal.Body>
              <Modal.Footer>
                <Button onClick={() => setpaymentShow(false)}>Close</Button>
              </Modal.Footer>
            </Modal>

          </>
        ) : (
          <div
            style={{
              minHeight: "80vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {" "}
            <Card style={{ width: "70vw" }}>
              <Card.Body>
                <Card.Title
                  style={{ color: "rgb(63, 81, 181)", fontSize: "2rem" }}
                >
                  <Link to="/kyc" className="smalltext" style={{ textDecoration: "none" }}>   Update Your KYC{" "}</Link>
                </Card.Title>
              </Card.Body>
            </Card>{" "}
          </div>
        )
      ) : (
        <div
          style={{
            minHeight: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      )}
    </>
  );
};

export default MySMSGroup;

import React, { useState, useEffect } from "react";
import SwipeableViews from "react-swipeable-views";
import { Paper, Grid, Typography, withStyles, Button } from "@material-ui/core";
import WizardHeader from "./wizardHeader";
import PanDetails from "./panDetails";
import AddressDetails from "./addressDetails";
import AadharDetails from "./aadharDetails";
import PersonalDetails from "./personalDetails";
import BankDetails from "./bankDetails";
import AadharVerification from "./aadharVerification";
import { connect } from "react-redux";
import { firestore, storage } from "../../firebase/firebase.utils";
// import { useHistory } from "react-router-dom";
import './kyc.css';

const style = (theme) => ({
  root: {
    border: `8px solid ${theme.palette.common.white}`,
    margin: 16,
    padding: "36px 0 0",
    background: `rgba(255,255,255,0.9)`,
    boxShadow: [
      `0px 16px 26px -10px ${theme.palette.primary.main}99`,
      theme.shadows[15],
    ],
  },
  navigation: {
    width: 110,
    fontSize: 12,
    [theme.breakpoints.down("xs")]: {
      fontSize: 10,
      width: 90,
    },
  },
  navigation: {
    width: 150,
    fontSize: 12,
    [theme.breakpoints.down("xs")]: {
      fontSize: 10,
      width: 110,
    },
  },
  prevBtn: {
    color: theme.palette.grey[700],
    background: theme.palette.common.white,
    boxShadow: theme.shadows[5],
  },
});
const Content = ({ classes, props, currentUser, currentindex }) => {
  console.log(props);
  console.log(currentUser);




  console.log(currentindex);
  // const history = useHistory();
  const [submit, setsubmit] = useState(false);
  const [btnsubmit, setbtnsubmit] = useState(false);
  // Aadhar Details

  const [aadharNo, setaadharNo] = useState("");
  const [selectedaadharFile, setSelectedaadharFile] = useState();
  const [clientId, setclientId] = useState("");
  const [aadharotp,setaadharotp] = useState("")
  //Address Details

  const [addresslane1, setaddresslane1] = useState("");
  const [addresslane2, setaddresslane2] = useState("");
  const [district, setdistrict] = useState("");
  const [state, setstate] = useState("");
  const [city, setcity] = useState("");
  const [pincode, setpincode] = useState("");

  //personal Details
  const [name, setName] = useState("");
  const [phoneNo, setphoneNo] = useState("")
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth()).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear() - 18;

  //  var today = `${dd}/${mm}/${yyyy}`;

  const [selectedDate, handleDateChange] = useState(new Date(yyyy, mm, dd));
  console.log(selectedDate);

  const [gender, setGender] = useState("Male");

  //pan Details
  const [panNo, setpanNo] = useState("");
  const [selectedpanFile, setSelectedpanFile] = useState();

  //Bank Details
  const [nameOnAccount, setnameOnAccount] = useState("");
  const [accountNo, setaccountNo] = useState("");
  const [accountType, setaccountType] = useState("");
  const [bankName, setbankName] = useState("");
  const [ifscCode, setifscCode] = useState("");
  const [bankVerifiedStatus, setbankVerifiedStatus] = useState(false);

  const [checkKYC, setcheckKYC] = useState(false);
  const [documenterror, setdocumenterror] = useState({});
  const [activeStep, setActiveStep] = useState(currentindex);
  const handleChange = (index) => (e) => setActiveStep(index);
  const handlePrev = () => setActiveStep(activeStep - 1);
  const tabs = [
    "Personal Details",
    "Aadhar Details",
    "Aadhar Verify",
    "PAN Details", 
    "Bank Details",
    "Address Details",
  ];
  const [error, setError] = useState({});

  const checkDocument = async (type,documentNo) => {
  return  await firestore.collectionGroup("details").where(type, "==", documentNo).get().then((value) => {
        console.log(value.docs.length);
        return value.docs.length;
    })
  }

  const verifyAadhar = async () => {
 //  alert(name + phoneNo + aadharNo);
  //  var data = https://us-central1-react-test-5e32f.cloudfunctions.net/aadharVerify
    // fetch('https://us-central1-react-test-5e32f.cloudfunctions.net/aadharVerify')
    // .then(response => response.json())
    // .then(data => console.log(data));
    checkDocument("aadharNo",aadharNo).then(async (result) => {
      console.log(result);
      if(result === 0){
        try {
          
          
          const requestOptions = {
            method: 'POST',
          //  mode: 'no-cors',
            headers: { 'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Origin':'*' 
            },
            body: JSON.stringify({ aadharNo: aadharNo,
            type:"AADHAR"
            })
         };
        let res = await fetch('https://us-central1-react-test-5e32f.cloudfunctions.net/aadharVerify', requestOptions);
        let doc = await res.json().catch(e=>{});
        
        console.log(doc);
        //console.log("gdnkdmd");
        
        if(doc.success === true){
          //console.log(doc.data["client_id"]);
          setclientId(doc.data["client_id"])
        // console.log(phoneNo);
        //   console.log(clientId);
         
          setActiveStep(activeStep + 1);
        }
        else{
          setError((prevState) => ({
            ...prevState,
            aadharVerify: doc.message,
          }));
         
        }
        
        
        } catch (error) {
        console.log(error);  
        }
            }
            else{
              setError((prevState) => ({
                ...prevState,
                aadharVerify: "Aadhar No is Already Used",
              }));
            }

    }
    )
  
    
  }


  const verifyAadharOTP = async () => {
    
  
   try {   
     const requestOptions = {
       method: 'POST',
     //  mode: 'no-cors',
       headers: { 'Content-Type': 'application/json',
       'Accept': 'application/json',
       'Access-Control-Allow-Origin':'*' 
       },
       body: JSON.stringify({ 
         clientId:clientId,
         otp:aadharotp,
         phoneNo:phoneNo,
         type:"AADHAR"
      })
    };
   let res = await fetch('https://us-central1-react-test-5e32f.cloudfunctions.net/aadharVerifyOTP', requestOptions);
   let docu = await res.json().catch(e=>{});
   
   console.log(docu);
   //console.log("gdnkdmd");
   
   if(docu.success === true){
     var x = docu.data.dob.split('-');
     var d1 = new Date(x[0],x[1],x[2]);
     var ageDifMs = Date.now() - d1;
     var ageDate = new Date(ageDifMs); 
     if(Math.abs(ageDate.getUTCFullYear() - 1970) >= 18){

      await firestore.collection("users").doc(currentUser.id).collection("verified").doc("aadharVerifiedDetails").set({
        document : docu
      })
      setActiveStep(activeStep + 1);
     

     }
     else{

      setError((prevState) => ({
        ...prevState,
        aadharOTP: "Minimum Age must be 18",
      }));
     }

    
   }
   else{
    setError((prevState) => ({
      ...prevState,
      aadharOTP: docu.message,
    }));
    
   }
   
   
   } catch (error) {
   console.log(error);  
   }
     }


     const verifyPAN = async () => {

      checkDocument("panNo",panNo).then(async (result)=>{
        if(result === 0){
          try {            
            const requestOptions = {
              method: 'POST',
            //  mode: 'no-cors',
              headers: { 'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Access-Control-Allow-Origin':'*' 
              },
              body: JSON.stringify({ panNo: panNo,
               type:"PAN"
              })
           };
          let res = await fetch('https://us-central1-react-test-5e32f.cloudfunctions.net/aadharVerify', requestOptions);
          let doc = await res.json().catch(e=>{});
          
          console.log(doc);
          //console.log("gdnkdmd");
          
          if(doc.success === true){
            //console.log(doc.data["client_id"]);
            setclientId(doc.data["client_id"])
            await firestore.collection("users").doc(currentUser.id).collection("verified").doc("panVerifiedDetails").set({
             document : doc
           })
           setActiveStep(activeStep + 1);
     
          }
          else{
            setError((prevState) => ({
              ...prevState,
              panVerify: doc.message,
            }));
          
          }
          
          
          } catch (error) {
          console.log(error);  
          }
         }
         else{
           setError((prevState) => ({
             ...prevState,
             panVerify: "Pan No is Already Used",
           }));
         }
     

      })
       }
       


       const verifyBANK = async () => {
        checkDocument("accountNo",accountNo).then(async (result) => {
          if(result === 0){
            try {
              
              
              const requestOptions = {
                method: 'POST',
              //  mode: 'no-cors',
                headers: { 'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin':'*' 
                },
                body: JSON.stringify({ accountNo:accountNo,
                  ifscCode:ifscCode,
                 type:"BANK",
                 userId:currentUser.id
                })
             };
            let res = await fetch('https://us-central1-react-test-5e32f.cloudfunctions.net/aadharVerify', requestOptions);
            let doc = await res.json().catch(e=>{});
            
            console.log(doc);
            //console.log("gdnkdmd");
            
            if(doc.success === true){
              //console.log(doc.data["client_id"]);
             // setclientId(doc.data["client_id"])
              await firestore.collection("users").doc(currentUser.id).collection("verified").doc("bankVerifiedDetails").set({
               document : doc
             })
             setActiveStep(activeStep + 1);
       
            }
            else{
              setError((prevState) => ({
                ...prevState,
                bankVerify: doc.message,
              }));
            
            }
            
            
            } catch (error) {
            console.log(error);  
            }
              }
              else{
                setError((prevState) => ({
                  ...prevState,
                  bankVerify: "Bank No is Already Used.\n Please Enter another Bank No",
                }));
              }
            
        })
      }
  
       

  const handleNext = () => {
    if (activeStep === 0) {
      var phonenumbers = /^\d{10}$/;

      if (!phoneNo) {
        setError((prevState) => ({
          ...prevState,
          phoneNo: "Please enter your phone No",
        }));
      }else if(!phoneNo.match(phonenumbers)){
        setError((prevState) => ({
          ...prevState,
          phoneNolength: "Invalid Phone No",
        }));
      } 
      else {
        setActiveStep(activeStep + 1);
      }
    } else if (activeStep === 1) {
    //  setbtnsubmit(true);
      var numbers = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;

   
      if (!aadharNo) {
        setError((prevState) => ({
          ...prevState,
          aadharNo: "Please enter Aadhar No",
        }));
      } 
      else if (!aadharNo.match(numbers)){
        setError((prevState) => ({
          ...prevState,
          aadharNolength: "Invalid Aadhar No",
        }));
      }
      else if (!selectedaadharFile) {
        setError((prevState) => ({
          ...prevState,
          selectaadharfile: "Please upload your Aadhar Card",
        }));
      }
      else {
        verifyAadhar();
//        setActiveStep(activeStep + 1);
      }
    }
    else if(activeStep === 2) {
      // console.log(clientId);
      // console.log(phoneNo);
      // console.log(otp);
      var aadharotpnumbers = /^[0-9]{6}$/;

   
      if (!aadharotp) {
        setError((prevState) => ({
          ...prevState,
          aadharotp: "Please enter OTP",
        }));
      } 
      else if (!aadharotp.match(aadharotpnumbers)){
        setError((prevState) => ({
          ...prevState,
          aadharotplength: "Invalid OTP",
        }));
      }
      else{
        verifyAadharOTP();
      } 

    }
    else if (activeStep === 3){
      var pannumbers = /[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
      if (!panNo) {
        setError((prevState) => ({
          ...prevState,
          panNo: "Please enter your PAN No",
        }));
      }  else if (!panNo.match(pannumbers)){
        setError((prevState) => ({
          ...prevState,
          panNolength: "Invalid PAN No",
        }));
      }
       else if (!selectedpanFile) {
        setError((prevState) => ({
          ...prevState,
          selectpanfile: "Please upload your PAN card",
        }));
      }
      else{
        verifyPAN();
      }

    }
    else if (activeStep === 4) {
      if (!nameOnAccount) {
        setError((prevState) => ({
          ...prevState,
          nameOnAccount: "Please enter your Account Name",
        }));
      } else if (!accountNo) {
        setError((prevState) => ({
          ...prevState,
          accountNo: "Please enter your Account No",
        }));
      } else if (!accountType) {
        setError((prevState) => ({
          ...prevState,
          accountType: "Please enter Account Type",
        }));
      } else if (!bankName) {
        setError((prevState) => ({
          ...prevState,
          bankName: "Please enter your Bank Name",
        }));
      } else if (!ifscCode) {
        setError((prevState) => ({
          ...prevState,
          ifscCode: "Please enter your Bank IFSC code",
        }));
      } else {
        //setActiveStep(activeStep + 1);
        verifyBANK();
      }
    }
  };


  const fillformagain = async () => {
    setcheckKYC(false);
   await firestore.collection("users").doc(currentUser.id).update({
      kycVerified:false
    })
  }

  useEffect(() => {
//      console.log("jnsjdnfsn");
if(currentUser !== undefined){
  firestore.collection("users").doc(currentUser.id).get().then((doc) => {
    setcheckKYC(doc.data().kycVerified);
    setdocumenterror(doc.data().error);
  }).catch((err) => {
    
  });

}
   
    return () => {
      
    }
  }, [checkKYC])

  // useEffect(() => {
  //     firestore
  //       .collection("users")
  //       .doc(currentUser.id)
  //       .get()
  //       .then(function (doc) {
  //         if (doc.exists) {
  //           // setCurrentPost(doc.data());
  //           console.log("Document data:", doc.data().kycVerified);
  //           setcheckKYC(doc.data().kycVerified);
  //           setdocumenterror(doc.data().error);

  //         } else {
  //           // doc.data() will be undefined in this case
  //           console.log("No such document!");
  //         }
  //       }
  //       .catch(function (error) {
  //         console.log("Error getting document:", error);
  //       }),
       
  //     []
  //   );
  // });

  const ekycRequest = async () => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json',
      'Accept': 'application/json' },
      body: JSON.stringify({ id: currentUser.id })
   };
   await fetch('http://localhost:8080/api/eKYC', requestOptions)
   .then(response => 
    response.json())
   .then(data => {
     console.log(data.message)
   }).catch(err => {
     // Do something for an error here
     console.log("Error Reading data " + err);
   })
  }

  const handlesubmit = async () => {
    await adduserdetails();
  //  setTimeout(5000);
//    await ekycRequest();
   
  }

  const adduserdetails = async () => {
   
    if (!addresslane1) {
      setError((prevState) => ({
        ...prevState,
        addresslane1: "Please enter your Address lane 1",
      }));
    } else if (!addresslane2) {
      setError((prevState) => ({
        ...prevState,
        addresslane2: "Please enter your Address lane 2",
      }));
    } else if (!pincode) {
      setError((prevState) => ({
        ...prevState,
        pincode: "Please enter pincode",
      }));
    } else if (!city) {
      setError((prevState) => ({
        ...prevState,
        city: "Please enter city",
      }));
    } else if (!district) {
      setError((prevState) => ({
        ...prevState,
        district: "Please enter district",
      }));
    } else if (!city) {
      setError((prevState) => ({
        ...prevState,
        state: "Please enter state",
      }));
    } 

     else {
      try {
        //      const blob = await resizeImg(author.photo, 250);

        if (selectedaadharFile != null && selectedpanFile != null) {
          setsubmit(true);
       //   setcheckKYC(true);

          const adharCardFileName = `${currentUser.id}/aadharCard`;
          const adharStorageRef = storage.ref();
          const adharImageRef = adharStorageRef.child(adharCardFileName);
          const uploadAdharTask = adharImageRef.put(selectedaadharFile);

          const panCardFileName = `${currentUser.id}/panCard`;
          const panStorageRef = storage.ref();
          const panImageRef = panStorageRef.child(panCardFileName);
          const uploadPanTask = panImageRef.put(selectedpanFile);

          let adharDownloadUrl = "";
          let panDownloadUrl = "";

          let uploadPromises = [];

          uploadPromises.push(
            uploadAdharTask.then(async (snapshot) => {
              adharDownloadUrl = await snapshot.ref.getDownloadURL();
            })
          );

          uploadPromises.push(
            uploadPanTask.then(async (snapshot) => {
              panDownloadUrl = await snapshot.ref.getDownloadURL();
            })
          );

          await Promise.all(uploadPromises);

          const userRef = firestore.collection("users").doc(currentUser.id);

          let userUpdatePromises = [];

          userUpdatePromises.push(
            userRef.collection("details").doc("personalDetails").set({
              phoneNo,
              gender: gender,
              addresslane1,
              addresslane2,
              pincode,
              city,
              district,
              state,
            })
          );

          userUpdatePromises.push(
            userRef.collection("details").doc("bankDetails").set({
              nameOnAccount,
              accountNo,
              accountType,
              bankName,
              ifscCode,
              bankVerifiedStatus,
            })
          );

          userUpdatePromises.push(
            userRef.collection("details").doc("documentDetails").set({
              aadharNo,
              panNo,
              aadharCard: adharDownloadUrl,
              panCard: panDownloadUrl,
            })
          );

       

          
   
      
          Promise.all(userUpdatePromises).then((docRef) => {
            setsubmit(false);
      //      setActiveStep(0);
            
          });
          setcheckKYC(true); 
        }


                       
      } catch (e) {
        console.error("An error has occured while adding an author", e);
      }
      


    }
  };

  console.log(documenterror);
  return (
    <Paper style={{}} elevation={1} className={classes.root}>
      {checkKYC ? (
        <div  
          style={{
            minHeight: "300px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        > <div>

          <Typography
            variant="h4"
            gutterBottom
            color="primary"
            style={{
              padding: "0 8px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            Your KYC Form has been submitted !
          
          </Typography>
          <Typography
            gutterBottom
            style={{
              padding: "0 8px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            Thank You!.
        
          </Typography>

          </div>
        
         
        
        </div>
      ) : (
        <div>
          <Typography
            variant="h4"
            gutterBottom
            color="primary"
            style={{
              padding: "0 8px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            Your KYC is not updated !
          </Typography>
          <Typography
            gutterBottom
            style={{
              padding: "0 8px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            Please update your kyc to use the services.
          </Typography>
          <WizardHeader
            tabs={tabs}
            activeStep={activeStep}
            handleChange={handleChange}
          />

          <form>
            <SwipeableViews disabled="false" index={activeStep}>
              <PersonalDetails
                error={error}
                name={name}
                setName={setName}
                phoneNo={phoneNo}
                setphoneNo={setphoneNo}
                selectedDate={selectedDate}
                handleDateChange={handleDateChange}
                gender={gender}
                setGender={setGender}
              />
              
              <AadharDetails
                error={error}
                aadharNo={aadharNo}
                setaadharNo={setaadharNo}
                selectedaadharFile={selectedaadharFile}
                setSelectedaadharFile={setSelectedaadharFile}
              />
              <AadharVerification
              error={error}
              aadharotp={aadharotp}
              setaadharotp={setaadharotp}
              />
              <PanDetails
                error={error}
                panNo={panNo}
                setpanNo={setpanNo}
                selectedpanFile={selectedpanFile}
                setSelectedpanFile={setSelectedpanFile}
              />
               <BankDetails
                error={error}
                nameOnAccount={nameOnAccount}
                setnameOnAccount={setnameOnAccount}
                accountNo={accountNo}
                setaccountNo={setaccountNo}
                accountType={accountType}
                setaccountType={setaccountType}
                bankName={bankName}
                setbankName={setbankName}
                ifscCode={ifscCode}
                setifscCode={setifscCode}
                bankVerifiedStatus={bankVerifiedStatus}
                setbankVerifiedStatus={setbankVerifiedStatus}
              />
              <AddressDetails
                error={error}
                addresslane1={addresslane1}
                setaddresslane1={setaddresslane1}
                addresslane2={addresslane2}
                setaddresslane2={setaddresslane2}
                district={district}
                setdistrict={setdistrict}
                state={state}
                setstate={setstate}
                city={city}
                setcity={setcity}
                pincode={pincode}
                setpincode={setpincode}
              />
             
                          

            </SwipeableViews>
            <Grid
              container
              justify="space-between"
              style={{ padding: "16px 16px" }}
            >
              <Grid item>
                <Button
                  style={{textAlign:"center",margin:"0 auto"}}
                  disabled
                  onClick={handlePrev}
                  variant="contained"
                  color="default"
                  className={`${classes.navigation} ${classes.prevBtn}`}
                >
                  Back
                </Button>
              </Grid>
              {activeStep < tabs.length - 1 && (
                <Grid item>
                  <Button
                    color="primary"
                    className={classes.navigation + " flex items-center tc"}
                    variant="contained"
                    onClick={handleNext}
                    disabled={btnsubmit}
                  >
                    Next
                  </Button>
                </Grid>
              )}
              {activeStep === tabs.length - 1 && (
                <Grid item>
                  {submit ? (
                    <Button
                      color="primary"
                      className={classes.navigation}
                      variant="contained"
                      disabled
                    >
                      Submitting
                    </Button>
                  ) : (
                    <Button
                      color="primary"
                      className={classes.navigation}
                      variant="contained"
                      onClick={handlesubmit}
                    >
                      Submit
                    </Button>
                  )}
                </Grid>
              )}
            </Grid>
          </form>
        </div>
      )}
    </Paper>
  );
};

const mapStateToProps = ({ user }) => ({
  currentUser: user.currentUser,
});
const mapDispatchToProps = (dispatch) => ({});

//export default withStyles(style)(Content);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(Content));

import React, { useEffect } from "react";
import SignIn from "../../components/sign-in/sign-in.component";
import SignUp from '../../components/sign-up/sign-up.component';
import "./sign-in-and-sign-up.styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { setReferredId } from "../../redux/referred/referred.actions";
import { connect } from "react-redux";

const SignInAndSignUpPage = (props) => {

  const parameter1 = props.match.params.paramater;
  console.log(parameter1);

  const count = useSelector(state => { console.log(state.referred.referredId); });
  const dispatch = useDispatch();

  console.log("sent")

  dispatch(setReferredId(parameter1));

  //  console.log(currentUser)


  return (
    <div className="sign-in-and-sign-up">

      <SignIn /><SignUp /></div>
  )
};
// const mapStateToProps = ({referred}) => ({
//  referredId: referred,
// });

// const mapDispatchToProps = (dispatch) => ({
//   setReferredId: (id) => dispatch(setReferredId(id)),
// });


//export default connect(mapStateToProps, mapDispatchToProps)(SignInAndSignUpPage);

export default SignInAndSignUpPage;
import PropTypes from 'prop-types';
import { useState, useRef, useEffect } from 'react';
import './homepageitems.scss';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { Link } from 'react-router-dom';

import img1 from '../../assets/1(1).png';
import img2 from '../../assets/3(1).png';
import img3 from '../../assets/2(1).png';
import img4 from '../../assets/MY TREE (2).png';
import img5 from '../../assets/5.png';

const imgArray = [
  img1,
  img2,
  img3,
  img4,
  img5,
]

const useTilt = (active) => {
  const ref = useRef(null);

  useEffect(() => {
    if (!ref.current || !active) {
      return;
    }

    const state = {
      rect: undefined,
      mouseX: undefined,
      mouseY: undefined
    };

    let el = ref.current;

    const handleMouseMove = (e) => {
      if (!el) {
        return;
      }
      if (!state.rect) {
        state.rect = el.getBoundingClientRect();
      }
      state.mouseX = e.clientX;
      state.mouseY = e.clientY;
      const px = (state.mouseX - state.rect.left) / state.rect.width;
      const py = (state.mouseY - state.rect.top) / state.rect.height;

      el.style.setProperty("--px", px);
      el.style.setProperty("--py", py);
    };

    el.addEventListener("mousemove", handleMouseMove);

    return () => {
      el.removeEventListener("mousemove", handleMouseMove);
    };
  }, [active]);

  return ref;
};

const Slide = ({
  image,
  title,
  subtitle,
  description,
  offset,
  isPageBackground,
  path,
  key,
  index
}) => {
  const active = offset === 0 ? true : null,
    ref = useTilt(active);

  console.log(key);
  return (
    <div
      ref={ref}
      className="slide"
      data-active={active}
      style={{
        "--offset": offset,
        "--dir": offset === 0 ? 0 : offset > 0 ? 1 : -1
      }}
    >
      {isPageBackground && (
        <div
          className="slideBackground"
          style={{
            backgroundImage: `url('${imgArray[index]}')`
          }}
        />
      )}
      <div
        className="slideContent"
        style={{ backgroundImage: `url('${imgArray[index]}')` }}
      >
        <div className="slideContentInner">
          {title && (
            <Link style={{ textDecoration: "none !important" }} to={path}>
              <h2 className="slideTitle" dir="auto">
                {title}
              </h2>
            </Link>
          )}
          {subtitle && (
            <h3 className="slideSubtitle" dir="auto">
              {subtitle}
            </h3>
          )}
          {description && (
            <p className="slideDescription" dir="auto">
              {description}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

Slide.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  offset: PropTypes.number.isRequired,
  isPageBackground: PropTypes.bool,
  path: PropTypes.string
};

const Carousel = ({ slides, isPageBackground }) => {
  const [slideIndex, setSlideIndex] = useState(0);

  const handlePrevSlide = () => {
    setSlideIndex((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
  };

  const handleNextSlide = () => {
    setSlideIndex((prev) => (prev + 1) % slides.length);
  };

  return (
    <section className="slidesWrapper">
      <div className="slides">
        <button className="prevSlideBtn" onClick={handlePrevSlide}>
          <ChevronLeftIcon style={{ color: "white", fontSize: "50px" }} />
        </button>

        {[...slides, ...slides, ...slides].map((slide, i) => {
          let offset = slides.length + (slideIndex - i);

          if (typeof slide === "string") {
            return (
              <Slide
                image={slide}
                offset={offset}
                isPageBackground={isPageBackground}
                key={i}
                index={slide.id - 1}
              />
            );
          } else {
            return (
              <Slide
                image={slide.image}
                title={slide.title}
                subtitle={slide.subtitle}
                description={slide.description}
                offset={offset}
                isPageBackground={isPageBackground}
                key={i}
                index={slide.id - 1}
                path={slide.path}

              />
            );
          }
        })}
        <button className="nextSlideBtn" onClick={handleNextSlide}>

          <ChevronRightIcon style={{ color: "white", fontSize: "50px" }} />
        </button>
      </div>
    </section>
  );
};

Carousel.propTypes = {
  slides: PropTypes.array.isRequired,
  isPageBackground: PropTypes.bool
};


export default Carousel;

// const app = <Carousel slides={slides} isPageBackground />;

// ReactDOM.render(app, document.querySelector("#app"));

import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import img1 from '../../assets/1(1).png';

function MyTreeHomepage({ currentUser }) {
    console.log(currentUser)
    const [open, setOpen] = React.useState(true);
    const [agentID, setagentID] = useState("");
    let history = useHistory();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [msg, setmsg] = useState("");

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = () => {
        //        alert(agentID);
        if (currentUser.agentID === agentID || currentUser.staragentID === agentID || currentUser.crownagentID === agentID) {
            history.push({
                pathname: '/myagenttree',
                state: { agentID: agentID }
            });

        }
        else {
            setmsg("Invalid Agent Id")
        }

    }

    return (
        <div>
            <div style={{ backgroundImage: `url(${img1})`, textAlign: "center", minHeight: "100vh", maxHeight: "100vh", marginTop: "45vh" }}>
                <Button variant="outlined" color="primary" style={{ textAlign: "center" }} onClick={handleClickOpen}>
                    Enter Agent Id
                </Button>


            </div>


            <Dialog
                fullScreen={fullScreen}
                fullWidth
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title"></DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <TextField id="AGENTID" onChange={(e) => setagentID(e.target.value)} value={agentID} label="Enter Agent Id " fullWidth variant="outlined" />
                        <p style={{ color: "red" }}>{msg}</p>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleSubmit} color="primary" variant="outlined">
                        Submit
                    </Button>
                    <Button onClick={handleClose} color="primary" autoFocus>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const mapStateToProps = ({ user }) => ({
    currentUser: user.currentUser,
});

export default connect(mapStateToProps, null)(MyTreeHomepage);
//import { act } from "react-dom/test-utils";
import {  SET_REFERRED_ID } from "./referred.types";

const INITIAL_STATE = {
    referredID : null
}

const referredReducer = (state = INITIAL_STATE, action) => {
    
    switch (action.type) {
        case SET_REFERRED_ID:
            return{
                ...state,
                referredID: action.referredId
            }    
    
        default:
            return state;
    }
}

export default referredReducer;
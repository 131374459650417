import { useEffect } from "react";
import { firestore } from "../../firebase/firebase.utils";

const Rules = () => {



    useEffect(() => {

        firestore.collectionGroup("details").where("aadharNo", "==", "894311175343").get().then((value) => {
            console.log(value.docs.length);
        })

        return () => {

        }
    }, [])


    return (
        <h1>rules</h1>
    )
}


export default Rules;
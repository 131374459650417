import { Avatar, IconButton } from '@material-ui/core';
import { Send, AttachFile, InsertEmoticon, SearchOutlined, Mic } from '@material-ui/icons';
import MoreVert from '@material-ui/icons/MoreVert';
import React, { useState, useEffect, useRef } from 'react';
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import './chat.css';
import { firestore as db } from '../../firebase/firebase.utils';
//import { useStateValue } from './StateProvider';
import firebase from 'firebase';
import InsertEmoticonIcon from "@material-ui/icons/InsertEmoticon";
import { Picker } from "emoji-mart";
import "emoji-mart/css/emoji-mart.css";


function Chat({ currentUser }) {

  console.log(currentUser);
  const [seed, setSeed] = useState("");
  const [input, setInput] = useState("");
  const { roomId } = useParams();
  const [roomName, setRoomName] = useState("");
  const [messages, setMessages] = useState([]);
  const [emoji, setEmoji] = useState(false);

  const [name, setname] = useState("");
  const [photo, setphoto] = useState("");
  //  const [{user}, dispatch] = useStateValue();
  const id = "IvNsECE5pMeqm3RQk4xT5ECeqSZ2";
  const addEmoji = (e) => {
    let emoji = e.native;
    setInput(input + emoji);
  };
  const checkEmojiClose = () => {
    if (emoji) {
      setEmoji(false);
    }
  };

  useEffect(() => {
    console.log(roomId);
    if (currentUser) {
      if (roomId) {

        db.collection("chats")
          .doc(currentUser.id)
          .collection(roomId)
          .orderBy("timestamp", "asc")
          .onSnapshot((snapshot) =>
            setMessages(snapshot.docs.map((doc) =>
              doc.data()
            ))

          );

        db.collection("users").doc(roomId).get().then((result) => {
          setname(result.data().displayName);
          setphoto(result.data().photoURL)
        }).catch((err) => {

        });
      }
    }
  }, [roomId, setRoomName]);
  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
    // console.log('called')
  };

  useEffect(() => {
    scrollToBottom();
    setSeed(Math.floor(Math.random() * 5000));



  }, []);

  const sendMessage = (e) => {
    e.preventDefault();
    const d = Date.now();

    db.collection('chats').doc(currentUser.id).collection(roomId).doc(d.toString())
      .set({
        text: input,
        id: currentUser.id,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      });

    db.collection('chats').doc(roomId).collection(currentUser.id).doc(d.toString())
      .set({
        text: input,
        id: currentUser.id,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      });

    setInput("");

  }

  return (
    <div className="chat">

      <div className="chat__header">

        <Avatar src={photo ? photo : `https://www.yashodahospital.org/wp-content/uploads/2018/09/no_image.png`} />

        <div className="chat__headerInfo">

          <h3>{name}</h3>
          <p> {messages.length ? "Last seen" + " " + new Date(messages[messages.length - 1]?.timestamp?.toDate()).toUTCString() : ""}</p>

        </div>

        {/* <div className="chat__headerRight">

            <IconButton>
                <SearchOutlined/>
            </IconButton>

            <IconButton>
            <AttachFile/>
            </IconButton>

            <IconButton>
                <MoreVert/>
            </IconButton>

        </div> */}

      </div>


      <div className="chat__body">

        {messages.map((message) =>
        (
          <p className={`chat__message ${message.id === currentUser.id && "chat__receiver"}`}>
            {/* <span className="chat__name">{message.name}</span> */}
            {message.text}
            <span className="chat__timestamp">
              {new Date(message.timestamp?.toDate()
              ).toUTCString()}
            </span>
          </p>

        ))}



      </div>


      <div className="chat__footer">
        <IconButton>
          {/* <InsertEmoticonIcon /> */}
          <InsertEmoticonIcon
            className="yellow"
            onClick={() => setEmoji(!emoji)}
          />
          {emoji ? <Picker onSelect={addEmoji} /> : null}
        </IconButton>
        {/* <IconButton>
          <AttachFile />
        </IconButton> */}

        <form>
          <input value={input} onChange={e => setInput(e.target.value)} type="text" placeholder="Type your message..." />

          <button className="sendButton" onClick={sendMessage} type="submit"></button>

        </form>

        <IconButton onClick={sendMessage}>
          <Send />
        </IconButton>


      </div>


    </div>
  )
}
const mapStateToProps = ({ user, referred }) => ({
  currentUser: user.currentUser,
  referredId: referred.referredId,
});

//export default Chat
export default connect(mapStateToProps, null)(Chat);

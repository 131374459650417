import { combineReducers } from "redux";

import userReducer from './user/user.reducer';
import userDetails from './kyc-form/kyc.reducers'
import referredReducer  from './referred/referred.reducers';

const rootReducer =  combineReducers({
    user: userReducer,
    userDetails:userDetails,
    referred : referredReducer
})


export default rootReducer;
import { useState, useEffect } from "react";
import { Row, Card, Button, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import { firestore } from "../../firebase/firebase.utils";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import '../smsGroups/smsgroups.css';
import { Modal } from "react-bootstrap";
import { InputGroup, FormControl, Form } from "react-bootstrap";

import aadharImage from "../../assets/aadhar.png";
import panImage from "../../assets/pancard.jpg";
import bankImage from "../../assets/bank.jpg";
import { CircularProgress } from "@material-ui/core";

import EditIcon from '@material-ui/icons/Edit';


const KYCHomePage = ({ currentUser }) => {
  const [aadharVerified, setaadharVerified] = useState(false);
  const [panVerified, setpanVerified] = useState(false);
  const [bankVerified, setbankVerified] = useState(false);
  const [length, setlength] = useState(0);

  const [show, setshow] = useState(false);
  const [nameOnAccount, setnameOnAccount] = useState("");
  const [bankname, setbankname] = useState("");
  const [accountNo, setaccountNo] = useState("");
  const [ifscCode, setifscCode] = useState("");
  const [accountType, setaccountType] = useState("");



  var data = [];

  // const id = useSelector(x => {
  //   return x.user.currentUser.id})

  //   console.log(id);


  const handleSubmit = () => {
    setshow(false)
    alert(bankname + accountNo + ifscCode + accountType)
  }
  const checkDocument = async (type, documentNo) => {
    return await firestore.collectionGroup("details").where(type, "==", documentNo).get().then((value) => {
      console.log(value.docs.length);
      return value.docs.length;
    })
  }


  const verifyBANK = async () => {

    checkDocument("accountNo", accountNo).then(async (result) => {

      if (result === 0) {
        try {


          const requestOptions = {
            method: 'POST',
            //  mode: 'no-cors',
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Access-Control-Allow-Origin': '*'
            },
            body: JSON.stringify({
              accountNo: accountNo,
              ifscCode: ifscCode,
              type: "BANK",
              userId: currentUser.id
            })
          };
          let res = await fetch('https://us-central1-react-test-5e32f.cloudfunctions.net/aadharVerify', requestOptions);
          let doc = await res.json().catch(e => { });

          console.log(doc);
          //console.log("gdnkdmd");

          if (doc.success === true) {
            //console.log(doc.data["client_id"]);
            // setclientId(doc.data["client_id"])
            await firestore.collection("users").doc(currentUser.id).collection("verified").doc("bankVerifiedDetails").set({
              document: doc
            })

            await firestore.collection("users").doc(currentUser.id).collection("details").doc("bankDetails").set({
              accountNo: accountNo,
              accountType: accountType,
              bankName: bankname,
              ifscCode: ifscCode,
              nameOnAccount: nameOnAccount
            }, { merge: true })
            // setActiveStep(activeStep + 1);
            alert("Bank Details Changed")
          }
          else {

            alert(doc.message)

          }


        } catch (error) {
          console.log(error);
        }
      }
      else {
        alert("Bank No is Already Used. Please Enter another Bank No");
      }

      setshow(false)
    })
  }

  useEffect(async () => {
    if (currentUser !== undefined) {
      await firestore
        .collection("users")
        .doc(currentUser.id)
        .collection("verified")
        .onSnapshot((snapshot) => {
          console.log(snapshot.docs);
          setlength(snapshot.docs.length);
          snapshot.docs.map((verifyData) => {
            console.log(verifyData.id);
            if (verifyData.id === "aadharVerifiedDetails") {
              setaadharVerified(true);
              console.log("hisfbfbnf");
            }

            if (verifyData.id === "panVerifiedDetails") {
              setpanVerified(true);
            }
            if (verifyData.id === "bankVerifiedDetails") {
              setbankVerified(true);
            }
          });
        });
      // if(data.includes("aadharVerifiedDetails")){
      //   setaadharVerified(true)
      //   console.log("hisfbfbnf");
      // }
      // else if(data.includes("panVerifiedDetails")){
      //   setpanVerified(true)
      // }
      // else if(data.includes("bankVerifiedDetails")){
      //   setbankVerified(true);
      // }

      console.log(data);
    }

    return () => { };
  }, [currentUser]);

  return (
    <>
      {
        currentUser ?

          (currentUser?.membershipID ? <Container style={{ justify: "center" }}>
            {length !== 3 ? (
              <h1 style={{ textAlign: "center", color: "#3f51b5" }}>
                Your Kyc is not Verified
              </h1>
            ) : (
              <h1 className="headerText" style={{ textAlign: "center", color: "#3f51b5" }}>
                Your Kyc is Verified
              </h1>
            )}

            <Row>
              <Col lg="4" md="6" xs="12" className="p-5">
                <Card style={{ width: "18rem" }}>
                  <Card.Img
                    variant="top"
                    src={aadharImage} />

                  <Card.Body style={{ justify: "center", margin: "0 auto" }}>
                    <Card.Title style={{ fontSize: "1.2rem" }}>
                      1. Aadhar Verification
              </Card.Title>
                    <Card.Text></Card.Text>
                    {!aadharVerified ? (
                      <Link
                        to={{
                          pathname: "/verifyKYC",
                          state: {
                            currentindex: 0,
                          },
                        }}
                      >
                        <Button className="ml-5" variant="primary">
                          Verify
                  </Button>
                      </Link>
                    ) : (
                      <Button className="ml-4" variant="success">
                        {" "}
                        <CheckCircleRoundedIcon style={{ color: "#fff" }} /> Verified
                      </Button>
                    )}
                  </Card.Body>
                </Card>
              </Col>
              <Col lg="4" md="6" xs="12" className="p-5">
                <Card style={{ width: "18rem" }}>
                  <Card.Img
                    variant="top"
                    src={panImage}
                  />

                  <Card.Body style={{ justify: "center", margin: "0 auto" }}>
                    <Card.Title style={{ fontSize: "1.2rem" }}>
                      2. PAN Verification
              </Card.Title>
                    <Card.Text></Card.Text>

                    {!panVerified ? (
                      <Link
                        to={{
                          pathname: "/verifyKYC",
                          state: {
                            currentindex: 3,
                          },
                        }}
                      >
                        <Button
                          disabled={!aadharVerified}
                          className="ml-5"
                          variant="primary"
                        >
                          Verify
                    </Button>
                      </Link>
                    ) : (
                      <Button className="ml-4" variant="success">
                        {" "}
                        <CheckCircleRoundedIcon style={{ color: "#fff" }} /> Verified
                      </Button>
                    )}
                  </Card.Body>
                </Card>
              </Col>
              <Col lg="4" md="6" xs="12" className="p-5">
                <Card style={{ width: "18rem" }}>
                  <Card.Img style={{ width: "140px", justify: "center", margin: "0 auto" }}
                    variant="top"
                    src={bankImage} />
                  <Card.Body style={{ justify: "center", margin: "0 auto" }}>
                    <Card.Title style={{ fontSize: "1.2rem" }}>
                      3. Bank Verification
              </Card.Title>
                    <Card.Text></Card.Text>
                    {!bankVerified ? (
                      <Link
                        to={{
                          pathname: "/verifyKYC",
                          state: {
                            currentindex: 4,
                          },
                        }}
                      >
                        <Button
                          disabled={!panVerified}
                          className="ml-5"
                          variant="primary"
                        >
                          Verify
                  </Button>
                      </Link>
                    ) : (
                      <>
                        <Button onClick={() => setshow(true)} style={{ fontSize: "13.5px" }} className="mr-1" variant="primary">
                          {" "}
                          <EditIcon style={{ color: "#fff" }} /> Edit
                      </Button>

                        <Button className="" variant="success">
                          {" "}
                          <CheckCircleRoundedIcon style={{ color: "#fff" }} /> Verified
                      </Button>

                      </>
                    )}
                  </Card.Body>
                </Card>

                <Modal
                  show={show}
                  size="md"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                      Edit Bank Details
        </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>

                    <InputGroup className="mb-2">
                      <InputGroup.Prepend>
                        <InputGroup.Text> Name On Account</InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl onChange={(e) => setnameOnAccount(e.target.value)} />
                    </InputGroup>

                    <InputGroup className="mb-2">
                      <InputGroup.Prepend>
                        <InputGroup.Text>Bank Name</InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl onChange={(e) => setbankname(e.target.value)} />
                    </InputGroup>


                    <InputGroup className="mb-2">
                      <InputGroup.Prepend>
                        <InputGroup.Text>Account No</InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl onChange={(e) => setaccountNo(e.target.value)} placeholder="" />
                    </InputGroup>



                    <InputGroup className="mb-2">
                      <InputGroup.Prepend>
                        <InputGroup.Text>IFSC Code</InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl onChange={(e) => setifscCode(e.target.value)} placeholder="" />
                    </InputGroup>



                    <InputGroup className="mb-2">
                      <InputGroup.Prepend>
                        <InputGroup.Text>Account Type</InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl onChange={(e) => setaccountType(e.target.value)} placeholder="" />
                    </InputGroup>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button onClick={() => verifyBANK()} variant="success" >Submit </Button>
                    <Button onClick={() => setshow(false)}>Close</Button>
                  </Modal.Footer>
                </Modal>


              </Col>
            </Row>
          </Container>
            : <div style={{
              minHeight: "80vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}> <Card style={{ width: '70vw' }}>
                <Card.Body>
                  <Card.Title style={{ color: "rgb(63, 81, 181)", fontSize: "2rem" }}>             <Link to="/" className="smalltext" style={{ textDecoration: "none", textAlign: "center" }}>  Please Become Kalpavruksha Society <br></br> Member To Verify KYC.</Link>
                  </Card.Title>
                </Card.Body>
              </Card> </div>) :
          <div style={{
            minHeight: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}><CircularProgress /></div>
      }
    </>);
};
const mapStateToProps = ({ user, referred }) => ({
  currentUser: user.currentUser,
  referredId: referred.referredId,
});

export default connect(mapStateToProps, null)(KYCHomePage);

//export default KYCHomePage;

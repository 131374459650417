import "./my-tree.styles.scss";
import React, { useState, useEffect } from "react";
import Tree from "react-d3-tree";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress } from "@material-ui/core";
import { faCommentDots, faPlus } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { useCenteredTree } from "../../helper";
import { Button } from 'react-bootstrap';
import {

    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Avatar,
    ListItemSecondaryAction,
    IconButton,
} from "@material-ui/core";
// import  Add  from "@material-ui/icons/Add";
import firebase, {
    getUsersLists,
    getTree,
    setTree,
    firestore,
} from "../../firebase/firebase.utils";

import FloatingMenu from './share.js';

import { FacebookIcon, TwitterIcon, WhatsappIcon, TelegramIcon, EmailIcon, RedditIcon, TumblrIcon } from "react-share";
import {
    FacebookShareButton,
    GooglePlusShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    TelegramShareButton,
    WhatsappShareButton,
    PinterestShareButton,
    VKShareButton,
    OKShareButton,
    RedditShareButton,
    TumblrShareButton,
    LivejournalShareButton,
    MailruShareButton,
    ViberShareButton,
    WorkplaceShareButton,
    EmailShareButton,
} from 'react-share';


import Fab from '@material-ui/core/Fab';
import NavigationIcon from '@material-ui/icons/Navigation';


const scaleExtent = {
    min: "0.7",
    max: "0.7"
}

function printAllVals(obj, user, addUserNode, displayName, photoURL, userId) {
    //console.log(obj);

    for (let k in obj) {
        if (typeof obj[k] === "object") {
            printAllVals(obj[k], user, addUserNode, displayName, photoURL, userId);
        } else {
            // base case, stop recurring
            if (obj[k] === addUserNode) {
                console.log(obj[k]);
                console.log(user);
                obj["id"] = user;
                obj["displayName"] = displayName;
                obj["photoURL"] = photoURL;
                obj["userId"] = userId;
                obj["children"] = [
                    { name: obj["name"] + "0" },
                    { name: obj["name"] + "1" },
                ];
            }
        }
    }

    return JSON.parse(JSON.stringify(obj));
}






let userFound = false;
window.$leftdata = 0;
window.$rightdata = 0;


const MyAgentTree = ({ currentUser, location }) => {
    console.log(location);
    //   console.log(this.props.location.state.agentID);

    var agentID = "";
    var url = "";

    if (location.state !== undefined) {
        agentID = location.state.agentID;
        url = `https://kmcsociety.com/referral/${location.state.agentID}`;
    }

    const [shouldShowDialog, setShowDialog] = useState(false);
    const [shouldShowUserDialog, setShowUserDialog] = useState(false);
    const [leftchildren, setleftchildren] = useState(0);
    const [rightchildren, setrightchildren] = useState(0);
    const [parentBname, setparentBname] = useState();
    const [childrenBname, setchildrenBname] = useState("");
    const [users, setUsers] = useState(null);
    //  const [orgChart, setOrgChart] = useState(orgChart1);
    const [translate, containerRef] = useCenteredTree();
    const [addUserNode, setaddUserNode] = useState("");
    const [userPhoto, setuserPhoto] = useState(new Map());
    const [orgChart, setOrgChart] = useState({});
    const [orgChart1, setOrgChart1] = useState(null);
    const [parentId, setparentId] = useState("");

    const [referId, setreferId] = useState("");
    const [parentPhoto, setparentPhoto] = useState("");
    const [parentName, setparentName] = useState("");

    const [profilePhoto, setprofilePhoto] = useState("");
    const [profileName, setprofileName] = useState("");
    const [addUserid, setaddUserid] = useState("");
    //  const [userFound, setuserFound] = useState(false);
    // const [count , setCount] = useState(0);
    const [orient, setorient] = useState("vertical");
    // const [userId, setuserId] = useState("");

    var leftdata = 0;
    var rightdata = 0;


    const checkLeftRight = (obj, parentname, leftchildren, rightchildren) => {
        console.log(obj);

        console.log(leftchildren);
        console.log(rightchildren);
        var count = true;

        console.log(obj["name"])
        if (typeof obj["children"] !== "undefined" && obj["name"] !== parentname) {
            console.log(obj["name"])
            if (obj["name"].substring(0, parentname.length + 1) === (parentname + "0")) {
                console.log(leftchildren);
                window.$leftdata++;
                console.log("left");
            }


            if (obj["name"].substring(0, parentname.length + 1) === (parentname + "1")) {
                console.log(rightchildren);
                window.$rightdata++;
                console.log("right");
            }
        }
        for (let k in obj) {
            // console.log(obj[k]);

            if (typeof obj[k] === "object") {
                checkLeftRight(obj[k], parentname, leftchildren, rightchildren);
            }
            if (obj["name"]) {

                //        if(typeof obj["children"] !== "undefined" && count && obj["name"] !== parentname){



                //   console.log(obj["name"]);
                //   // console.log(leftchildren);
                //   // console.log(rightchildren);

                // if(obj["name"].substring(0,parentname.length+1) === (parentname + "0")){
                //   console.log(leftchildren);
                //   setleftchildren(leftchildren++); 
                //   console.log("left");
                // }


                // if(obj["name"].substring(0,parentname.length+1) ===( parentname + "1")){
                //   console.log(rightchildren);
                //   setrightchildren(rightchildren+1); 
                //   console.log("right");
                // }


                // count = false;


                //        }
                //      console.log(obj["name"]);
            }


        }

        return JSON.parse(JSON.stringify(obj));
    }



    console.log("hello");

    //    console.log(JSON.stringify(orgChart));
    //    var mydata = JSON.stringify(orgChart);
    //   if(currentUser){
    //     var startindex = mydata.indexOf(currentUser.id); 
    //     var lastindex = mydata.indexOf(currentUser.id);
    //     var count=0;
    //     var start = true;
    //     var check = false;
    //     var i = startindex+1;
    //     console.log(i);
    //     if(mydata !== "{}"){
    //     while(start){

    //       // if( mydata.charAt(i) === '['){
    //       //   console.log(mydata.charAt(i));
    //       //   count++; 
    //       //   check = true;
    //       // }
    //       // else if(mydata.charAt(i) === ']'){
    //       //   count--;
    //       // }

    //       // if(check){
    //       //     if(i === 300){
    //       //       lastindex = i - 1;
    //       //       start = false;
    //       //     }
    //       //   }

    //       if(i === 250){
    //         start = false;
    //       }
    //       i++;
    //       console.log(i);
    //     }
    //   }
    //     console.log(startindex);
    //     console.log(lastindex);

    // }



    useEffect(() => {

        console.log("sdgujchjskdndskjdcnkm");
        getTree().on("value", (e) => {
            console.log(e.val());
            var s =
                e.val() || '{"name":"Root","id":"KMC_BIN1_0000369","displayName":"KMC","photoURL":"","children":[{"name":"0","id":"KMC_BIN1_0000370","displayName":"KMC","photoURL":"","children":[{"name":"00","id":"KMC_BIN1_0000369","displayName":"KMC","photoURL":"","children":[{"name":"000"},{"name":"001"}]},{"name":"01","id":"KMC_BIN1_0000369","displayName":"KMC","photoURL":"","children":[{"name":"010"},{"name":"011"}]}]},{"name":"1","id":"KMC_BIN1_0000369","displayName":"KMC","photoURL":"","children":[{"name":"10","id":"KMC_BIN1_0000369","displayName":"KMC","photoURL":"","children":[{"name":"100"},{"name":"101"}]},{"name":"11","id":"KMC_BIN1_0000369","displayName":"KMC","photoURL":"","children":[{"name":"110"},{"name":"111"}]}]}]}'
            //  console.log(orgChart)

            setOrgChart(JSON.parse(s));
            console.log("Real time data");
            console.log(JSON.parse(s));


            // if(currentUser){
            //   console.log(s);
            //   console.log(currentUser.id);
            //   var startindex = s.indexOf(currentUser.id); 
            //           var lastindex = s.indexOf(currentUser.id);
            //           var count=0;
            //           var setLast = true;
            //           var setFirst = true;
            //           var check = false;
            //           var i = startindex+1;
            //           var j = startindex-1;
            //           console.log(i);
            //           console.log(j);
            // }


            //get child
            if (currentUser) {
                if (s.includes(agentID)) {

                    //          console.log(currentUser);

                    var startindex = s.indexOf(agentID);
                    var parentstartindex1 = s.indexOf(agentID) - 9;
                    var lastindex = s.indexOf(agentID);
                    var parentstartindex = s.indexOf(agentID);
                    var count = 0;
                    var setLast = true;
                    var setFirst = true;
                    var setparentFirst = true;
                    var check = false;
                    var i = startindex + 1;
                    var j = startindex - 1;

                    console.log(i);
                    if (s !== "{}") {
                        while (setFirst) {
                            if (s.charAt(j) === '{') {
                                startindex = j;
                                setFirst = false;
                            }
                            j--;
                        }

                        while (setLast) {

                            if (s.charAt(i) === '[') {
                                console.log(s.charAt(i));
                                count++;
                                check = true;
                            }
                            else if (s.charAt(i) === ']') {
                                count--;
                            }

                            if (check) {
                                if (count === 0) {
                                    lastindex = i + 1;
                                    setLast = false;
                                }
                            }

                            // if(i === 250){
                            //   start = false;
                            // }
                            i++;
                            //console.log(i);
                        }
                        //   var y = startindex;
                        //   var parentstartindex1 = startindex;
                        //   while(setparentFirst1){
                        //     console.log("fggh");
                        //     if(s.charAt(y) === '"' && s.charAt(y+1) === 'i' && s.charAt(y+2) === 'd' && s.charAt(y+3) === '"'){
                        //       parentstartindex1 = y;
                        //       setparentFirst1 = false; 
                        //   }
                        //   y++;
                        // }
                        console.log(s.substring(startindex + 9, parentstartindex1));
                        var x = s.substring(startindex + 9, parentstartindex1);
                        var setparentFirst1 = true;
                        var k = s.indexOf('"' + x + '"');
                        while (setparentFirst) {
                            if (s.charAt(k - 1) === '"' && s.charAt(k) === 'i' && s.charAt(k + 1) === 'd' && s.charAt(k + 2) === '"') {
                                parentstartindex = k;
                                setparentFirst = false;
                            }
                            k++;
                        }
                    }
                    console.log(startindex);
                    console.log(parentstartindex1);

                    console.log(lastindex);
                    console.log(parentstartindex);
                    console.log(s.substring(parentstartindex + 5, parentstartindex + 21));
                    setparentId(s.substring(parentstartindex + 5, parentstartindex + 21));

                    var myData = s.substring(startindex, lastindex) + '}';
                    console.log(myData);
                    setOrgChart1(JSON.parse(myData));
                    var x = JSON.parse(myData)["name"]
                    console.log(x)
                    setparentBname(x);
                    setleftchildren(0);
                    setrightchildren(0);
                    window.$leftdata = 0;
                    window.$rightdata = 0;
                    console.log("ggggggggggggggggggggggggggggggggggggggg")
                    checkLeftRight(JSON.parse(myData), x, 0, 0);

                }

                firestore.collection("agents").doc(agentID).get().then((e) => {
                    if (e.data() !== undefined) {

                        firestore.collection("agents").doc(e.data().referredFrom).get().then((result) => {
                            console.log(result.data());
                            setparentName(result.data().displayName);
                            setparentPhoto(result.data().photoURL);
                            setreferId(result.data().userId)

                        })
                    }
                });
            }

            if (parentId) {
                console.log("fghvhjhhghghvhv");
                console.log(parentId);


            }

        });
        return () => { };
    }, [currentUser, parentId]);

    function handleClose() {
        setShowDialog(false);
        //forceUpdate();
    }
    function handleCloseUser() {
        setShowUserDialog(false);
    }


    const handleAddchildren = async (agentID, childrenId, parentBname, childrenBname) => {

        var position;
        console.log(parentBname);
        console.log(childrenBname);

        if (childrenBname.substring(0, parentBname.length + 1) === parentBname + "0") {
            position = "left";
            console.log("left");
        }
        else if (childrenBname.substring(0, parentBname.length + 1) === parentBname + "1") {
            position = "right";
            console.log("right");
        }

        if (position === "left") {
            await firestore.collection("agents").doc(agentID).collection("children").doc("count").set({
                left: firebase.firestore.FieldValue.increment(1)
            }, { merge: true })
        }

        if (position === "right") {
            await firestore.collection("agents").doc(agentID).collection("children").doc("count").set({
                right: firebase.firestore.FieldValue.increment(1)
            }, { merge: true })
        }



    }


    const handlePhoto = async (id) => {
        //  const [img, setimg] = useState("")
        // let img = {};
        // console.log(id);
        // const userRef = firestore.collection("users").doc(id);
        // await userRef.get().then((e) => {
        //   img = e.data()["photoURL"];
        //   if(img !== undefined){
        //     console.log(img);
        //     return "https://image.shutterstock.com/image-photo/white-transparent-leaf-on-mirror-260nw-1029171697.jpg"
        //   }

        // }
        // );

        // console.log(id);

        //console.log(id);
        let img = {};
        try {
            const userRef = firestore.collection("users").doc(id);
            await userRef.get().then((e) => {
                if (e.data() !== undefined) {
                    setuserPhoto(state => ({
                        ...state,
                        [id]: e.data().photoURL
                    }));
                }
            });
            // console.log(img.photoURL);

        }
        catch (e) {
            console.log('We have the error', e);
        }




    }


    function handlesetUser(u) {
        handleClose();
        console.log(u);
    }
    var i = 0;

    const [leftchild, setleftchild] = useState(-120);
    const [rightchild, setrightchild] = useState(-40);

    const addUserToChat = async (sentID, recieveID) => {
        try {
            // //      const batch = firestore.batch();
            //       const userRef = firestore.collection("users").doc(sentID).collection("contactList").doc(recieveID);
            //       batch.set(userRef, {

            //       });

            //       const userRef1 = firestore.collection("chats").doc(sentID).collection(recieveID);
            //       batch.set(userRef1, {

            //       },{merge:true});
            //       await batch.commit();
            console.log(sentID);
            console.log(recieveID);
            firestore.collection("users").doc(sentID).collection("contactList").doc(recieveID).set({

            })

            firestore.collection("users").doc(recieveID).collection("contactList").doc(sentID).set({

            })


            firestore.collection("chats").doc(sentID).collection(recieveID).doc("0").set({

            })

            firestore.collection("chats").doc(recieveID).collection(sentID).doc("0").set({

            })

        } catch (e) {
            console.log('We have the error', e);
        }

    }
    const nodeSize = { x: 250, y: 200 };
    const foreignObjectProps = { width: nodeSize.x, height: nodeSize.y / 2, x: -120, y: -40 };
    //setuserFound(false);


    //console.log(currentUser);
    console.log(parentName);
    console.log(parentPhoto);

    console.log(orgChart1);
    return (
        <div>
            {currentUser === undefined || orgChart1 === null ? <div style={{
                minHeight: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}><CircularProgress /></div> :

                <>

                    <div
                        // className="chart-container"
                        style={{ width: "100vw", height: "100vh" }}
                        ref={containerRef}
                    >


                        <>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>


                                <div className="col-lg-2 col-md-3 col-xs-1">
                                    <div className="smallwidth" style={{ border: "1px solid black", borderRadius: "50px", backgroundColor: "rgb(255, 255, 255)", color: "rgb(63, 81, 181)" }}>
                                        <div className="MuiButtonBase-root MuiListItem-root MuiListItem-gutters MuiListItem-button" tabindex="0" role="button" aria-disabled="false"><div className="MuiListItemAvatar-root"><div className="MuiAvatar-root MuiAvatar-circle"><img src={parentPhoto} className="MuiAvatar-img" /></div></div><div onClick={() => {
                                            setShowUserDialog(!shouldShowUserDialog)
                                            setaddUserid(referId);
                                            setprofilePhoto(parentPhoto);
                                            setprofileName(parentName);

                                        }} className="MuiListItemText-root"><span className="MuiTypography-root MuiListItemText-primary MuiTypography-body1 MuiTypography-displayBlock"><p style={{ fontSize: "12px" }}>{parentName} </p> </span></div><span class="MuiTouchRipple-root"></span></div>

                                    </div>
                                    <br></br>
                                    {/* <h1>{ window.$leftdata}</h1>
  <h1>{ window.$rightdata}</h1> */}


                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <h5 style={{ color: "rgb(63, 81, 181)" }}>Left : {window.$leftdata} &nbsp; | &nbsp; Right : {window.$rightdata}</h5>
                                    </div>
                                </div>

                            </div>
                            <Tree
                                className="col-xs-12 col-lg-12 col-md-12 chart-container"
                                style={{ maxWidth: "100vw", maxHeight: "100vh" }}
                                data={orgChart1}
                                initialDepth={2}
                                depthFactor="150"
                                scaleExtent={scaleExtent}
                                rootNodeClassName="node__root"
                                branchNodeClassName="node__branch"
                                leafNodeClassName="node__leaf"
                                orientation="vertical"
                                pathFunc={(rd3tProps) => straightPathFunc({
                                    ...rd3tProps,
                                    orient,
                                })}
                                // pathFunc={"step"}
                                translate={translate}
                                // collapsible={false}
                                renderCustomNodeElement={(rd3tProps) =>
                                    RenderForeignObjectNode({
                                        ...rd3tProps,
                                        foreignObjectProps,
                                        setShowDialog,
                                        shouldShowDialog,
                                        getUsersLists,
                                        setUsers,
                                        currentUser,
                                        setaddUserNode,
                                        handlePhoto,
                                        userPhoto,
                                        shouldShowUserDialog,
                                        setShowUserDialog,
                                        handleCloseUser,
                                        setprofileName,
                                        setprofilePhoto,
                                        setaddUserid,
                                        leftchildren,
                                        setleftchildren,
                                        rightchildren,
                                        setrightchildren,
                                        parentBname,
                                        setparentBname,
                                        childrenBname,
                                        setchildrenBname,
                                        agentID



                                    })
                                }
                                onNodeClick={(c) => {
                                    setaddUserNode(c.name);
                                    if (typeof c.children === "undefined") {
                                        //       alert(c.__rd3t.depth);
                                        console.log("we are here ");
                                        setShowDialog(!shouldShowDialog);
                                        console.log(currentUser.id);
                                        getUsersLists(currentUser.id).then((e) => {
                                            setUsers(e);
                                            console.log(e);
                                        });
                                    }
                                    //   setShowDialog(!shouldShowDialog)
                                }}
                            />
                            <FloatingMenu agentID={agentID} />
                        </>


                        {/* { !(Object.keys(orgChart1).length === 0 && orgChart1.constructor === Object) ? ( 
        
       )        
        :
        <>
        <h1 style={{textAlign:"center",color:"rgb(63, 81, 181)"}}> You are not added in MLM Tree  </h1>
        <p style={{textAlign:"center"}}> You Will be shortly added by your referring User</p>
        </>}  */}

                    </div>


                    <Dialog
                        fullWidth="false"
                        maxWidth="xs"
                        open={shouldShowUserDialog}
                        onClose={handleCloseUser}
                        aria-labelledby="max-width-dialog-title"
                    >

                        <DialogContent style={{ justifyContent: "center", display: "flex" }}>
                            <ListItemAvatar >
                                <Avatar style={{ height: '100px', width: '100px' }} alt="Remy Sharp" src={profilePhoto} />
                            </ListItemAvatar>
                        </DialogContent>
                        <ListItemText style={{ textAlign: "center", display: "block", fontSize: '3rem' }}>  <h3>{profileName}</h3></ListItemText>
                        <Button style={{ maxWidth: "100px", margin: "0 auto" }} size="sm" variant="outline-primary" onClick={addUserToChat(currentUser.id, addUserid)} ><Link style={{ textDecoration: "none" }} to="/chats"> <FontAwesomeIcon icon={faCommentDots} /> Message </Link></Button>
                        <DialogActions>
                            <Button onClick={handleCloseUser} color="primary">
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        fullWidth="xl"
                        // maxWidth={maxWidth}
                        open={shouldShowDialog}
                        onClose={handleClose}
                        aria-labelledby="max-width-dialog-title"
                    >
                        <DialogTitle id="max-width-dialog-title">Add a child</DialogTitle>
                        <DialogContent>
                            <List>
                                {users ? (
                                    users.map((e) => (
                                        <div>
                                            {e["agentID"] !== undefined ? !JSON.stringify(orgChart).includes(e["id"]) ?
                                                <ListItem key={i++} button onClick={() => { }}>
                                                    <ListItemAvatar>
                                                        <Avatar alt="Remy Sharp" src={e["photoURL"]} />
                                                    </ListItemAvatar>
                                                    <ListItemText>{e["displayName"]}</ListItemText>

                                                    <ListItemSecondaryAction>
                                                        {
                                                            <IconButton
                                                                edge="end"
                                                                aria-label="add"
                                                                onClick={() => {
                                                                    //handlesetUser(e["id"]);
                                                                    console.log("idddddddddddd" + e["id"]);
                                                                    console.log(e["displayName"]);

                                                                    var newOrgChart = printAllVals(
                                                                        orgChart,
                                                                        e["agentID"],
                                                                        addUserNode,
                                                                        e["displayName"],
                                                                        e["photoURL"],
                                                                        e["id"]
                                                                    );

                                                                    setTree(JSON.stringify(newOrgChart));
                                                                    handleAddchildren(agentID, e["id"], parentBname, childrenBname);
                                                                    // console.log(JSON.parse(JSON.stringify(newOrgChart)))

                                                                    //    console.log(newOrgChart);
                                                                    // setOrgChart(newOrgChart);
                                                                    handleClose();
                                                                }}
                                                            >
                                                                <FontAwesomeIcon icon={faPlus} />

                                                            </IconButton>

                                                        }
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                                : <></> : <></>}




                                        </div>
                                    ))
                                ) : (
                                    <ListItem button>"Loading"</ListItem>
                                )}

                                {
                                    agentID === currentUser.agentID && currentUser.staragentID ? <> Star Agent <ListItem button onClick={() => { }}>
                                        <ListItemAvatar>
                                            <Avatar alt="Remy Sharp" src={currentUser.photoURL} />
                                        </ListItemAvatar>
                                        <ListItemText>{currentUser.displayName}  ( {currentUser.staragentID} )</ListItemText>

                                        <ListItemSecondaryAction>
                                            {
                                                <IconButton
                                                    edge="end"
                                                    aria-label="add"
                                                    onClick={() => {
                                                        //handlesetUser(e["id"]);
                                                        // console.log("idddddddddddd" + e["id"]);
                                                        // console.log(e["displayName"]);

                                                        var newOrgChart = printAllVals(
                                                            orgChart,
                                                            currentUser.staragentID,
                                                            addUserNode,
                                                            currentUser.displayName,
                                                            currentUser.photoURL,
                                                            currentUser.id
                                                        );

                                                        setTree(JSON.stringify(newOrgChart));
                                                        handleAddchildren(agentID, currentUser.id, parentBname, childrenBname);
                                                        // console.log(JSON.parse(JSON.stringify(newOrgChart)))

                                                        //    console.log(newOrgChart);
                                                        // setOrgChart(newOrgChart);
                                                        handleClose();
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={faPlus} />

                                                </IconButton>

                                            }
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    </>
                                        : <></>
                                }



                                {
                                    agentID === currentUser.staragentID && currentUser.crownagentID ? <> Crown Agent <ListItem button onClick={() => { }}>
                                        <ListItemAvatar>
                                            <Avatar alt="Remy Sharp" src={currentUser.photoURL} />
                                        </ListItemAvatar>
                                        <ListItemText>{currentUser.displayName}  ( {currentUser.crownagentID} )</ListItemText>

                                        <ListItemSecondaryAction>
                                            {
                                                <IconButton
                                                    edge="end"
                                                    aria-label="add"
                                                    onClick={() => {
                                                        //handlesetUser(e["id"]);
                                                        // console.log("idddddddddddd" + e["id"]);
                                                        // console.log(e["displayName"]);

                                                        var newOrgChart = printAllVals(
                                                            orgChart,
                                                            currentUser.crownagentID,
                                                            addUserNode,
                                                            currentUser.displayName,
                                                            currentUser.photoURL,
                                                            currentUser.id
                                                        );

                                                        setTree(JSON.stringify(newOrgChart));
                                                        handleAddchildren(agentID, currentUser.id, parentBname, childrenBname);
                                                        // console.log(JSON.parse(JSON.stringify(newOrgChart)))

                                                        //    console.log(newOrgChart);
                                                        // setOrgChart(newOrgChart);
                                                        handleClose();
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={faPlus} />

                                                </IconButton>

                                            }
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    </>
                                        : <></>
                                }
                            </List>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="primary">
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
            }
        </div>

    );
};

const mapStateToProps = ({ user }) => ({
    currentUser: user.currentUser,
});

const straightPathFunc = (linkDatum, orientation) => {
    const { source, target } = linkDatum;
    // console.log(linkDatum);

    const deltaY = target.y - source.y;
    // if(target.x  > 0){
    //   target.x = target.x + 100; 
    // }
    // else if(target.x  < 0){
    //   target.x = target.x - 100;
    // }
    //   console.log("source x-axis + " + source.x);
    //   console.log("source y-axis + " + source.y);
    //   console.log("target x-axis" + target.x);
    // console.log("target y-axis" + target.y);
    return `M${source.x},${source.y} V${source.y + deltaY / 2} H${target.x} V${target.y}`;

};


const RenderForeignObjectNode = ({
    nodeDatum,
    toggleNode,
    foreignObjectProps,
    setShowDialog,
    shouldShowDialog,
    getUsersLists,
    setUsers,
    currentUser,
    setaddUserNode,
    handlePhoto,
    userPhoto,
    shouldShowUserDialog,
    setShowUserDialog,
    handleCloseUser,
    setprofileName,
    setprofilePhoto,
    setaddUserid,
    leftchildren,
    setleftchildren,
    rightchildren,
    setrightchildren,
    parentBname,
    setparentBname,
    childrenBname,
    setchildrenBname,
    agentID

}) => {


    //console.log(parentBname);
    // console.log(nodeDatum);
    // console.log(parentBname);
    // console.log(leftchildren);
    // console.log(rightchildren);

    // if(typeof nodeDatum.children !== "undefined"){
    //   if(typeof parentBname === "undefined"){
    //     setparentBname("0100")
    //   }
    //   else if(nodeDatum.name.substring(0,parentBname.length+1) === parentBname + "0"){
    //     setleftchildren(leftchildren+1); 
    //     // if(nodeDatum.name.substring(0,parentBname.length+1) === parentBname + "0"){
    //     //   setleftchildren(leftchildren+1); 
    //     // }
    //     // if(nodeDatum.name.substring(0,parentBname.length+1) === parentBname + "1"){
    //     //   setrightchildren(rightchildren+1); 
    //     // }
    //   }
    //   else if(nodeDatum.name.substring(0,parentBname.length+1) === parentBname + "1"){
    //     setrightchildren(rightchildren+1); 
    //   }

    // }

    userFound = true;
    if (userPhoto[nodeDatum.id] === undefined) {
        handlePhoto(nodeDatum.id);
    }
    var left = true;
    var xaxis = -40;
    if (nodeDatum.name !== undefined) {
        if (nodeDatum.__rd3t.depth > 0) {

            const l = nodeDatum.name.length;
            //  console.log(nodeDatum.name.substring(l-1,l));
            //console.log(nodeDatum);

            if (nodeDatum.name.substring(l - 1, l) === "0") {

                if (nodeDatum.id === undefined) {
                    xaxis = xaxis
                }
                else {
                    xaxis = xaxis;
                }
            }
            else if (nodeDatum.name.substring(l - 1, l) === "1") {
                left = false;
                xaxis = xaxis + 5;
            }
        }
    }

    return (
        <g >

            {/* <circle
       
       style={{  backgroundColor: "#3f51b5",}}
        onClick={(c) => {
          console.log(nodeDatum);
          setaddUserNode(nodeDatum.name);
          if (typeof nodeDatum.children === "undefined") {
             //      alert(c.__rd3t.depth);
            setShowDialog(!shouldShowDialog);
            getUsersLists(currentUser.id).then((e) => {
              setUsers(e);
              console.log(e);
            });
          }
        }}
        r={20}
      ></circle> */}
            {typeof nodeDatum.children === "undefined" ?
                <foreignObject width="70" height="100" x={xaxis} y="-40" >

                    <div
                        style={{ border: "1px solid black", borderRadius: "50px", backgroundColor: "#FFFFFF", color: "#3f51b5" }}
                    >

                        <ListItem

                            button

                            onClick={(c) => {
                                console.log(nodeDatum);

                                setaddUserNode(nodeDatum.name);
                                if (typeof nodeDatum.children === "undefined") {
                                    //      alert(c.__rd3t.depth);
                                    setchildrenBname(nodeDatum.name);
                                    setShowDialog(!shouldShowDialog);
                                    getUsersLists(agentID).then((e) => {
                                        setUsers(e);
                                        console.log(e);
                                    });
                                }
                            }}
                        >
                            <ListItemAvatar>
                                <Avatar
                                    src={
                                        "https://cdn4.iconfinder.com/data/icons/vectory-bonus-3/40/button_add-512.png"
                                    }
                                ></Avatar>
                            </ListItemAvatar>




                        </ListItem>
                    </div>
                </foreignObject> : (
                    <>

                        {/* <div>
{nodeDatum.name === parentBname ? <p>left : {leftchildren} Right : {rightchildren}</p> : <p></p> } 
</div>  */}

                        <foreignObject width="70" height="100" x={xaxis} y="-40"    >
                            <div
                                style={{ borderRadius: "50px", backgroundColor: "#FFFFFF", color: "#3f51b5" }}
                            >
                                <ListItem

                                    button

                                >

                                    {left ? <>
                                        {/* <ListItemText  style={{ border: "1px solid black",borderRadius:"50px", width:"250px", backgroundColor: "#FFFFFF",color:"#3f51b5",padding:"10px",textAlign:"center" }}><p style={{fontSize:"12px",marginTop:"10px",textAlign:"center" }}>{nodeDatum.displayName} <br></br>  </p></ListItemText>
  <ListItemText> &nbsp; &nbsp;  </ListItemText> */}
                                        <ListItemAvatar >
                                            <Avatar onClick={toggleNode}
                                                // src={
                                                //   "https://www.humanesociety.org/sites/default/files/styles/1240x698/public/2018/08/kitten-440379.jpg?h=c8d00152&itok=1fdekAh2"
                                                // }
                                                src={nodeDatum.photoURL}
                                            />
                                            <text fill="black" strokeWidth="1" x="60" y="5" onClick={() => {
                                                if (parentBname !== nodeDatum.name) {
                                                    setShowUserDialog(!shouldShowUserDialog);
                                                    setaddUserid(nodeDatum.userId);
                                                    setprofilePhoto(nodeDatum.photoURL);
                                                    setprofileName(nodeDatum.displayName);
                                                    // setaddUserNode(nodeDatum.name);
                                                }
                                            }}>
                                                {nodeDatum.displayName}
                                            </text>
                                        </ListItemAvatar>
                                    </> : <>

                                        <ListItemAvatar>
                                            <Avatar onClick={toggleNode}
                                                // src={
                                                //   "https://www.humanesociety.org/sites/default/files/styles/1240x698/public/2018/08/kitten-440379.jpg?h=c8d00152&itok=1fdekAh2"
                                                // }
                                                src={nodeDatum.photoURL}
                                            />
                                            <text fill="black" strokeWidth="1" x="60" y="5" onClick={() => {
                                                if (parentBname !== nodeDatum.name) {
                                                    setShowUserDialog(!shouldShowUserDialog);
                                                    setaddUserid(nodeDatum.id);
                                                    setprofilePhoto(nodeDatum.photoURL);
                                                    setprofileName(nodeDatum.displayName);
                                                    // setaddUserNode(nodeDatum.name);
                                                }
                                            }}>{nodeDatum.displayName}</text>
                                        </ListItemAvatar>
                                        {/* <ListItemText>  </ListItemText>
               <ListItemText   style={{ border: "1px solid black",borderRadius:"50px",width:"300px", backgroundColor: "#FFFFFF",color:"#3f51b5",padding:"10px",textAlign:"center",  display: "flex",
    flexDirection: "column",
    justifyContent: "center" }}><p  style={{fontSize:"12px",textAlign:"center",marginTop:"10px"}}>{nodeDatum.displayName}  <br></br> {nodeDatum.name === parentBname ? `left : ${leftchildren} Right : ${rightchildren}` : "" }  </p></ListItemText>  */}

                                    </>}

                                </ListItem>
                            </div>
                        </foreignObject>

                    </>
                )}
        </g>
    );

};

export default connect(mapStateToProps, null)(MyAgentTree);
import React from "react";
import { Paper, Typography,Grid} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';



const style = makeStyles(theme=>({
    root: {
      border: `8px solid ${theme.palette.common.white}`,
      width: '50rem', 
      
      marginLeft:theme.spacing.unit * 23,
      
      marginRight:theme.spacing.unit * 10,
      [theme.breakpoints.down("xs")]: {
        // fontSize: 10,
        marginRight:'1rem',
        width: '20rem',
        marginLeft:'1rem',
      },
    
      marginTop:90,
      padding: "18px 0 0",
      background: `rgba(255,255,255,0.9)`,
      boxShadow: [
        `0px 16px 26px -10px ${theme.palette.primary.main}99`,
        theme.shadows[15]
      ]
    },
    griditem: {
        padding: theme.spacing.unit * 3,
        height: "100%",
        maxWidth: 450,
        margin: "0 auto"
        // padding: theme.spacing(1),
      },
      griditem1: {
        padding: theme.spacing(1),
      },
    navigation: {
      width: 110,
      fontSize: 12,
      [theme.breakpoints.down("xs")]: {
        fontSize: 10,
        width: 90
      }
    },
    prevBtn: {
      color: theme.palette.grey[700],
      background: theme.palette.common.white,
      boxShadow: theme.shadows[5]
    }
  }));

const Payments = () => {
    const classes = style();
    return(
    <div className="container">    
    <div className="col-12 col-sm-6">
    <Paper  style={{}} elevation={1} className={classes.root}>
      <Typography
        variant="h4"
        gutterBottom
        color="primary"
        style={{ padding: "0 8px", display: "flex",flexDirection: "column",alignItems: "center" }}
      >
        <div className="col-12 col-sm-6">
        Bank Details
        </div>
      </Typography>
      <Grid container justify="center" alignItems="center" className={classes.griditem}>
        <Grid container direction="row" alignItems="center" alignContent="center">
            <Grid item xs={7} className={classes.griditem1}>
                <Typography color="black">
                    {/* <div className="col-12 col-sm-6"> */}
                    Name on Account:
                    {/* </div> */}
                    
                </Typography>
            </Grid>
            <Grid item xs={9}>
                {/* value */}
            </Grid>
        </Grid>{" "}
        <Grid container direction="row" alignItems="center" alignContent="center">
            <Grid item xs={7} className={classes.griditem1}>
                <Typography color="black">
                    Account No :
                </Typography>
            </Grid>
            <Grid item xs={9}>
                {/* value */}
            </Grid>
        </Grid>{" "}
        <Grid container direction="row" alignItems="center" alignContent="center">
            <Grid item xs={7} className={classes.griditem1}>
                <Typography color="black">
                    Account Type :
                </Typography>
            </Grid>
            <Grid item xs={9}>
                {/* value */}
            </Grid>
        </Grid>{" "}
        <Grid container direction="row" alignItems="center" alignContent="center">
            <Grid item xs={7} className={classes.griditem1}>
                <Typography color="black">
                    Bank Name :
                </Typography>
            </Grid>
            <Grid item xs={9}>
                {/* value */}
            </Grid>
        </Grid>{" "}
        <Grid container direction="row" alignItems="center" alignContent="center">
            <Grid item xs={7} className={classes.griditem1}>
                <Typography color="black">
                    IFSC Code :
                </Typography>
            </Grid>
            <Grid item xs={9}>
                {/* value */}
            </Grid>
        </Grid>{" "}
        <Grid container direction="row" alignItems="center" alignContent="center">
            <Grid item xs={7} className={classes.griditem1}>
                <Typography color="black">
                   Status  :
                </Typography>
            </Grid>
            <Grid item xs={9}>
                {/* value */}
            </Grid>
        </Grid>{" "}
      </Grid>

      
    </Paper>
    </div>
    </div>  
    );
}

export default Payments;




import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import './profile.css';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import firebase, { firestore } from "../../firebase/firebase.utils";
import { FacebookIcon, TwitterIcon, WhatsappIcon, TelegramIcon, EmailIcon, RedditIcon, TumblrIcon } from "react-share";
import {
  FacebookShareButton,
  GooglePlusShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  PinterestShareButton,
  VKShareButton,
  OKShareButton,
  RedditShareButton,
  TumblrShareButton,
  LivejournalShareButton,
  MailruShareButton,
  ViberShareButton,
  WorkplaceShareButton,
  EmailShareButton,
} from 'react-share';
import { Card } from "react-bootstrap";
import { CircularProgress } from "@material-ui/core";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
const Myprofile = ({ currentUser }) => {

  //  const user = useSelector(state => state.user.currentUser.id);
  var url = ""
  if (currentUser !== undefined) {
    url = `https://kmcsociety.com/referral/${currentUser.id}`;
  }
  const shareText = 'Join Now!'
  const text = 'Join KMC!'
  console.log(currentUser);

  const myDetails = {};
  const users = "users";
  const details = "details";
  const personalDetails = "personalDetails";
  const [promises, setpromises] = useState([]);
  const [show, setShow] = useState(false);

  const [displayAadhar, setdisplayAadhar] = useState("block");

  const [aadharData, setaadharData] = useState([]);
  //  let promises = [];
  const [bankData, setbankData] = useState([]);

  function toDateTime(input) {
    console.log(input.seconds);
    var curdate = new Date(null);
    curdate.setTime(input.seconds * 1000);

    return curdate.toLocaleDateString('en-GB');
  }


  useEffect(async () => {
    console.log("hello");
    if (currentUser !== undefined) {
      const userRef = firestore.collection(users).doc(currentUser.id).collection(details).doc(personalDetails);

      await userRef.get().then((snapshot) => {
        setpromises(snapshot.data())
        firestore.collection("users").doc(currentUser.id).collection("verified").doc("aadharVerifiedDetails").get().then((result) => {
          setaadharData(result.data());
          firestore.collection("users").doc(currentUser.id).collection("details").doc("bankDetails").get().then((bankresult) => {
            setbankData(bankresult.data());
            setShow(true);

          })
        }).catch((err) => {

        });
      });


      //  await firestore.collection("users").doc(currentUser.id).collection("verified").doc("aadharVerifiedDetails").get().then((result) => {
      //    setaadharData(result.data());
      //  }).catch((err) => {

      //  });
      //     console.log(snapshot.data());
      // snapshot.docs.map(async (e) => {
      // //  setpromises(e.data());
      //   setpromises(oldArray => [...oldArray, e.data()]);
      // });
    }
    return () => {

    }
  }, [currentUser])
  //console.log(promises);

  const [checkA, setcheckA] = useState(false);
  const handleChange = (event) => {
    setcheckA(event.target.checked);
    setdisplayAadhar(displayAadhar === "block" ? "none" : "block");
  };
  // console.log(aadharData.document.data.dob);
  return (
    <> {
      show ? (currentUser.kycVerified ? (
        <div className="col-lg-12 col-md-12 col-xs-12 animated fadeIn" >
          <div className="card">
            <div className="card-body">
              <div className="avatar">
                <img
                  src={currentUser.photoURL}
                  className="card-img-top"
                  alt=""
                />
              </div>
              <h3 className="card-title" style={{ textAlign: "center", color: "rgb(63, 81, 181)" }} >
                {aadharData.document.data.full_name}
              </h3>
              <div className="card">
                <div className="card-body">

                  <h3 style={{ textAlign: "center", color: "rgb(63, 81, 181)" }}>Personal Details</h3>
                  <div class="row mt-5">
                    <p className="card-text col-lg-6">DOB : {aadharData.document.data.dob}</p>
                    <p className="card-text col-lg-6">Gender : {aadharData.document.data.gender}</p>
                    <p className="card-text col-lg-6">Address Lane 1 / Street : {promises.addresslane1}</p>
                    <p className="card-text col-lg-6">Address Lane 2 / Street: {promises.addresslane2}</p>
                    <p className="card-text col-lg-6">Pincode : {promises.pincode}</p>
                    <p className="card-text col-lg-6">City / Village: {promises.city}</p>
                    <p className="card-text col-lg-6">District : {promises.district}</p>
                    <p className="card-text col-lg-6">State : {promises.state}</p>
                    <p className="card-text col-lg-6">Phone No : {promises.phoneNo}</p>
                  </div>

                </div>


                <div className="card-body" style={{ display: `${displayAadhar}` }}>

                  <h3 style={{ textAlign: "center", color: "rgb(63, 81, 181)" }}>Aadhar Address Details</h3>
                  <div class="row mt-5">

                    <p className="card-text col-lg-6">Address Lane 1 : {aadharData.document.data.address.house}</p>
                    <p className="card-text col-lg-6">Address Lane 2 : {aadharData.document.data.address.street}</p>
                    <p className="card-text col-lg-6">Location : {aadharData.document.data.address.loc}</p>
                    <p className="card-text col-lg-6">Landmark : {aadharData.document.data.address.landmark}</p>
                    <p className="card-text col-lg-6">Pincode : {aadharData.document.data.zip}</p>
                    <p className="card-text col-lg-6">District : {aadharData.document.data.address.dist}</p>
                    <p className="card-text col-lg-6">State : {aadharData.document.data.address.state}</p>
                  </div>

                </div>



                <div className="card-body">

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkA}
                        onChange={handleChange}
                        name="checkA"
                        color="primary"
                      />
                    }
                    label="Aadhar Address is same as Current Address"
                  />

                  <h3 style={{ textAlign: "center", color: "rgb(63, 81, 181)" }}>Bank Details</h3>
                  <div class="row mt-5">

                    <p className="card-text col-lg-6">Bank Account No : {"X".repeat(bankData.accountNo.length - 3)} {bankData.accountNo.slice(bankData.accountNo.length - 3)}</p>
                    <p className="card-text col-lg-6">Account Type : {bankData.accountType}</p>
                    <p className="card-text col-lg-6">Bank Name : {bankData.bankName}</p>
                    <p className="card-text col-lg-6">IFSC Code : {bankData.ifscCode}</p>
                  </div>

                </div>
                <div className="card-body">

                  <h3>Share</h3>
                  <div class="row mt-5">
                    <WhatsappShareButton className="col-lg-2 col-md-2 col-xs-6 mb-2" url={url}> <WhatsappIcon size={32} round /> Whatsapp
      </WhatsappShareButton>

                    <FacebookShareButton className="col-lg-2 col-md-2 col-xs-6 mb-2" url={url}> <FacebookIcon size={32} round /> Facebook
      </FacebookShareButton>

                    <TwitterShareButton className="col-lg-2 col-md-2 col-xs-6 mb-2 mr-3" url={url}> <TwitterIcon size={32} round /> Twitter
      </TwitterShareButton>
                    <TelegramShareButton className="col-lg-2 col-md-2 col-xs-6 mb-2" url={url}> <TelegramIcon size={32} round /> Telegram
      </TelegramShareButton>
                    <EmailShareButton className="col-lg-2 col-md-2 col-xs-6 mb-2 mr-4" url={url}> <EmailIcon size={32} round /> Email
      </EmailShareButton>

                  </div>

                </div>
              </div>





            </div>
          </div>
        </div>) : <div style={{
          minHeight: "80vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}> <Card style={{ width: '70vw' }}>
          <Card.Body>
            <Card.Title style={{ color: "rgb(63, 81, 181)", fontSize: "2rem" }}>             <Link to="/kyc" className="smalltext" style={{ textDecoration: "none" }}>   Update Your KYC{" "}</Link>
            </Card.Title>
          </Card.Body>
        </Card> </div>) : <div style={{
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}><CircularProgress /></div>}
    </>
  )
};

//export default Myprofile;

const mapStateToProps = state => (
  {
    currentUser: state.user.currentUser
  }
)

export default connect(mapStateToProps)(Myprofile);

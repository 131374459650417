import React, { useState } from "react";
import {
  withStyles,
  Grid,
  ButtonBase,
  FormControlLabel,
  Radio,
  Tooltip,
  TextField,
  Button,
  Input,

} from "@material-ui/core";
import classNames from "classnames";
import * as FaIcons from 'react-icons/fa';

const style = theme => {
  // const active = {
  //   filter: "grayscale(0)",
  //   border: `3px solid ${theme.palette.primary.main}`
  // };
  return {

    root: {
      padding: theme.spacing.unit * 3,
      height: "100%",
      maxWidth: 450,
      margin: "0 auto"
    },
    labelRoot: {
      fontSize: 15,
      [theme.breakpoints.down("xs")]: {
        fontSize: 13.5,
      }
    },

    error: {
      color: "red",
      justify: "center",
      alignItems: "center"
    },
    mainClass: {
      height: 106,
      width: 106,
      borderRadius: "50%",
      boxShadow: [
        `0px 16px 26px -10px ${theme.palette.primary.main}a5`,
        theme.shadows[5]
      ],
      margin: 8,
      color: theme.palette.primary.light,
      border: `3px solid ${theme.palette.common.white}`,
      filter: "grayscale(1)",
      transition: theme.transitions.create(),
      "&:active": {
        filter: "grayscale(0)",
        border: `3px solid ${theme.palette.primary.main}`
      },
      "&:hover": {
        filter: "grayscale(0)",
        border: `3px solid ${theme.palette.primary.main}`
      }
    },
    mainLblClass: {
      color: theme.palette.grey[700],
      textTransform: "uppercase",
      letterSpacing: 1,
      marginBottom: 16
    },
    //class for checked item
    checked: {
      filter: "grayscale(0)",
      border: `3px solid ${theme.palette.primary.main}`
    },
    LblChecked: { color: theme.palette.primary.main },
    itemInner: {
      height: 66,
      width: 66,
      borderRadius: "50%",
      backgroundSize: "cover",
      backgroundPosition: "center center",
      boxShadow: theme.shadows[9]
    },
    input: {
      height: 106,
      width: 106,
      position: "absolute",
      top: -6,
      left: -6,
      opacity: 0
    },
    button: {
      width: '25vw',
      fontSize: 12,
      [theme.breakpoints.down("xs")]: {
        fontSize: 10,
        width: '70vw'
      },
    },
    navigation: {
      width: 300,
      fontSize: 12,
      [theme.breakpoints.down("xs")]: {
        fontSize: 10,
        width: 250
      },

    },

  };
};

const PanDetails = ({ classes, error, panNo, setpanNo, selectedpanFile, setSelectedpanFile }) => {

  const [ispanFilePicked, setIspanFilePicked] = useState(false);

  const numbers = /[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
  const handlepanNoChange = e => setpanNo(e.target.value);
  const changepanHandler = (event) => {
    setSelectedpanFile(event.target.files[0]);
  };
  //    console.log(selectedpanFile);
  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      className={classes.root}
    >
      <Grid container direction="row" alignItems="center" alignContent="center">

        <Grid item xs={1}>

        </Grid>
        <Grid item xs={10}>
          <TextField
            name="name"
            value={panNo}
            fullWidth
            margin="normal"
            label="Enter your PAN Number"
            variant="outlined"
            onChange={handlepanNoChange}
            InputLabelProps={{
              classes: {
                root: classes.labelRoot
              }
            }}
          />
        </Grid>
        <Grid item xs={12} className={classes.error}>{panNo ? "" : error.panNo} </Grid>
        <Grid item xs={12} className={classes.error}>{panNo.match(numbers) ? "" : error.panNolength} </Grid>


        <div className="col-lg-12 col-md-12 col-xs-12">
          <input
            color="primary"
            accept="image/*,application/pdf"
            type="file"
            onChange={changepanHandler}
            id="icon-button-file"
            style={{ display: 'none', }}
          />
          <label htmlFor="icon-button-file">
            <Button
              variant="contained"
              component="span"
              className={classes.button}
              size="large"
              color="primary"
            >
              <FaIcons.FaUpload />
                    &nbsp; &nbsp;
                Upload Your PAN Card &nbsp; &nbsp;
          </Button>
          </label>
        </div>
        <br></br>
        <div>

        </div>
        <span className="col-lg-11">
          {selectedpanFile ? selectedpanFile.name : "No file Chosen"}
        </span>
        <Grid item xs={12} lg={12} className={classes.error}>{selectedpanFile ? "" : error.selectpanfile} </Grid>
        <Grid item xs={12} className={classes.error}>{error.panVerify} </Grid>

      </Grid>
      {/* <Button
                color="primary"
                className={classes.navigation}
                variant="contained"
               >

                    <FaIcons.FaUpload  />
                    &nbsp; &nbsp;
                Upload Your PAN Card
                
              </Button>    */}

    </Grid>
  );
};
export default withStyles(style)(PanDetails);

import "./ranks.scss";
import Stepper from "./stepper";
import React, { useState, useEffect } from "react";
import { Col, Row, Button } from 'react-bootstrap';
import { connect } from "react-redux";
import firebase, {
  getUsersLists,
  getTree,
  setTree,
  firestore,
} from "../../firebase/firebase.utils";
//import { Button } from "bootstrap";

window.$leftdata = 0;
window.$rightdata = 0;

const Ranks = ({ currentUser, location }) => {

  console.log(currentUser);

  var agentID = "";


  if (location.state !== undefined) {
    agentID = location.state.agentID;
  }

  console.log(agentID);

  const [currentStep, setcurrentStep] = useState(1);
  const [orgChart1, setOrgChart1] = useState(null);
  const [parentBname, setparentBname] = useState("");

  useEffect(() => {

    console.log("sdgujchjskdndskjdcnkm");
    getTree().on("value", (e) => {
      console.log(e.val());
      var s =
        e.val() || '{"name":"Root","id":"RkWO4Efpplb6SFpFHm88wvCdJZ53","displayName":"KMC","children":[{"name":"0"},{"name":"1"}]}';

      //  console.log(orgChart)

      // setOrgChart(JSON.parse(s));
      console.log("Real time data");
      console.log(JSON.parse(s));



      //get child
      if (currentUser) {

        firestore.collection("agents").doc(agentID).get().then((result) => {
          setcurrentStep(result.data().rank.rank || 1);

        })
        if (s.includes(agentID)) {


          var startindex = s.indexOf(agentID);
          var parentstartindex1 = s.indexOf(agentID) - 9;
          var lastindex = s.indexOf(agentID);
          var parentstartindex = s.indexOf(agentID);
          var count = 0;
          var setLast = true;
          var setFirst = true;
          var setparentFirst = true;
          var check = false;
          var i = startindex + 1;
          var j = startindex - 1;

          console.log(i);
          if (s !== "{}") {
            while (setFirst) {
              if (s.charAt(j) === '{') {
                startindex = j;
                setFirst = false;
              }
              j--;
            }

            while (setLast) {

              if (s.charAt(i) === '[') {
                console.log(s.charAt(i));
                count++;
                check = true;
              }
              else if (s.charAt(i) === ']') {
                count--;
              }

              if (check) {
                if (count === 0) {
                  lastindex = i + 1;
                  setLast = false;
                }
              }

              // if(i === 250){
              //   start = false;
              // }
              i++;
              //console.log(i);
            }
            //   var y = startindex;
            //   var parentstartindex1 = startindex;
            //   while(setparentFirst1){
            //     console.log("fggh");
            //     if(s.charAt(y) === '"' && s.charAt(y+1) === 'i' && s.charAt(y+2) === 'd' && s.charAt(y+3) === '"'){
            //       parentstartindex1 = y;
            //       setparentFirst1 = false; 
            //   }
            //   y++;
            // }
            console.log(s.substring(startindex + 9, parentstartindex1));
            var x = s.substring(startindex + 9, parentstartindex1);
            var setparentFirst1 = true;
            var k = s.indexOf('"' + x + '"');
            while (setparentFirst) {
              if (s.charAt(k - 1) === '"' && s.charAt(k) === 'i' && s.charAt(k + 1) === 'd' && s.charAt(k + 2) === '"') {
                parentstartindex = k;
                setparentFirst = false;
              }
              k++;
            }
          }
          console.log(startindex);
          console.log(parentstartindex1);

          console.log(lastindex);
          console.log(parentstartindex);
          console.log(s.substring(parentstartindex + 5, parentstartindex + 33));
          // setparentId(s.substring(parentstartindex+5,parentstartindex+33));

          var myData = s.substring(startindex, lastindex) + '}';
          console.log(myData);
          setOrgChart1(JSON.parse(myData));
          var x = JSON.parse(myData)["name"]
          console.log(x)
          setparentBname(x);
          // setleftChildern(0);
          // setrightChildern(0);
          window.$leftdata = 0;
          window.$rightdata = 0;
          checkLeftRight(JSON.parse(myData), x, 0, 0);



          try {


            const requestOptions = {
              method: 'POST',
              //  mode: 'no-cors',
              headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*'
              },
              body: JSON.stringify({
                leftData: window.$leftdata,
                rightData: window.$rightdata,
                userId: agentID
              })
            };
            let res = fetch('https://us-central1-react-test-5e32f.cloudfunctions.net/checkRank', requestOptions);
            let doc = res.json().catch(e => { });

            console.log(doc);
            //console.log("gdnkdmd");

          } catch (error) {
            console.log(error);
          }


          //  checkLeftRight(JSON.parse(myData), x, 0, 0);

        }
      }



    });
    return () => { };
  }, [currentUser]);



  const checkLeftRight = (obj, parentname, leftChildern, rightChildern) => {
    console.log(obj);

    console.log(leftChildern);
    console.log(rightChildern);
    var count = true;

    console.log(obj["name"])
    if (typeof obj["children"] !== "undefined" && obj["name"] !== parentname) {
      console.log(obj["name"])
      if (obj["name"].substring(0, parentname.length + 1) === (parentname + "0")) {
        console.log(leftChildern);
        window.$leftdata++;
        console.log("left");
      }


      if (obj["name"].substring(0, parentname.length + 1) === (parentname + "1")) {
        console.log(rightChildern);
        window.$rightdata++;
        console.log("right");
      }
    }
    for (let k in obj) {
      // console.log(obj[k]);

      if (typeof obj[k] === "object") {
        checkLeftRight(obj[k], parentname, leftChildern, rightChildern);
      }
      if (obj["name"]) {

        //        if(typeof obj["children"] !== "undefined" && count && obj["name"] !== parentname){



        //   console.log(obj["name"]);
        //   // console.log(leftChildern);
        //   // console.log(rightChildern);

        // if(obj["name"].substring(0,parentname.length+1) === (parentname + "0")){
        //   console.log(leftChildern);
        //   setleftChildern(leftChildern++); 
        //   console.log("left");
        // }


        // if(obj["name"].substring(0,parentname.length+1) ===( parentname + "1")){
        //   console.log(rightChildern);
        //   setrightChildern(rightChildern+1); 
        //   console.log("right");
        // }


        // count = false;


        //        }
        //      console.log(obj["name"]);
      }


    }

    return JSON.parse(JSON.stringify(obj));
  }
  // useEffect(() => {


  //   return () => {
  //     cleanup
  //   }
  // }, [input])




  const handleClick = async () => {
    //    const { currentStep } = this.state;
    //    alert(window.$leftdata)
    alert(orgChart1)
    console.log(orgChart1);
    console.log(parentBname);


  }



  return (
    <>

      <h3 style={{ textAlign: "center" }}>My Ranks</h3>

      <div className="stepper-container-vertical">
        <Stepper
          direction="vertical"
          currentStepNumber={currentStep - 1}
          steps={stepsArray}
          incentives={stepsArray1}
          stepColor="#ee5253"
        />
        {/* <Button onClick={() => handleClick()}> Get My Rank</Button> */}
      </div>





      {/* <div className="buttons-container">
        <button onClick={() => handleClick()}>Previous</button>
         <button onClick={() => handleClick("next")}>Next</button> 
      </div> */}
    </>
  );
}

//export default Ranks;

const stepsArray = [
  // "Create your account",
  "Agent",
  "Senior Agent",
  "Development Officer",
  "Executive",
  "Senior Executive",
  "Coordinator",
  "Organiser",
  "Team General Manager(T.G.M)"

];

const stepsArray1 = [
  // "Create your account",
  "",
  "Golden Pin",
  "Rs 12000",
  "Rs 60,000",
  "Rs 1,50,000",
  "Rs 3,00,000",
  "Rs 5,10,000",
  "Rs 21,00,000"

];



const mapStateToProps = ({ user }) => ({
  currentUser: user.currentUser,
});

export default connect(mapStateToProps, null)(Ranks);
import React, { useState } from 'react';
import './Sidebarchat.css';
import { Avatar } from '@material-ui/core';
import {useEffect} from 'react';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { firestore as db} from '../../firebase/firebase.utils';
import {Link} from "react-router-dom";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";


function Sidebarchat({ id, addnewChat,input,currentUser}) {


    let history = useHistory();

console.log(input);
    const [seed,setSeed] = useState("");
    const [messages,setMessages] = useState("");
    const [name,setname] = useState("");
    const[photo,setphoto] = useState("");
useEffect(() => {

    setSeed(Math.floor(Math.random() * 5000));

},[]);

useEffect(() =>{
    if(currentUser){
        if(id){
            db.collection("chats")
            .doc(currentUser.id)
            .collection(id)
            .onSnapshot
            ((snapshot) => (
                console.log(snapshot.docs.length),
                setMessages(snapshot.docs[snapshot.docs.length-1].data().text)

             //   snapshot.docs.map((doc) => console.log(doc.data()))
            ))

            db.collection("users").doc(id).get().then((result) => {
                setname(result.data().displayName)
                setphoto(result.data().photoURL)
            }).catch((err) => {
                
            });
        }
    }
},[id]);

console.log(messages);
const createChat = () => {
    // const roomName = prompt('Enter room name for the chat');

    // if (roomName) {
    //     //to do
    //     db.collection("rooms").add({
    //         name: roomName,

    //     });
    // }
    history.push("/tree");
};

    return !addnewChat ? (
        name.toLowerCase().includes(input.toLowerCase()) ?
(        <Link to={`/rooms/${id}`} style={{textDecoration : "none"}}>

<div className="sidebarChat">
        <Avatar src= { photo ? photo : `https://www.yashodahospital.org/wp-content/uploads/2018/09/no_image.png`}/>

        <div className="sidebarChat__info">

            <h2 className="sidebarChat__heading">{name}</h2>
            <p>{messages}</p>

        </div>
            
        </div>

        </Link>
 )       : <></>
) : (
        <div onClick={createChat}
        className="sidebarChat__addChat">
            <h2>Add new chat</h2>
            <AddCircleIcon className="addchaticon"/>

            
        </div>

    );
}


const mapStateToProps = ({ user,referred }) => ({
    currentUser: user.currentUser,
    referredId: referred.referredId,
  });

//export default Chat
export default connect(mapStateToProps, null)(Sidebarchat);
import React, { useState } from "react";
import PropTypes from "prop-types";
import { withStyles, FormControlLabel, Grid, Switch, TextField, GridList, GridListTileBar } from "@material-ui/core";
import { curl, straight, color, haircut, shampoo } from "./iconPaths";
const style = theme => ({
  root: {
    padding: theme.spacing.unit * 3,
    height: "100%",
    maxWidth: 450,
    margin: "0 auto"
  },
  labelRoot: {
    fontSize: 15,
    [theme.breakpoints.down("xs")]: {
      fontSize: 13.5,
    }
  },


  error: {
    color: "red"
  },
  row: { borderBottom: `1px solid ${theme.palette.grey[100]}` },
  icon: {
    fill: theme.palette.grey[700],
    width: 28,
    height: 28
  }
});
const AadharVerification = ({ classes, error, aadharotp, setaadharotp }) => {


  const handleOTP = e => setaadharotp(e.target.value);



  return (

    <Grid
      container
      justify="center"
      alignItems="center"
      className={classes.root}
    >

      <Grid container direction="row" alignItems="center" alignContent="center"  >
        <Grid item xs={2}>
        </Grid>
        <Grid item xs={8}>
          <TextField
            name="aadharotp"
            value={aadharotp}
            fullWidth
            margin="normal"
            label="Enter OTP"
            variant="outlined"
            onChange={handleOTP}
            InputLabelProps={{
              classes: {
                root: classes.labelRoot
              }
            }}
          />
          <Grid item xs={2}>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.error}>{aadharotp ? "" : error.otp} </Grid>
        <Grid item xs={12} className={classes.error}>{error.aadharOTP} </Grid>

      </Grid>
    </Grid>

  );
};

export default withStyles(style)(AadharVerification);

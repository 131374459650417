import React, { useState } from 'react';
import CustomButton from '../custom-button/custom-button.component';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import './unregistered-homepage-styles.scss';
import { Row, Col, Button } from "react-bootstrap";
import { auth, signInWIthGoogle, createUserProfileDocument, firestore } from "../../firebase/firebase.utils";
import { useDispatch, useSelector } from "react-redux";
import { setReferredId } from "../../redux/referred/referred.actions";
import firebase from "../../firebase/firebase.utils";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


import FilledInput from '@material-ui/core/FilledInput';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import FormComponent from './form';
import './form.css';
//"../../redux/referred/referred.actions";
import Modal from 'react-bootstrap/Modal'
import InputGroup from 'react-bootstrap/InputGroup'
import img from "../../assets/kmc.png"
export const UnregisteredHomepage = ({ currentUser, match }) => {


    const [phone, setphone] = useState("");
    const [email, setemail] = useState("");
    const [displayName, setdisplayName] = useState("");
    const [photoURL, setphotoURL] = useState("https://www.yashodahospital.org/wp-content/uploads/2018/09/no_image.png");
    const [show, setshow] = useState(false);

    const [show1, setshow1] = useState(false);

    const [show3, setShow3] = useState(false);


    const [verificationId, setverificationId] = useState("");
    const [isLogin, setisLogin] = useState(false)

    const [otp, setotp] = useState("")
    const handleClose = () => setShow3(false);
    const handleShow = () => setShow3(true);


    console.log(currentUser);
    console.log(match);
    const history = useHistory();

    function handleClick() {
        history.push('/signin');
    }

    const otpsubmit = () => {
        //        setShow3(true);

        var credential = firebase.auth.PhoneAuthProvider.credential(verificationId, otp);

        if (isLogin) {

            firebase.auth().signInWithCredential(credential)
                .then(function (userCredential) {
                    console.log(userCredential);
                });


        } else {

            firebase.auth().signInWithCredential(credential)
                .then(function (userCredential) {
                    console.log(userCredential);
                    createUserProfileDocument(userCredential.user, { email, displayName, photoURL, phone });
                    console.log(userCredential.user);
                });


        }



    }

    const otpsubmit1 = () => {
        //        setShow3(true);
        return "123456";
    }


    const onsubmit = () => {

        firestore.collection("users").where("phone", "==", phone).get().then((result) => {
            console.log(result.docs.length)
            if (result.docs.length) {

                setshow(true);
                var applicationVerifier = new firebase.auth.RecaptchaVerifier(
                    'recaptcha1');
                var provider = new firebase.auth.PhoneAuthProvider();
                var number = "+91" + phone;
                provider.verifyPhoneNumber(number, applicationVerifier)
                    .then(function (verificationIddata) {
                        //var verificationCode = prompt('Please enter the verification code', '');
                        // var verificationCode = "";

                        //  var verificationCode = Number(prompt("Please enter your name", "123456"));

                        setShow3(true);
                        setverificationId(verificationIddata);
                        setisLogin(true);

                    })




            }
            else {
                alert("Account doesn't Exist \nPlease Signup to register.");
                document.getElementById("reg-log").checked = true;
            }
        })

    }



    const onsignupsubmit = () => {

        firestore.collection("users").where("phone", "==", phone).get().then((result) => {
            console.log(result.docs.length)
            if (result.docs.length === 0) {

                setshow(true);
                var applicationVerifier = new firebase.auth.RecaptchaVerifier(
                    'recaptcha');
                var provider = new firebase.auth.PhoneAuthProvider();
                var number = "+91" + phone;
                provider.verifyPhoneNumber(number, applicationVerifier)
                    .then(function (verificationIddata) {
                        //var verificationCode = prompt('Please enter the verification code', '');
                        // var verificationCode = "";

                        //  var verificationCode = Number(prompt("Please enter your name", "123456"));

                        setShow3(true);
                        setverificationId(verificationIddata);

                    })




            }
            else {
                alert("Account Already Exist");
                document.getElementById("reg-log").checked = false;
            }
        })

    }




    const handleauthSubmit1 = () => {


        firestore.collection("users").where("phone", "==", phone).get().then((result) => {
            console.log(result.docs.length)
            if (result.docs.length) {

                setshow(true);
                var applicationVerifier = new firebase.auth.RecaptchaVerifier(
                    'recaptcha1');
                var provider = new firebase.auth.PhoneAuthProvider();
                var number = "+91" + phone;
                provider.verifyPhoneNumber(number, applicationVerifier)
                    .then(function (verificationId) {
                        //var verificationCode = prompt('Please enter the verification code', '');
                        var verificationCode = "";

                        //  var verificationCode = Number(prompt("Please enter your name", "123456"));

                        setShow3(true);
                        var verificationCode = otpsubmit();
                        console.log(verificationCode);
                        return firebase.auth.PhoneAuthProvider.credential(verificationId,
                            verificationCode);
                    })
                    .then(function (phoneCredential) {
                        return firebase.auth().signInWithCredential(phoneCredential)
                            .then(function (userCredential) {
                                console.log(userCredential);

                            });
                    });




            }
            else {
                alert("Account Already Exist");
                document.getElementById("reg-log").checked = false;
            }
        })

    }


    const handleauthSubmit = async () => {
        var recaptcha = new firebase.auth.RecaptchaVerifier('recaptcha');
        setshow1(true);
        var number = "+91" + phone;
        await firebase.auth().signInWithPhoneNumber(number, recaptcha).then(function (e) {
            var code = prompt('Enter the otp', '');


            if (code === null) return;
            console.log(e);

            e.confirm(code).then(function (result) {
                // var email = "test@gmail.com";
                // var displayName = "test";
                // var photoURL = "";
                createUserProfileDocument(result.user, { email, displayName, photoURL, phone });
                console.log(result.user);

                //                document.querySelector('label').textContent +=   result.user.phoneNumber + "Number verified";

            }).catch(function (error) {
                console.error(error);

            });

        })
            .catch(function (error) {
                console.error(error);

            });
    }


    var parameter1;
    if (match !== undefined) {

        parameter1 = match.params.paramater;
        console.log(parameter1);

    }
    else {

        parameter1 = null;
    }

    const count = useSelector(state => { console.log(state.referred.referredId); });
    const dispatch = useDispatch();

    console.log("sent")

    dispatch(setReferredId(parameter1));


    console.log(otp);
    console.log(verificationId);

    return (
        <>   <Dialog
            maxWidth="sm"
            fullWidth={true}
            open={show3}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Enter OTP</DialogTitle>
            <DialogContent>
                <TextField value={otp} onChange={(e) => setotp(e.target.value)} style={{ width: "100%" }} id="outlined-basic" label="6-DIGIT OTP" variant="outlined" />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Close
                </Button>
                <Button onClick={() => otpsubmit()} color="primary" autoFocus>
                    Submit
                </Button>
            </DialogActions>
        </Dialog>


            <Row >
                <Col lg="6" md="12" sm="12">
                    <img className='homepage-img' src={img} alt="image" />

                    <h2 className="welcometxt" style={{ textAlign: "center " }}>WELCOME TO KALPAVRUKSHA <br></br> COOPERATIVE SOCIETY LTD.</h2>
                    {/* <CustomButton style={{ opacity: "0.60", borderRadius: "40px", textAlign: "center ", margin: "0 auto", justifyContent: "center", display: "block" }} color="secondary" onClick={handleClick}>Sign In</CustomButton> */}


                    {/* <CustomButton style={{ borderRadius: "40px", textAlign: "center ", margin: "0 auto", justifyContent: "center", display: "block" }} color="secondary" onClick={signInWIthGoogle} isGoogleSignIn type="button">
                        Sign in with Google
                    </CustomButton> */}

                </Col>
                <Col lg="6" md="12" sm="12" xs="12">


                    <div>

                        <div class="section">
                            <div class="container">
                                <div class="row full-height justify-content-center">
                                    <div class="col-12 text-center align-self-center py-5">
                                        <div class="section pb-5 pt-5 pt-sm-2 text-center">
                                            <h6 class="mb-0 pb-3"><span>Log In </span><span>Sign Up</span></h6>
                                            <input class="checkbox" type="checkbox" id="reg-log" name="reg-log" />
                                            <label for="reg-log"></label>
                                            <div class="card-3d-wrap mx-auto">
                                                <div class="card-3d-wrapper">
                                                    <div class="card-front">
                                                        <div class="center-wrap">
                                                            <div class="section text-center">

                                                                <div class="form-group mt-2">
                                                                    <FormControl fullWidth variant="outlined">
                                                                        <InputLabel >Phone No</InputLabel>
                                                                        <OutlinedInput
                                                                            id="outlined-adornment-amount"
                                                                            value={phone}
                                                                            onChange={(e) => setphone(e.target.value)}
                                                                            startAdornment={<InputAdornment position="start">+91</InputAdornment>}
                                                                            labelWidth={60}
                                                                        />
                                                                    </FormControl>
                                                                </div>
                                                                <Button style={{ marginTop: "6px" }} className="mb-4" onClick={() => onsubmit()}> Log In </Button>
                                                                <br></br>
                                                                <label>{show ? "Please Check the box to proceed" : ""}</label>

                                                                <div id="recaptcha1"></div>


                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="card-back">
                                                        <div class="center-wrap">
                                                            <div class="section text-center">

                                                                <div class="form-group">
                                                                    <FormControl fullWidth variant="outlined">
                                                                        <InputLabel>Full Name</InputLabel>
                                                                        <OutlinedInput
                                                                            id="outlined-adornment-name"
                                                                            value={displayName}
                                                                            onChange={(e) => setdisplayName(e.target.value)}
                                                                            labelWidth={60}
                                                                            size="small"
                                                                            autoFocus
                                                                            error={displayName !== "" && !displayName.match("^[a-zA-Z]")}
                                                                            helperText={displayName === "" ? 'Empty field!' : ''}
                                                                        />
                                                                        <FormHelperText>{displayName === "" ? 'Please Enter Full Name' : ''}</FormHelperText>
                                                                    </FormControl>
                                                                </div>
                                                                <div class="form-group mt-2">
                                                                    <FormControl fullWidth variant="outlined">
                                                                        <InputLabel >Email</InputLabel>
                                                                        <OutlinedInput
                                                                            id="outlined-adornment-email"
                                                                            type="email"
                                                                            value={email}
                                                                            onChange={(e) => setemail(e.target.value)}
                                                                            labelWidth={60}
                                                                            error={email !== "" && !email.match("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$")}

                                                                        />
                                                                        <FormHelperText>{email !== "" && !email.match("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$") ? 'Enter valid email' : ""}</FormHelperText>
                                                                    </FormControl>
                                                                </div>
                                                                <div class="form-group mt-2">
                                                                    <FormControl fullWidth variant="outlined">
                                                                        <InputLabel>Phone No</InputLabel>
                                                                        <OutlinedInput
                                                                            id="outlined-adornment-amount1"
                                                                            value={phone}
                                                                            onChange={(e) => setphone(e.target.value)}
                                                                            startAdornment={<InputAdornment position="start">+91</InputAdornment>}
                                                                            labelWidth={60}
                                                                            error={phone !== "" && !phone.match("^[6-9][0-9]{9}$")}

                                                                        />
                                                                        <FormHelperText>{phone !== "" && !phone.match("^[6-9][0-9]{9}$") ? "Enter 10-digit valid phone No" : ""}</FormHelperText>
                                                                    </FormControl>
                                                                </div>
                                                                <Button style={{ marginTop: "6px" }} disabled={((displayName !== "") && (email !== "") && (email.match("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$")) && (phone !== "") && (phone.match("^[6-9][0-9]{9}$"))) ? false : true} className="mb-4" onClick={() => onsignupsubmit()}> Sign Up </Button>
                                                                <br></br>
                                                                <label>{show1 ? "Please Check the box to proceed" : ""}</label>

                                                                <div id="recaptcha"></div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>





                    {/* <CustomButton style={{ opacity: "0.60", borderRadius: "40px", textAlign: "center ", margin: "0 auto", justifyContent: "center", display: "block" }} color="secondary" onClick={handleClick}>Sign In</CustomButton> */}
                    {/* <CustomButton style={{ borderRadius: "40px", textAlign: "center ", margin: "0 auto", justifyContent: "center", display: "block" }} color="secondary" onClick={signInWIthGoogle} isGoogleSignIn type="button">
                        Sign in with Google
                    </CustomButton> */}


                </Col>

            </Row>

        </>
    )
}


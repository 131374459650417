import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import firebase, { firestore } from "../../firebase/firebase.utils";
import { Card } from "react-bootstrap";
import { CircularProgress } from "@material-ui/core";
import "./about.scss";
import Footer from "./footer";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
var createReactClass = require('create-react-class');


const Tabs = createReactClass({
    getInitialState() {
        return {
            selected: this.props.selected || 0
        }
    },
    render() {
        return (<div>
            <ul className="inline">
                {this.props.children.map((elem, index) => {
                    let style = index == this.state.selected ? 'selected' : '';
                    return <li className={style} key={index} onClick={this.handleChange.bind(this, index)}>{elem.props.title}</li>
                })}
            </ul>
            <div className="tab">{this.props.children[this.state.selected]}</div>
        </div>
        )
    },
    handleChange(index) {
        this.setState({ selected: index })
    }
})


const Panel = createReactClass({
    render() {
        return <div style={{ textAlign: "center" }}>{this.props.children}</div>
    }
})


const About = ({ currentUser }) => {

    //  const user = useSelector(state => state.u
    return (
        <>
            {currentUser ?

                <div>
                    <Card>
                        <h1 style={{ textAlign: "center", color: "rgb(63, 81, 181)" }} className="mb-5">ABOUT US</h1>
                        <Tabs selected={0}>
                            <Panel title="Who are We"> <div style={{ textAlign: "left", fontSize: "17px" }}>
                                <h4> Name </h4>
                                The name of the society shall be Kalpavruksha Mutually Aided Multipurpose
                                Co-operative Society Limit in short The Society is called as Kalpavruksha
                                Co-operative Society Limited in all authorised transactions.
                                <br></br>
                                <br></br>
                                <h4>Address</h4>
                                Society address shall be H.No 13-304,Christian Colony Shadnagar, Mandal
                                Farooqnagar, District Ranga Reddy,Telangana      <br></br>
                                <br></br>
                                <h4>Co-operative Principles</h4>

                                The activities of the Society will be conducted as per the
                                provisions of Section. 3 of the A.P Mutually Aided Cooperative Societies ACT
                                1995(Now T.S Mutually Co-operative society ACT 1995) following the co-operative
                                principles. Membership in our Society shall be voluntary and available to all persons
                                who can make use of its services and are willing to accept the responsibilities of
                                membership. The Society will not treat the members as a capital providers but treat
                                them as a responsible Members. The Dicision making right as well as sharing surplus
                                will be Based on Byelaws only. The activities of the society will be managed by the
                                committee elected by the members or appointed by the members accountable to them
                                every member will have only one vote. Surplus of deficit earned by the Society at the
                                end of the year on the transactions conducted will be distributed to members based on
                                their transactions with the society. The surplus will be utilized towards the
                                development of the Society and also to render required services to the members as well
                                as to achieve the object of the society. The society will make arrangements to conduct
                                member education regularly to its members, committee members others who are
                                interested in societies activities. To render better and quality services to members,
                                society will associate actively with other cooperatives, institutions and Government
                                Organizations. The Ssociety will concentrate on Member requirements and desires and
                                render services for their overall development.
                                <br></br>
                                <br></br>

                                <h4> DEFINITIONS </h4>

                                As there are byelaw unless the context otherwise requires –
                                ‘Act’ means T.S. Mutually Aided Co-operative Societies Act 1995.
                                ‘Registrar’ means Registrar of the Mutually Aided Co-operative Societies or any
                                other officer who was conferred with the power of Registrar as per Section 4 of
                                1995 Act by the Government.
                                ‘Members’ mean eligible members as mentioned in the Byelaw.
                                ‘Managing Committee’ means Kalpavruksha Mutually Aided Multipurpose Cooperative Society Limited. Managing Committee.
                                ‘General Body’ means Total Members ofKalpavruksha Mutually Aided
                                Multipurpose Co operative Society Limited.
                                ‘C.E.O’ means the person appointed by the board to look after day to day activities
                                of the Kalpavruksha Mutually Aided Multipurpose Co-operative Society Limited.
                                ‘Co-operative Year’ means the year starting from 1st April and ends on 31st
                                March of the succeeding year.


                                <br></br>
                                <br></br>

                                <h4>Objectives</h4>


                                The objective of the Society is to be self sufficient, viable, member
                                sensitive, working in consonance with the principles of cooperation towards overall
                                economic and social betterment of its members, is the objective of the Kalpavruksha
                                Mutually Aided Multipurpose Co-operative Society Limited.


                                <br></br>
                                <br></br>
                                <h4>Eligibility for membership</h4>

                                The eligibility for the membership will be based on the
                                provisions of Section. 19 of the TS Mutually Aided Co-operative Societies Act 1995.
                                Persons Aged 18 and above having sound mind will be eligible for membership. They
                                must be solvent.
                                The membership will be allotted by the committee constituted by the board of
                                directors. The member has to follow the co-operative principles and also to full fill the
                                membership requisition as per bye-law. Person declared as insolvent by any court of
                                law is not eligible as member. Persons seeking membership of the society may not be
                                member of any other Society which is rendering similar services.


                                <br></br>
                                <br></br>

                                <h4>Withdrawal of Membership</h4>
                                Any Member can withdraw their membership with an
                                application after repaying the dues to the society. Within six months from the receipt of
                                the withdrawal application committee must settle the members account. Till the
                                settlement of the account in the society member shall fulfill his responsibilities without
                                having any rights.


                                <br></br>
                                <br></br>

                                <h4>Termination of Membership</h4>
                                Any member working against the interests of other
                                member as well as the interest of the society. After repaying the amounts due to society
                                within the time frame given by the committee. Deceased Members removed as per the
                                provisions of the Mutually aided co-operative society act 1995.



                                <br></br>
                                <br></br>

                                <h4>Services to members</h4>
                                The society to achieve the objective can on its own or with the
                                co-operation of other co-operative institutions, Financial institutions, developing
                                agencies, Govt. agencies commercial organizations may undertake the following
                                activities or services. Facility of saving fixed amount regularly and get back the same
                                when the member expires or withdraws form his membership. Fixed and recurring
                                deposit facilities. Facility of Saving money and the same may be taken at any time
                                Facility of loan as per the board decision. Facility of Loan relief assurance scheme at the
                                time of member deceased. Facility of getting financial advise Facility of getting
                                co-operative education Based on the Profession and income of the members they may
                                be divided as various groups separately, through different schemes financial benefits
                                can be provided. Loans may be provided for the members consumption, productive and
                                marketing purposes and also kept inputs available for members, new technical
                                methods to be introduced. Society may directly undertake Production, Marketing,
                                Transport Services etc.. For the economic and social development of present and past
                                members of the society, the society may create, undertake various welfare activities.
                                For the overall development of members financial and development, advocacy services
                                may be provided and also can provide credit, insurance and non credit services. To
                                create awareness about health & staple food and also provide health services. To
                                improve the skills of members, technical, professional, training will be provided To
                                create profitable jobs, cottage, small scale industries, collective productive activities to
                                be encouraged and managed. In order to improve income source to members in village
                                level facility centers will be established, through work assurance scheme jobs will be
                                created, for providing employment to unemployed suitable arrangements will be made.
                                Efforts will be made to protect food security to members To remove gender disparity,
                                efforts will be made. Keeping in view the rural environment, agriculture allied
                                Industries with new methods will be established to improve members livelihood as
                                well as to improve their financial position for example through natural Agricultural
                                Methods etc. To achieve benefits to members as well as to society to have relationship
                                and agreements with local, Municipal, regional, National, International development
                                agencies, Financial Institutions, Commercial, Organization, Banks, co-operative
                                Federations, Religious and social organizations. To improve members benefits and also
                                to give boost to co-operative movement, efforts will be made through supportive
                                legislation, Govt. policies, public support. In community development co-operative
                                concept will be made as a integral part, Support will be given to establish self help
                                groups and self reliant co-operative societies. Efforts will be made to undertake
                                operational research activities on issues related to co-operative movement. Material
                                related to co-operative movement i.e. co-operative education, Training will be prepared,
                                published and distributed. Project reports will be prepared on behalf of members and
                                society to get financial assistance and technical support from other institutions. For the
                                Special and common activities of the society, contributions, grants, loans, and others
                                funds will be mobilized from Govt. Financial Institutions, Local, Regional, National and
                                international developing Agencies co-operative Federations, Local bodies, commercial
                                organizations and individuals. Donations and contributions may be given by the
                                society by the board assessing the need and availability of funds for spiritual,
                                educational, health, wealth, space, technical purposes and also for the welfare of
                                members and society donations may be given. Society can invest its general surplus
                                funds for the achievement of its objectives, in shares and securities, in other businesses
                                fixed and current assets except in activities who have high profit with high risk The
                                society will work with co-operative, financial and with other development agencies in
                                terms of financial and technical issues. If required society will establish new
                                institutions and ancillary institutions as well. The society will undertake welfare and
                                other services for its employees. For the development of women and achieve the
                                objectives of the society, society will undertake any other activity. Based on their
                                profession, income members will be made various groups and depending on their
                                needs separate schemes will be implemented to give due benefits Safe deposit lockers
                                will be maintained. Depending upon the needs, services will be given at the doorsteps
                                of the members. Education, health and food for these 3 necessities, required services
                                will be given by the society or through other Agencies. All products required for
                                members will be purchased or Manufactured by the society and add value to the
                                product and supplied the same to members. Provide loans to fixed assets i.e. Land,
                                Plots, home, commercial com plexus or society purchased the same and maintain them,
                                and sold them or lease Provide loans on vehicles, Gold armaments, home, plots, reg
                                .mortgage, pledge etc., procure agricultural products from the fields and process the
                                same and hand over to the members. Lands, Plots, Purchases and Sale open plots are
                                taken by the Society and Developed as plots or constructions of the Houses, that houses
                                are directly sales or given as rent purpose or maintain them. Society may jointly
                                cultivate with formers or purchase or take loan on lease basis and society do cultivation
                                on its own.



                                <br></br>
                                <br></br>

                                <h4>Minimum Responsibilities of Members</h4>

                                Members shall pay all the amounts due to the society in time
                                Members must fulfill all the responsibilities as fixed by the society from time to time
                                <br></br>
                                <br></br>

                                <h5>General body</h5> As per provisions of the TS Mutually Aided Co-operative societies act
                                1995 the following are the duties of the General body, society General body will have
                                ultimate control over the society subject to the provisions of the TS Mutually Aided
                                Co-operative societies act 1995.


                                <br></br>
                                <br></br>
                                <h5>General body Meetings</h5>
                                The conduct of General body Meeting every year within 6
                                months after closure of the Accounting year is the responsibility of the committee.
                                Financial year: The societies financial year will be from 1st April to 31st March of the
                                subsequent year.


                                <br></br>
                                <br></br>
                                <h5>Audit</h5>

                                The general body should appoint a chartered accountant as a Auditor in its
                                annual meeting every year duly considering the recommendations of the board. The
                                chief executive officer should arrange all recods and accounts and other documents and
                                the statements of accounts as approved by the board every year for the audit. In order
                                to conduct the general body meetings of the society in time, the chief executive officer
                                must ensure that the audit of the society is completed by 30th May/June of every year.
                                The auditor has the right to attend the General body and present his report if he wants
                                to do so in person. He shall also report to General body the deviations from the budject
                                if any.


                                <br></br>
                                <br></br>
                                <h5>Settlement of Disputes</h5>


                                Society’s General Body should constitute a 3 member
                                Committee to resolve the disputes mentioned in Section 37 of the TS Mutually Aided
                                Co-operative Societies Act 1995.

                                <br></br>
                                <br></br>
                                <h5>Filing of Returns</h5>

                                After conduct of the society General body meeting, Required reports
                                must be submitted to the Registrar every year within 30 days of the meeting.



                                <br></br>
                                <br></br>



                                <h4 >PANDEMIC IN COVID – 19</h4>

                                Many small businesses have been hurt or even destroyed by the COVID-19 pandemic. Owners
                                may have lost their business as well as their funds. One of the legal tools that can help some
                                owners recover is the business cooperative, or “co-operative.” Co-operatives are organizations
                                in which people or businesses band together to help themselves. Usually the co-operative
                                society seeks to complete a task or function better or less expensively or with more control
                                over the process, than members could accomplish individually. Our business and social
                                communities have countless examples of co-operative society .
                                <br></br>
                                <br></br>
                                Here are a few: Businesses are facing challenges due to the pandemic as well as people who
                                lost a business or lost jobs lack of funds to restart, might consider whether it is possible with
                                similarly situated businesses or like-minded business associates, co-workers, or
                                acquaintances to restart in the form of a co-operative society.

                                <br></br>
                                <br></br>
                                Co-operatives have a long history. They are generally guided by some basic principles.
                                According to the National Co-operative Union of India , some of those basic principles are
                                Voluntary and Open Membership: Anyone having a need and meeting the co-operative
                                basic requirements is welcome to join. Co-operative do not discriminate based on gender,
                                social, racial, political or religious factors.
                                <br></br>
                                <br></br>
                                <strong> Independence:</strong> Co-operative societies are autonomous, self-help organizations.
                                <br></br>
                                <br></br>
                                <strong> Democratic Control:</strong> Members decide who will be the co-operative leaders and how it will
                                be run, on a one-member one-vote basis.

                                <br></br>
                                <br></br>


                                <strong> Economic Participation: </strong> All members invest and participate in the co-operatives. The
                                financial investment is usually modest, and members do not receive a significant return on any
                                financial investment. Members benefit is based on patronage, how much of the co-operative
                                designated activity they engage in with the co-operative. If the co-operative society generates
                                extra funds in excess of what is needed to operate, they are distributed based on each
                                member’s patronage of the co-operative Society

                                <br></br>
                                <br></br>


                                <strong> Education and Training:</strong> Co-operatives provide education, training and information so
                                members can contribute to the organization’s success.

                                <br></br>
                                <br></br>

                                <strong> Co-operation among Cooperatives: </strong> Co-operative work with and support other co-operative.
                                <br></br>
                                <br></br>

                                <strong>Community: </strong> Co-operatives are community minded and work to develop and benefit their
                                communities. Today some of the largest business organizations in India are co-operatives.
                                IFFCO,MULKANOOR,CAMPO,AMUL,HOPCOMS,LIZZATH PAPAD. These organizations are
                                co-operatives. Their missions are not to generate a profit as such, but to benefit their
                                members. Generally, if they have profits, they return them to the members based on
                                patronage. They generally ascribe to the above principles. For businesses, joining together as a
                                co-operative can provide economies and bargaining power for consolidating space, collectively
                                providing services By joining together with other knowledgeable persons similarly situated,
                                those damaged by the scourge of the pandemic may be able to achieve a fresh start in business
                                by pooling their collective knowledge and financial resources.
                                <br></br>
                                <br></br>
                                A co-operative society may be an appropriate and particularly useful business model in the era
                                of COVID-19.
                                <br></br>
                                <br></br>
                                <h4>
                                    SMS-e-PLAN </h4>
                                <h6 style={{ color: "rgb(63, 81, 181)", fontSize: "18px" }}>Sanghamitra savings & earning plan
                                </h6>
                                <br></br>

                                Those persons who want to register as a member can join Sanghamitra Savings
                                & Earning Plan after having understood the terms and conditions of the plan in full.
                                <br></br>
                                <br></br>

                                <h4> Cancellation & refund policy of SMS e-PLAN</h4>


                                If any member withdraws after paying a minimum of ten installments, the remaining amount  Excluding five percent of the value of the scheme, will be paid to him upon maturity of the scheme.
                                <br></br>
                                <br></br>
                            </div></Panel>
                            <Panel title="Privacy Policy"><div style={{ textAlign: "left", fontSize: "17px" }}>
                                The Privacy Policy governs the use of this website Kalpavruksha Mutually Aided
                                Multipurpose Co-Operative Society Limited. (Website/Site/SOCIETY)
                                <br></br>
                                <br></br>
                                By visiting the Website and all other links thereunder, the User agrees to be bound by
                                the terms and conditions of the Privacy Policy Kalpavruksha Mutually Aided
                                Multipurpose Co-Operative Society Limited. By accepting the Privacy Policy, the User
                                expressly consents to the usage and disclosure of the User’s information in
                                accordance with this Privacy Policy of Kalpavruksha Mutually Aided Multipurpose
                                Co-Operative Society Limited.
                                <br></br>
                                <br></br>
                                Kalpavruksha Mutually Aided Multipurpose Co-Operative Society Limited is committed
                                to protecting your privacy and working towards offering you a powerful, safe, online
                                experience.
                                <br></br>
                                <br></br>
                                Our web site does not collect personal information about individuals except when
                                such individuals specifically provide such information on a voluntary basis which
                                information will not be sold or otherwise transferred to unaffiliated third parties
                                unless otherwise stated at the time of collection or with the approval of the user as
                                the case may be.
                                <br></br>
                                <br></br>
                                Kalpavruksha Mutually Aided Multipurpose Co-Operative Society Limited treats your
                                personal information or your use of the service as private and confidential and does
                                not check, edit or reveal it to any third parties except where it believes in good faith,
                                such action is necessary to comply with the applicable legal and regulatory processes
                                or to protect and defend the rights of other users or to enforce the terms of service
                                which are binding on all the users of Kalpavruksha Mutually Aided Multipurpose
                                Co-Operative Society Limited. Kalpavruksha Mutually Aided Multipurpose
                                Co-Operative Society Limited and/or its affiliates will share / sell some or all of the
                                User’s personal information with another business entity.

                                <br></br>
                                <br></br>
                                Except where specifically agreed or necessary for operational or regulatory reasons,
                                Kalpavruksha Mutually Aided Multipurpose Co-Operative Society Limited will not send
                                you any unsolicited information. If the user does not opt out, Kalpavruksha Mutually
                                Aided Multipurpose Co-Operative Society Limited may use any e-mail addresses of
                                users to send occasional e-mails pertaining to information on product and services.
                                The User can nevertheless unsubscribe from receipt of such e-mails by following
                                instructions therein. Kalpavruksha Mutually Aided Multipurpose Co-Operative Society
                                Limited may collect unnamed statistics, which do not personally identify you.
                                Kalpavruksha Mutually Aided Multipurpose Co-Operative Society Limited reserves the
                                right to perform statistical analysis of user behavior and characteristics in order to
                                measure interest in and use of the various areas of the Site and to inform advertisers
                                of such information as well as the number of users that have been exposed to or
                                clicked on their advertising banners.       <br></br>
                                <br></br>


                                We will provide only aggregated data from these analyses to third parties. We will
                                communicate with Users via e-mail or by other online delivery devices only if the User
                                agrees to/elects to receive/send those communications. Users, who believe that they
                                are receiving our communications in error or no longer desire to receive them, should
                                inform us and we will remove those Users' names from our mailing lists. Kalpavruksha
                                Mutually Aided Multipurpose Co-Operative Society Limited will be judicious in the use
                                of e-mail and paper mail to communicate with users. Kalpavruksha Mutually Aided
                                Multipurpose Co-Operative Society Limited reserves its right to correct any part of
                                the content of the Website at any time as and when required at its sole discretion.

                                <br></br>
                                <br></br>



                                The content of this Website shall not be displayed or printed in any form in part or
                                whole without the prior written approval of Kalpavruksha Mutually Aided
                                Multipurpose Co-Operative Society Limited. The information contents provided on this
                                Site or the society logo cannot be copied, modified, uploaded, downloaded, published
                                or republished, transmitted or otherwise distributed for commercial purposes without
                                prior and explicit permission from Kalpavruksha Mutually Aided Multipurpose
                                Co-Operative Society Limited. Reproduction of any information or material provided
                                on this Website, with or without any modification, is prohibited unless, with prior
                                permission of Kalpavruksha Mutually Aided Multipurpose Co-Operative Society Limited
                                and shall amount to violation of copyright and would be deemed an illegal act. You
                                are requested to note our financial products and the information, tools and planners
                                contained in the Site, Kalpavruksha Mutually Aided Multipurpose Co-Operative Society
                                Limited are meant for your general information only and not to be used for deciding
                                on your financial requirements. You are specifically advised to contact one of our
                                Branches for any of your particular financial needs.


                                <br></br>
                                <br></br>
                                <h4> Protection Of Privacy Of Children/Minor </h4>
                                <br></br>
                                Use of the Website is available only to
                                persons who can form legally binding contracts under applicable law. Persons who are
                                "incompetent to contract" within the meaning of the Indian Contract Act, 1872
                                including un-discharged insolvents, etc. are not eligible to use the Website.
                                <br></br>
                                <br></br>
                                <h4> Use Of Information And Material </h4>
                                <br></br>

                                The information and materials contained in these
                                pages and the terms, conditions, and descriptions that appear thereon are subject to
                                change. They should not be regarded as an offer, solicitation, invitation, advice
                                regarding any financial product of Kalpavruksha Mutually Aided Multipurpose
                                Co-Operative Society Limited. You are therefore requested to verify all the
                                information with one of the Branches before relying on the same. Financial Products
                                and services shown on the website may be withdrawn or amended or varied at any
                                time at the sole discretion of Kalpavruksha Mutually Aided Multipurpose Co-Operative
                                Society Limited. Save and except with the Kalpavruksha Mutually Aided Multipurpose
                                Co-Operative Society Limited prior written consent, you shall not insert a hyperlink to
                                this website or any part thereof on any other website or "mirror" or frame this
                                website, any part thereof, or any information or materials contained in this website
                                on any other server, website or web-page. Wherever certain information is requested
                                and necessary to enable you to use our web enabled services we gather such
                                information, including personal information, so that we can better develop our
                                relationship with Users. You may choose not to share any such information with us,
                                but then you may not be able to take advantage of the features on the site.  <br></br>
                                <br></br>
                                Cookies: 'Cookies' are computer data storage programs which enable a web site to record a visitor's activities from his/ her computer hard drive. A cookie is placed on our server for as long as a session is active. No data from the user's computer is accessed from our site. Our cookies tag each visitor's browser with a random, unique number. The cookie simply assigns a number and does not disclose any personal information about the person using the browser. Additionally, the User may encounter “cookies” or other similar data collection devices on certain pages of the Website that are placed by third parties. Consul hereby disclaims that it does not control the use of and are not responsible for any activities of cookies by third parties.
                                <br></br>
                                <br></br>


                                The information, product descriptions, material, advices, suggestions, illustrations notifications, circulars etc. are collectively stated "the content" in this Website. If the said content contains any mistakes, omissions, inaccuracies and typographical errors, etc. Kalpavruksha co-operative society LTD. Sanghamitra  savings plan assumes no responsibility thereof.
                                <br></br>
                                <br></br>
                                The information and materials contained in this Site, are provided "as is," "as available" basis. Kalpavruksha co-operative society LTD. Sanghamitra  savings plan does not warrant the accuracy, adequacy or completeness of any of such information and materials. Kalpavruksha co-operative society LTD. Sanghamitra  savings plan expressly disclaims any and all liability for errors or omissions in all such information and materials. No warranty of any kind, implied, express or statutory, including but not limited to the warranties relating to non-infringement of third party rights, title, merchantability, fitness for a particular purpose and freedom from computer virus, is given in conjunction with any of the information and materials published herein.
                                <br></br>
                                <br></br>
                                <h4> Limitation And Liability </h4>
                                <br></br>

                                Kalpavruksha co-operative society LTD. Sanghamitra  savings plan shall not be liable for any loss or damage, including without limitation direct or indirect, special, economic, incidental, or consequential damages, losses or expenses arising in connection with this Site or use thereof or inability to use by any party, or in connection with any failure of performance, error, omission, interruption, defect, delay in operation or transmission, computer virus or line or system failure, even if Kalpavruksha co-operative society LTD. Sanghamitra  savings plan has been advised of the possibility of such damages, losses or expenses.
                                <br></br>
                                <br></br>
                                <h4>Hyperlinks To Other Internet Resources: </h4>
                                <br></br>

                                Hyperlinks to other internet resources are for the User's convenience only and are at your own risk; the content, accuracy, legality of or restrictions imposed or opinions expressed by and other links provided by or for these resources are not investigated, verified, monitored, nor endorsed by Kalpavruksha co-operative society LTD. Sanghamitra  savings plan. Kalpavruksha co-operative society LTD. Sanghamitra  savings plan is not responsible either for their standards of public order and decency or for their personal data protection policy or the use that could be made of such data. When you access another website through a hypertext link, you are accepting that this access is at your own risk. Consequently, Kalpavruksha co-operative society LTD. Sanghamitra  savings plan will not be liable for any loss or damage arising directly or indirectly from the accuracy or otherwise of materials or information contained on the pages of such sites, or for any loss arising directly or indirectly from the defects with such sites.
                                <br></br>
                                <br></br>


                                Web site and pages thereon shall be deemed to be the property of Kalpavruksha
                                Mutually Aided Multipurpose Co-Operative Society Limited. Please note that
                                Kalpavruksha Mutually Aided Multipurpose Co-Operative Society Limited does not
                                warrant that any e-mail from its Web site is secure and does not guarantee that any
                                such e-mail from you or from Kalpavruksha Mutually Aided Multipurpose Co-Operative
                                Society Limited are secure during Internet transmission. Public Access to Personal
                                Information: Please note that you should exercise caution when deciding to disclose
                                or key-in your personal information on-line, and ensure that no third party is able to
                                access such personal information as you key it in or view it on the screen of your
                                computer. This caution is to be exercised in respect of the information that you may
                                provide, to apply to become a customer of Kalpavruksha Mutually Aided
                                Multipurpose Co-Operative Society Limited as well.
                                <br></br>
                                <br></br>
                                This Privacy Policy shall be subject to change without notice. Kalpavruksha co-operative society LTD. Sanghamitra  savings plan recommends visiting this Privacy Policy every time the User visits the Website, in order to be informed about change, if any. In the event the modified privacy policy is not acceptable to the User, the User should discontinue using the Website. However, if the User continues to use the service the User shall be deemed to have agreed to accept and abide by the modified privacy policy of this Website.

                            </div></Panel>
                            <Panel title="Terms and Conditions"><div style={{ textAlign: "left", fontSize: "17px" }}>



                                <h4>
                                    Terms and Conditions</h4>
                                <br></br>

                                1. Any one who is a member of M/ s. Kalpavruksha Mutually Aided Multipurpose
                                Co-operative Society Limited can join as a Member of Sanghamitra Savings Plan.
                                <br></br>
                                <br></br>
                                2. Such Member who intends to join as Member Member of Sanghamitra Savings Plan.shall
                                at least have completed 9th Class.
                                <br></br>
                                <br></br>  3. Those individuals who joined this Plan and paid two installments shall be eligible to be
                                appointed as Society Representative/ Agent
                                <br></br>
                                <br></br> 4. Such persons who are recognized as Society Representative are eligible to introduce the
                                members to the Savings Plan.

                                <br></br>
                                <br></br> 5. The incentive/ commission to the Representative will be paid within five days after the
                                receipt of fully paid second installments by the Representative at least from two members who
                                joined the SMS e-Plan

                                <br></br>
                                <br></br> 6. The Representative is eligible to be paid such commission after deducting the Income-Tax
                                apart from any other payments payable to other authorities.
                                <br></br>
                                <br></br> 7. The Representative is eligible for commission or incentive on the basis of the
                                performance of their team.

                                <br></br>
                                <br></br>8. The Representative shall not furnish false information or made false assurances to the
                                members who joined the SMS e-Plan under any circumstances.
                                <br></br>
                                <br></br> 9. The Representative (s) shall not indulge in activities of those nature which would tarnish
                                the image of the Savings Plan.
                                <br></br>
                                <br></br>10. The Representative shall use such forms as are approved by the Sanghamitra Savings
                                Plan and in no circumstances any other forms are documents shall not be used.

                                <br></br>
                                <br></br>11. No Representative or Representatives are deemed or considered to be the employees of
                                the Society and/ or the Society Representatives shall claim employment in the Society.
                                <br></br>
                                <br></br> 12. The Managing Committee has the absolute authority and right to reject the membership
                                of the persons who intend to join the Plan.
                                <br></br>
                                <br></br>13 The Representatives shall abide the terms and conditions of the Savings Plan as well as
                                the bye-laws of the Society those subjected to changes and or amendments from time to time.
                                <br></br>
                                <br></br>14. The Representatives shall be aware of rules that are made from time to time and inform
                                the Society Members.

                                <br></br>
                                <br></br>15. All members shall pay the installments regularly and in a timely manner to the Savings
                                Plan till the expiry of the period.
                                <br></br>
                                <br></br>

                                16. Any Member the Society who commits default in payment of installments or fails to
                                made payment of installments shall be paid the amount so paid after deducting 5 % of the
                                total amount of the plan at the time expiration of the plan towards penalty
                                <br></br>
                                <br></br>
                                17.  	Any Representative or representatives will lose their recognition in
                                the event if he/ they fails to perform business for one year.
                                <br></br>
                                <br></br> 18.  	 The Representatives shall attend the meetings of the Savings Plan
                                being conducted from time to time.
                                <br></br>
                                <br></br> 19.  	 The Managing Committee has full authority right to change or amend
                                the terms and conditions of the Savings Plan from time to time.
                                <br></br>
                                <br></br>20.  	The Managing Committee has right to terminate such Society
                                Representatives who have violated the terms and conditions of the Savings
                                Plan and also are not eligible or entitled to receive commission or bonus or
                                both from the Savings Plan.
                                <br></br>
                                <br></br> 21.  	 All disputes relating to the Savings Plan arising between the Members,
                                Representatives and as well as the Sanghamitra Savings & Earning Plan inter se will be resolved under the provisions of the Telangana Mutually Aided Co-operative
                                Societies Act, 1995.
                                <br></br>
                                {/* <br></br>This instrument here after referred to as the agreement executed between Applicant and Company. The Company is engaged in the business of Direct Selling through Multi Level Marketing (M.L.M.) and in other business activities as stated in the object clauses of Memorandum and Articles of Association of the Company. The Company authorises Direct Sellers across the country for marketing and sale of its Products and Services. An individual (Indian Citizen only) intended to become Direct Seller can apply for the same in prescribed application form of the Company. There is no fee or charge for becoming Direct Seller of the Company. The applicant must be 18 years of age or above. The Company exclusively uses its website to display the details of the products, marketing methods and business monitoring. It also uses verbal publicity to promote its business. For smooth running, simplifying, keeping transparent, prevention of fraudulent practices and betterment of the business of Direct Selling, the Company has framed certain rules and regulations, marketing plan, terms and conditions etc. These terms and conditions are construed in accordance with model guidelines on direct selling issued by the Govt. of India, Ministry of Consumer Affairs, Food & Public Distribution, Department of Consumer Affairs, New Delhi vide F. No. 21/18/2014-IT (Vol.-II) dated 9th Sept, 2016.
                                <br></br>
                                <br></br>
                                  WHEREAS the applicant has gone through the Company's official website and read printed documents, brochures including stipulated terms and conditions for becoming Direct Seller.
                                  <br></br>
                                <br></br>
                                The Company and Direct Seller have clearly understood and agreed to abide by the terms and conditions as laid down herein at the time of agreement.
                                <br></br>
                                <br></br>
                                <hr></hr>   A. After submitting application form to the Company duly signed by applicant, the Company upon scrutiny and verification of the details submitted by applicant in application form may register as "Direct Seller" and authorise him for selling of the goods/products and services of the Company. The Company exclusive reserves the right to accept or reject or decline the application at its discretion without assigning any reason whatsoever.
                                <br></br>
                                <br></br>  B. The Direct Seller shall enjoy the following privileges: -
                                <br></br>
                                <br></br>  i) Incentive for effecting sale of goods/products and services of the Company as per marketing plan.
                                <br></br>
                                <br></br>  ii). No territorial restriction to sell the goods/products and services.
                                <br></br>
                                <br></br>iii). Search and inspect his/her account on website of the Company through I.D. and password awarded by the company.
                                <br></br>
                                <br></br>iv). Incentive of the Direct Seller shall be in proportion to the Business Volume of the Direct Seller either by his personal efforts or through team as stipulated in the marketing plan of the Company.
                                <br></br>
                                <br></br>v). Direct seller shall be entitled to a Cooling-off Period of 30 days from the date of execution of Agreement between Direct Seller and company without any punishable clause.
                                <br></br>
                                <br></br>vi). Direct Seller has the option of Buy back or return of currently marketable goods and services purchased by him/her within 30 days from the date of purchase at Direct Seller's request at reasonable terms. The return of the products must be supported with bill of purchase and such products should not be damaged any angel. The purchaser should insure that quality and condition of the product should be similar to the quality and condition which was prevailed at the time of purchase. Such return shall be governed by the return policy published on website of the Company.
                                <br></br>
                                <br></br> C. An individual, upon appending his/her signature at the bottom of this application form shall be deemed to have accepted the terms and conditions stipulated herein. Upon registration after scrutiny of the details submitted by applicant in application form, he shall become the Direct Seller of the Company. Allotment of password and ID shall be construed as registration as Direct Seller.
                                <br></br>
                                <br></br>D. The applicant hereby covenants that as under: -
                                <br></br>
                                <br></br> i). That he has clearly understood the marketing methods/plan, the compensation plan, its limitations and conditions. He/She agrees that he/she is not relying upon any misrepresentation or fraudulent inducement or assurance that is not set out in terms and conditions or other officially printed or published materials of the Company.
                                <br></br>
                                <br></br>ii). Relation between the Company and the Direct Seller shall be governed, in addition to this agreement, by the rules and procedure mentioned in the marketing plan available on website. The Direct Seller further confirms that he/she has read and understood guidelines and terms & conditions carefully and agrees to be bound by them.
                                <br></br>
                                <br></br>iii). Direct Seller shall act as freelancer. He shall not commit any misfeasance or malfeasance to create any liability/obligation on the Company of whatsoever nature.
                                <br></br>
                                <br></br>  iv). Direct Seller shall be responsible for paying all taxes whether direct or indirect including but not limited to income tax, GST and other taxes chargeable to Direct Seller on amount earned hereunder. All legal, statutory, financial and other obligations associated with Direct Seller's business shall be the sole responsibility of Direct Seller.
                                <br></br>
                                <br></br>  v). It is made and understood in very clear terms that Direct Seller is not an agent, employee, an authorised representative of the Company or its service provider and shall not be entitled to any employee's benefits. He/She is not authorised to receive/accept any amount/payment for and on behalf of the Company. Any payment/amount received by him/her will not be deemed to be received by the Company and the Company shall take necessary action against such Direct Seller.
                                <br></br>
                                <br></br>  vi). Direct Seller shall keep proper book of account stating the details of the sale of products, price, tax, quantity etc.
                                <br></br>
                                <br></br>   vii). Direct Seller hereby declare that all the information furnished by him to the Company are true and correct to his/her best of knowledge and nothing is concealed. Company reserves the right to take any action against the Direct Seller in the event of it is discovered that the Direct Seller furnished any wrong/false information to the Company.
                                <br></br>
                                <br></br> <h4>General Conditions:</h4>
                                1. The Company may appoint any person for collection/distribution services. Direct Seller is required to visit the Company's official website from time to time to get such appointment and avail facilities, make payment, collect valid receipt and products/services from its outlet/permanent retail centre.
                                <br></br>
                                <br></br> 2. Direct Seller shall use his/her best efforts to promote the sale of goods/products and services and maximize them. Direct Seller shall also provide reasonable assistance to Company in promotional activities.
                                <br></br>
                                <br></br> 3. The Direct Seller will be eligible for remuneration as per business volume of sale of products and services done by him/her subject to the eligibility of norms formulated by the Company from time to time according to marketing plan and not in any other form/manner is payable/given.
                                <br></br>
                                <br></br>4. Track ID has to be quoted by the Direct Seller for all his/her transactions and correspondence with the Company. The Track ID once chosen cannot be altered at any point of time.
                                <br></br>
                                <br></br> 5. No communication will be entertained without unique ID and password. Direct Seller shall preserve the ID and password properly as it is "must" for logging on website.
                                <br></br>
                                <br></br> 6. Incentive to the Direct Seller shall be subject to statutory deductions under income tax and other acts as applicable for the time being in force.
                                <br></br>
                                <br></br>7. The Company reserves right to withheld/block/suspend I.D. of the Direct Seller in the event of the Direct Seller fails to provide any detail as desired by the Company from time to time.
                                <br></br>
                                <br></br> 8. Direct Seller undertakes to adhere to policies, procedures, guidelines and rules & regulations formed by the Company.
                                <br></br>
                                <br></br> 9. The Direct Seller shall be faithful to the Company and uphold the integrity and decorum of the Company and shall maintain good relations to other Direct Sellers and his/her clients.
                                <br></br>
                                <br></br>   10. Company reserves the right to modify the terms and conditions, Products, Marketing Plan, Business and other policies at any time without any prior notice. Modification shall be published through the official website of the Company or any other mode as Company may deem fit and proper and such modification/ alteration and amendments made by the Government from time to time, shall be applicable and binding upon the Direct Seller from the date of such modification/alteration.
                                <br></br>
                                <br></br>  11. If any Direct Seller loses his contractual capacity due to any reason or in case of death of Direct Seller either his nominee or one of the legal heir with consent in writing of all the legal heirs, may join the Company as Direct Seller in place of incapable or the deceased provided he executes written agreement and undertakes to abide by all rules and regulations and terms & conditions etc. in the same manner as that in case of original Direct Seller. In case of failure to arrival at such consent within six months from the incapability or death of the Direct Seller, the Company shall be at liberty to terminate the unique I.D. and for this period the Company will keep his unique ID in abeyance.
                                <br></br>
                                <br></br>  12. Direct Seller shall be abided by all statutory, central, state and local body laws, rules and regulations and guidelines in operation of Company's business. Direct Seller shall not engage in any deceptive of unlawful trade practices as defined under different statutes.
                                <br></br>
                                <br></br> 13. Direct Seller shall not manipulate the Company's marketing plan, products and services, rate, B.V. etc. in any way.
                                <br></br>
                                <br></br>  14. Direct Seller shall not send, transmit or otherwise communicate any message to anybody on behalf of the Company without any authority from the Company.
                                <br></br>
                                <br></br> 15. Direct Seller or any other person under him is strictly prohibited to use promotional material, other than the developed and authorised by the company.
                                <br></br>
                                <br></br> 16. Direct Seller shall not use the KMC trademark, logotype and design anywhere without written permission of the Company and the said permission can be withdrawn at any time by the Company.
                                <br></br>
                                <br></br> 17. Direct Seller shall be self-responsible for all arrangements, expenses and permissions from Central/State Government and local bodies for conducting meetings or seminars.
                                <br></br>
                                <br></br>  18. Direct Seller shall bear the cost and expenses of conducting its business in accordance with these terms and conditions. The Company will not entertain any reimbursement on any expense made by the Direct Seller other than sales incentive earned by the Direct Seller as per the marketing plan.
                                <br></br>
                                <br></br> 19. Direct Seller shall carry his/her Identity Card issued by the company and not visit the consumer premises without prior appointment/approval. He shall not use I.D. Card apart from Company purposes.
                                <br></br>
                                <br></br> 20. Direct Seller shall not sell any product for a price exceeding Maximum Retail Price (M.R.P.)
                                <br></br>
                                <br></br>  21. Direct Seller shall not make medical claim for the effects arising out by using the products of the Company.
                                <br></br>
                                <br></br>
                                <h4>Restrictions:</h4>

                                1. Direct Seller or his/her relatives (relative means dependent son or daughter, father/mother, spouse) shall not engage in any activities of Multi Level Marketing of any other Company/Person. If it is found, such direct seller shall be terminated.
                                <br></br>
                                <br></br>  2. Direct Seller is prohibited from listening, marketing, advertising, promoting, discussing or selling of any product or the business opportunity on any website or online forum that offers like auction as a mode of selling.
                                <br></br>
                                <br></br> 3. The Direct Seller hereby undertakes not to compel or induce or mislead any person with any false statement or promise to purchase products or services from the Company or to become direct seller of the Company.
                                <br></br>
                                <br></br>

                                <h4>Cancellation And Refund Policy</h4>
                                <br></br>
                                1. Subscriber must know that Sanghamitra savings plan can be canceled.
                                <br></br>
                                <br></br>     2. Sanghamitra savings plan can be pre-matured only on the condition that all pending and future installments in the Sanghamitra savings plan are paid in advance.
                                <br></br>
                                <br></br>  3. A subscriber can withdraw their subscription by a written request to the Company.
                                <br></br>
                                <br></br> 4. The Company will, as per the bye-laws and Sanghamitra savings plan-agreement, make the payment after the deductions, on the actual subscribed amount.
                                <br></br>
                                <br></br>

                                <h4>Special Conditions</h4>
                                Notwithstanding anything stated or provided herein, the Company shall have all powers and discretion to modify, alter or vary the terms and conditions in any manner or mode as the Company deems fit and proper and shall be communicated through official website. If any Direct Seller does not agree to such modifications/ alterations, he may terminate his agreement within 30 days of such publication by giving a written notice to the Company. Without any objection to such modifications/alterations, if Direct Seller continues his/her business activities, it will be deemed that he/she has accepted all modifications/alterations for future.
                                <br></br>
                                <br></br>
                                <h4> Dispute settlement:</h4>

                               1. If any dispute or difference arises out of or in relation to this agreement including any question regarding its existence, validity, termination or terms and conditions, the company and Direct Seller shall endeavour to settle through mutual discussions within 30 days of such dispute arising. In case of dispute or difference is not mutually settled within period, it shall be referred to Grievance Redressal Committee of the Company. If Direct Seller is not satisfied with the decision of Grievance Redressal Committee and dispute or difference is remaining unsolved, the same shall be referred to sole Arbitrator appointed by the Company in accordance with the provisions of Arbitration and conciliation Act, 1996 as amended from time to time. The arbitration proceedings shall be conducted and the decision of Arbitrator will be final and shall have binding effect on the both parties to the agreement.
                               <br></br>
                                <br></br> 2. The terms and conditions stipulated in the forgoing paragraphs shall be governed in accordance with the law for the time being in force in India. Disputes, either civil or criminal in nature, shall be subject to the exclusive jurisdiction of the court only.
                                <br></br>
                                <br></br>    <h4>SMS Alert:</h4>

                                The Direct Seller agrees to receive the SMS Alert from the company on Mobile No. mentioned/ quoted above and will not object even if they are received despite of DND activated. Direct Seller shall intimate the change in Mobile No. (if any). */}

                            </div>
                            </Panel>
                        </Tabs>

                    </Card>
                    <Footer />
                </div>

                : <div style={{
                    minHeight: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}><CircularProgress /></div>
            }
        </>
    )
};

//export default Myprofile;

const mapStateToProps = state => (
    {
        currentUser: state.user.currentUser
    }
)

export default connect(mapStateToProps)(About);

import firebase from "firebase/app";
import "firebase/storage";
import "firebase/firestore";
import "firebase/auth";
import "firebase/firebase-database";
import 'firebase/messaging';
import { usersPath, referredIdPath } from "./paths.js";

const config = {
  apiKey: "AIzaSyBDdxsg4VqBsF5YGYnOAWmAx6nvpCbk1PQ",
  authDomain: "react-test-5e32f.firebaseapp.com",
  projectId: "react-test-5e32f",
  storageBucket: "react-test-5e32f.appspot.com",
  messagingSenderId: "838822914808",
  appId: "1:838822914808:web:4b01e99594cb17a592e90d",
  measurementId: "G-94RZXC6QTL",
};

var tree = {};

export const createUserProfileDocument = async (userAuth, additionalData) => {
  if (!userAuth) return;
  console.log(additionalData);
  const userRef = firestore.doc(`users/${userAuth.uid}`);

  const snapshot = await userRef.get();

  if (!snapshot.exists) {
    const createdAt = new Date();

    try {
      await userRef.set({
        displayName: additionalData.displayName,
        email: additionalData.email,
        id: userAuth.uid,
        createdAt,
        phone: additionalData.phone,
        photoURL: additionalData.photoURL,
        kycVerified: false,
        referredFrom: null,
        activeSMSGgroup: false,
        rank: {
          rank: 1,
          lastCheckAt: firebase.firestore.FieldValue.serverTimestamp(),
        },
      });
    } catch (error) {
      console.log("Error creating user");
    }
  }

  return userRef;
};

export const getUsersLists = async (userAuth) => {
  console.log(userAuth);



  const userRef = firestore
    .collection("agents")
    .doc(userAuth)
    .collection("referredId");

  const snapshot = await userRef.orderBy("timestamp", "desc").get();

  let promises = [];
  console.log(snapshot.docs);


  // snapshot.docs.map(async (e) => {
  //   promises.push(e.data());
  // });

  // return (await Promise.all(promises));
  snapshot.docs.map(async (e) => {
    promises.push(firestore.collection(`users`).doc(e.data().id).get());
  });

  return (await Promise.all(promises).then(e => e.map(data => data.data())));
};

export const getTree = () => {
  return firebase.database().ref("tree");
};

export const setTree = (data) => {
  firebase.database().ref("tree").set(data);
};

export const addChildren = async () => { };

firebase.initializeApp(config);

export const auth = firebase.auth();
export const firestore = firebase.firestore();

export const storage = firebase.storage();

const provider = new firebase.auth.GoogleAuthProvider();

provider.setCustomParameters({ prompt: "select_account" });

export const signInWIthGoogle = () => auth.signInWithPopup(provider);
const messaging = firebase.messaging();
export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });

export default firebase;
